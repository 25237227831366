"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandStatus = void 0;
/**
 * The status of the command
 */
var CommandStatus;
(function (CommandStatus) {
    CommandStatus["QUEUED"] = "QUEUED";
    CommandStatus["SUCCEEDED"] = "SUCCEEDED";
    CommandStatus["FAILED"] = "FAILED";
})(CommandStatus = exports.CommandStatus || (exports.CommandStatus = {}));
