var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { process } from "../../Process";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { RadioGroup, Switch } from "@headlessui/react";
import DateTimePicker from "react-datetime-picker";
import { Disclosure, Dialog } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon, ExclamationTriangleIcon, } from "@heroicons/react/24/outline";
import { Radio } from "../Radio";
import { TextInput } from "../Input";
var SensitiveConfirmation = function (_a) {
    var warning = _a.warning, close = _a.close, accept = _a.accept;
    var nameInputId = "nameInputId";
    var onSubmit = function (e) {
        e.preventDefault();
        var name = e.currentTarget.elements[nameInputId].value;
        if (name.length > 0) {
            accept(name);
            close();
        }
    };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px]" },
        React.createElement("form", { onSubmit: onSubmit, className: "flex flex-col gap-y-[32px] items-center" },
            React.createElement("div", { className: "flex flex-row items-center gap-x-[32px]" },
                React.createElement(ExclamationTriangleIcon, { className: "w-[40px] h-[40px] text-yellow-500" }),
                React.createElement("label", { className: "font-N5 text-white" }, "SENSITIVE FIELD")),
            React.createElement("div", { className: "max-w-[75%] flex flex-col gap-y-[32px] text-center" },
                React.createElement("label", { className: "font-N7 text-white" }, "This field is marked as sensitive. Setting it can have major consequences. As such, it requires a second approver."),
                React.createElement("label", { className: "font-N7 text-caution-yellow" }, warning),
                React.createElement("label", { className: "font-N7 text-white" }, "To continue, type the name of the person who approved this change:"),
                React.createElement(TextInput, { placeholder: "Enter Name", id: nameInputId })),
            React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                React.createElement("button", { className: "text-black-1200 font-N8 px-[24px] py-[16px] bg-green hover:bg-green/80 rounded-[10px] cursor-pointer", onClick: close }, "CLOSE"),
                React.createElement("input", { type: "submit", className: "text-black-1200 font-N8 px-[24px] py-[16px] bg-red hover:bg-red/80 rounded-[10px] cursor-pointer", value: "CONTINUE" })))));
};
export var NOCControlInput = function (_a) {
    var metadata = _a.metadata, currentValue = _a.currentValue, setValue = _a.setValue, onChange = _a.onChange, hideActions = _a.hideActions, sensitive = _a.sensitive;
    var input = React.createElement(React.Fragment, null);
    var _b = useState(currentValue), state = _b[0], setState = _b[1];
    var inputRef = useRef(null);
    useEffect(function () {
        if (inputRef.current) {
            var current = inputRef.current;
            if (current.value != state) {
                current.value = state;
            }
        }
    }, [inputRef, state]);
    var modified = state != currentValue;
    switch (metadata.type) {
        case "varchar":
        case "text":
        case "int":
            input = (React.createElement("input", { ref: inputRef, type: metadata.type == "int" ? "number" : undefined, defaultValue: currentValue, onChange: function (e) {
                    if (e.target.value != state)
                        setState(e.target.value);
                    if (onChange)
                        onChange(e.target.value);
                }, placeholder: state == null ? "NULL" : undefined, className: "grow px-[8px]" }));
            break;
        case "boolean":
            input = (React.createElement(Switch, { checked: state, onChange: function (e) {
                    setState(e);
                    if (onChange)
                        onChange(e);
                }, className: "".concat(state ? "bg-blue-600" : "bg-gray-500", " relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2") },
                React.createElement("span", { className: "".concat(state ? "translate-x-6" : "translate-x-1", " inline-block h-4 w-4 transform rounded-full bg-white transition-transform") })));
            break;
        case "enum":
            input = (React.createElement(RadioGroup, { className: "flex flex-row gap-x-[16px] items-center", value: state, onChange: setState }, Object.keys(metadata.enum).map(function (key) {
                return (React.createElement(RadioGroup.Option, { value: metadata.enum[key], key: key }, function (_a) {
                    var checked = _a.checked;
                    return (React.createElement("button", { className: "rounded-[8px] px-[16px] py-[8px] font-N7 text-white ".concat(checked ? "bg-black-400" : "bg-black-800") }, metadata.enum[key]));
                }));
            })));
            break;
        case "timestamptz":
            input = (React.createElement("div", { className: "text-black bg-black-400" },
                React.createElement(DateTimePicker, { onChange: function (e) {
                        setState(e);
                        if (onChange)
                            onChange(e);
                    }, value: state }),
                ";"));
            break;
    }
    var _c = useState(false), isSensitiveModalOpen = _c[0], setIsSensitiveModalOpen = _c[1];
    var onApply = function () {
        if (sensitive) {
            setIsSensitiveModalOpen(true);
        }
        else {
            setValue(state);
        }
    };
    return (React.createElement("div", { className: "flex flex-row grow items-center gap-x-[8px]" },
        React.createElement(Dialog, { open: isSensitiveModalOpen, onClose: function () { return setIsSensitiveModalOpen(false); }, className: "relative z-50" },
            React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
            React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                React.createElement(Dialog.Panel, { className: "mx-auto" },
                    React.createElement(SensitiveConfirmation, { close: function () { return setIsSensitiveModalOpen(false); }, accept: function () { return setValue(state); }, warning: sensitive === null || sensitive === void 0 ? void 0 : sensitive.warning })))),
        React.createElement("div", { className: "grow flex flex-row items-center" }, input),
        !hideActions && state != currentValue && (React.createElement("button", { className: "px-[16px] py-[8px] rounded-[8px] bg-green disabled:bg-green/40 hover:bg-green/80 text-black disabled:text-400", onClick: function () {
                setState(currentValue);
            } }, "Restore")),
        !hideActions && metadata.nullable && (React.createElement("button", { className: "px-[16px] py-[8px] rounded-[8px] bg-red hover:bg-red/80 text-white", onClick: function () {
                setState(null);
            } }, "Set NULL")),
        !hideActions && (React.createElement("button", { className: "px-[16px] py-[8px] rounded-[8px] bg-blue-600 disabled:bg-blue-900 hover:bg-blue-400 text-white disabled:text-400", disabled: !modified, onClick: onApply }, "Apply"))));
};
export var NOCControl = function (_a) {
    var title = _a.title, metadataUrl = _a.metadataUrl, setMetadataUrl = _a.setMetadataUrl, deleteDescription = _a.deleteDescription, deleteUrl = _a.deleteUrl;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    var metadata = useSWR(isLoading || !isAuthenticated
        ? null
        : ["".concat(process.env.NOC_SVC_URL).concat(metadataUrl), getAccessTokenSilently], Auth0Fetcher).data;
    var onSetMetadata = function (name, value) { return __awaiter(void 0, void 0, void 0, function () {
        var metadata, accessToken, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("Setting metadata with name " + name + " to value " + value);
                    metadata = {
                        name: name,
                        value: value,
                    };
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL).concat(setMetadataUrl), {
                            method: "POST",
                            body: JSON.stringify(metadata),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        console.error("Error setting metadata: " + response.status);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _c = useState(false), isSensitiveModalOpen = _c[0], setIsSensitiveModalOpen = _c[1];
    var onDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, responseBody;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("Deleting " + title);
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL).concat(deleteUrl), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    responseBody = _a.sent();
                    alert("Something went wrong deleting entity: " +
                        responseBody.message +
                        " (code " +
                        response.status +
                        ")");
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Disclosure, null, function (_a) {
        var open = _a.open;
        return (React.createElement(React.Fragment, null,
            React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                React.createElement("label", { className: "font-N6" }, title),
                open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
            React.createElement(Disclosure.Panel, { className: "" },
                React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                    metadata &&
                        metadata.fields &&
                        metadata.fields.map(function (field) {
                            return (React.createElement("div", { key: field.name, className: "flex flex-col gap-y-[4px]" },
                                React.createElement("hr", { className: "mx-[32px] bg-black-400 " }),
                                React.createElement("label", { className: "font-N7 text-white" }, field.name),
                                React.createElement(NOCControlInput, { metadata: field.metadata, currentValue: field.currentValue, setValue: function (value) {
                                        void onSetMetadata(field.name, value);
                                    }, sensitive: field.sensitive }),
                                field.description && (React.createElement("label", { className: "font-N8 text-black-400" }, field.description))));
                        }),
                    deleteUrl != null && React.createElement("hr", { className: "mx-[32px] bg-black-400" }),
                    deleteDescription != null && (React.createElement("label", { className: "font-N8 text-black-400" }, deleteDescription)),
                    deleteUrl != null && (React.createElement(React.Fragment, null,
                        React.createElement("button", { className: "px-[16px] py-[8px] rounded-[8px] bg-red hover:bg-red/80 text-white w-fit", onClick: function () { return setIsSensitiveModalOpen(true); } }, "DELETE"),
                        React.createElement(Dialog, { open: isSensitiveModalOpen, onClose: function () { return setIsSensitiveModalOpen(false); }, className: "relative z-50" },
                            React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
                            React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                                React.createElement(Dialog.Panel, { className: "mx-auto" },
                                    React.createElement(SensitiveConfirmation, { close: function () { return setIsSensitiveModalOpen(false); }, accept: onDelete, warning: "Deleting entites that are active can cause major problems. NEVER delete an entity if you aren't completely sure it's unused." }))))))))));
    }));
};
var NOCCreateSubobject = function (_a) {
    var metadataUrl = _a.metadataUrl, setField = _a.setField, subObjectKey = _a.subObjectKey;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    var metadata = useSWR(isLoading || !isAuthenticated
        ? null
        : ["".concat(process.env.NOC_SVC_URL).concat(metadataUrl), getAccessTokenSilently], Auth0Fetcher).data;
    return (React.createElement("div", { className: "flex flex-col gap-y-[8px]" }, metadata &&
        metadata.fields &&
        metadata.fields
            .filter(function (f) { return f.neededToCreate; })
            .map(function (field) {
            var _a;
            return (React.createElement("div", { key: field.name, className: "flex flex-col gap-y-[4px]" },
                React.createElement("label", { className: "font-N7 text-white" }, field.name),
                React.createElement(NOCControlInput, { metadata: field.metadata, currentValue: (_a = field.currentValue) !== null && _a !== void 0 ? _a : "", setValue: function () { }, onChange: function (value) {
                        setField(field.name, subObjectKey, value);
                    }, hideActions: true, sensitive: field.sensitive }),
                field.description && (React.createElement("label", { className: "font-N8 text-black-400" }, field.description)),
                React.createElement("hr", { className: "mx-[32px] bg-black-400 " })));
        })));
};
export var NOCCreate = function (_a) {
    var title = _a.title, createUrl = _a.createUrl, parent = _a.parent, subObjects = _a.subObjects;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _b = useState({}), createObject = _b[0], setCreateObject = _b[1];
    var setField = function (key, value) {
        var currentObject = createObject;
        currentObject[key] = value;
        // Object spread forces a re-render. React isn't smart enough to re-render if we reuse the old object.
        setCreateObject(__assign({}, currentObject));
    };
    useEffect(function () {
        if (parent && createObject[parent.key] == undefined) {
            setField(parent.key, parent.possibleParents[0].id);
        }
    });
    var setSubobjectField = function (key, object, value) {
        if (object == null) {
            setField(key, value);
            return;
        }
        var currentObject = createObject;
        if (!currentObject[object]) {
            currentObject[object] = {};
        }
        currentObject[object][key] = value;
        // Object spread forces a re-render. React isn't smart enough to re-render if we reuse the old object.
        setCreateObject(__assign({}, currentObject));
    };
    var onCreate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, confirmation, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    confirmation = "CONFIRM ".concat(title, ": ").concat(JSON.stringify(createObject));
                    if (!(confirm(confirmation) == true)) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL).concat(createUrl), {
                            method: "POST",
                            body: JSON.stringify(createObject),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        alert("Something went wrong. Please ask the software team for assistance.");
                    }
                    else {
                        alert("Success.");
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Disclosure, null, function (_a) {
        var open = _a.open;
        return (React.createElement(React.Fragment, null,
            React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                React.createElement("label", { className: "font-N6" }, title),
                open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
            React.createElement(Disclosure.Panel, { className: "" },
                React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                    parent && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                            React.createElement("label", { className: "font-N7" }, parent.title),
                            React.createElement(Radio, { items: parent.possibleParents.map(function (p) {
                                    return {
                                        name: p.title,
                                        value: p.id,
                                    };
                                }), value: createObject[parent.key], setValue: function (val) { return setField(parent.key, val); } })),
                        React.createElement("hr", { className: "mx-[32px] bg-black-400 " }))),
                    subObjects &&
                        subObjects.map(function (o, i) {
                            return (React.createElement(NOCCreateSubobject, __assign({ key: "".concat(i) }, o, { setField: setSubobjectField })));
                        })),
                React.createElement("button", { className: "px-[16px] py-[8px] rounded-[8px] bg-red hover:bg-red/80 text-white", onClick: onCreate }, "CREATE"))));
    }));
};
export var NOCControls = function (_a) {
    var controllableAssets = _a.controllableAssets, creatableAssets = _a.creatableAssets;
    var _b = useState(false), accepted = _b[0], setAccepted = _b[1];
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("label", { className: "font-N5 text-white" }, "NOC Controls"),
        React.createElement("div", { className: "flex flex-row items-center gap-x-[8px]" },
            React.createElement("label", { className: "" }, "I understand that the controls on this page impact the production system and will use my best judgement in applying settings."),
            React.createElement(Switch, { checked: accepted, onChange: setAccepted, className: "".concat(accepted ? "bg-blue-600" : "bg-gray-500", " relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2") },
                React.createElement("span", { className: "".concat(accepted ? "translate-x-6" : "translate-x-1", " inline-block h-4 w-4 transform rounded-full bg-white transition-transform") }))),
        React.createElement("div", { className: "grid" },
            React.createElement("div", { className: "overlap-grid" },
                React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
                    controllableAssets.map(function (c) {
                        return React.createElement(NOCControl, __assign({ key: c.title }, c));
                    }),
                    creatableAssets.map(function (c) {
                        return React.createElement(NOCCreate, __assign({ key: c.title }, c));
                    }))),
            !accepted && React.createElement("div", { className: "overlap-grid z-50 bg-black/60" }))));
};
