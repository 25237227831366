"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDisplayAddress = void 0;
/**
 * Converts an address entity into a displayable address.
 *
 * @param addressEntity The address entity retrieved from the database.
 * @returns The stringified version of the address.
 */
function GetDisplayAddress(addressEntity) {
    let address = "";
    address = address.concat(addressEntity.street1);
    if (addressEntity.street2) {
        address = " " + address.concat(addressEntity.street2);
    }
    if (addressEntity.street3) {
        address = " " + address.concat(addressEntity.street3);
    }
    address = address.concat(", " + addressEntity.city + ", " + addressEntity.state);
    return address;
}
exports.GetDisplayAddress = GetDisplayAddress;
