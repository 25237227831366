var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { StaticTable } from "../Table";
import { LoadingIcon } from "ee-tailwind-components";
import { StyledButton, StyledButtonStyle } from "../StyledButton";
import { TextInput } from "../Input";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import useSWR from "swr";
import { Dialog } from "@headlessui/react";
import { HelpTooltip } from "../HelpTooltip";
import { SuccessIndicator } from "../SuccessIndicator";
export var AlertSuppressionEditDialog = function (_a) {
    var suppression = _a.suppression, onClose = _a.onClose, onSubmit = _a.onSubmit, alertHistory = _a.alertHistory, onDelete = _a.onDelete;
    var user = useAuth0().user;
    var buttons = [
        {
            title: "30 minutes",
            value: 1000 * 60 * 30,
        },
        {
            title: "1 hour",
            value: 1000 * 60 * 60,
        },
        {
            title: "3 hours",
            value: 1000 * 60 * 60 * 3,
        },
        {
            title: "12 hours",
            value: 1000 * 60 * 60 * 12,
        },
        {
            title: "1 day",
            value: 1000 * 60 * 60 * 24,
        },
        {
            title: "7 days",
            value: 1000 * 60 * 60 * 24 * 7,
        },
    ];
    var expirationRef = useRef(null);
    var patternRef = useRef(null);
    var descriptionRef = useRef(null);
    var dateToInputString = function (date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0, 16);
    };
    var onButtonClick = function (value) {
        if (!expirationRef.current) {
            return;
        }
        var date = new Date();
        date.setMilliseconds(date.getMilliseconds() + value);
        expirationRef.current.value = dateToInputString(date);
    };
    var _b = useState(), errorMessage = _b[0], setErrorMessage = _b[1];
    var displayErrorMessage = function (message) {
        setErrorMessage(message);
        setTimeout(function () {
            setErrorMessage(undefined);
        }, 10000);
    };
    var filterAlertsByPattern = function (alerts, pattern) {
        return alertHistory
            .map(function (a) { return a.message.replace("[Suppressed by Command Console] ", ""); })
            .filter(function (a) { return a.includes(pattern); });
    };
    var _c = useState(suppression === null || suppression === void 0 ? void 0 : suppression.pattern), pattern = _c[0], setPattern = _c[1];
    var filteredAlerts = pattern
        ? filterAlertsByPattern(alertHistory, pattern)
        : [];
    var onSave = function () {
        var _a;
        if (!expirationRef.current ||
            !patternRef.current ||
            !descriptionRef.current) {
            return;
        }
        if (!expirationRef.current.value) {
            displayErrorMessage("Expiration must be set.");
            return;
        }
        if (!descriptionRef.current.value) {
            displayErrorMessage("Description must be set.");
            return;
        }
        var alertToCreate = {
            id: suppression ? suppression.id : 0,
            description: descriptionRef.current.value,
            pattern: patternRef.current.value ? patternRef.current.value : undefined,
            until: new Date(expirationRef.current.value),
            operator: (_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : "",
        };
        setErrorMessage(undefined);
        onSubmit(alertToCreate);
        onClose();
    };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px]" },
        React.createElement("label", { className: "font-N5 text-white w-full text-center" }, "Edit Suppression"),
        React.createElement("div", { className: "grid grid-cols-2 gap-x-4 gap-y-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" }, "Pattern (optional):"),
            React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
                React.createElement(TextInput, { placeholder: "Enter pattern", ref: patternRef, onChange: function () { var _a; return setPattern((_a = patternRef.current) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: suppression === null || suppression === void 0 ? void 0 : suppression.pattern }),
                React.createElement(HelpTooltip, { text: "The pattern provided will match if the incoming alert contains the string as a substring of the title. Leave empty to match all alerts." })),
            pattern && (React.createElement("div", { className: "flex flex-col gap-y-2 col-span-2" },
                filteredAlerts.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("label", { className: "font-N8 text-white" }, "Recent Matches"),
                    filteredAlerts.map(function (f, i) {
                        return (React.createElement("label", { className: "font-N8p5 text-white", key: i }, f));
                    }))),
                filteredAlerts.length == 0 && (React.createElement("label", { className: "font-N8 text-white" }, "No recent alert matches.")))),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] col-span-2 mx-auto w-full" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Description:"),
            React.createElement(TextInput, { placeholder: "Provide helpful description", ref: descriptionRef, defaultValue: suppression === null || suppression === void 0 ? void 0 : suppression.description }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] col-span-2 mx-auto w-full" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Expiration:"),
            React.createElement(TextInput, { type: "datetime-local", ref: expirationRef, defaultValue: (suppression === null || suppression === void 0 ? void 0 : suppression.until)
                    ? dateToInputString(new Date(suppression.until))
                    : undefined }),
            React.createElement("div", { className: "flex flex-row flex-wrap col-span-2 gap-4 items-center" },
                React.createElement("label", { className: "font-N8 text-white" }, "Expiration Presets:"),
                buttons.map(function (b, i) { return (React.createElement(StyledButton, { key: i, text: b.title, onClick: function () { return onButtonClick(b.value); } })); })),
            React.createElement("div", { className: "flex flex-rowcol-span-2 gap-x-4 mt-16" },
                React.createElement(StyledButton, { text: "SAVE", style: StyledButtonStyle.Green, onClick: onSave }),
                onDelete != null && (React.createElement(StyledButton, { text: "DELETE", style: StyledButtonStyle.Green, onClick: function () {
                        onDelete();
                        onClose();
                    } })),
                React.createElement(StyledButton, { text: "CLOSE", style: StyledButtonStyle.Gray, onClick: onClose }))),
        React.createElement(SuccessIndicator, { message: errorMessage, success: false })));
};
export var Alerts = function (_a) {
    var alerts = _a.alerts, details = _a.details;
    var _b = useAuth0(), getAccessTokenSilently = _b.getAccessTokenSilently, isLoading = _b.isLoading, isAuthenticated = _b.isAuthenticated;
    var alertsSuppressions = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/alerts/suppression/").concat(details.id),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var _c = useState(false), showEditDialog = _c[0], setShowEditDialog = _c[1];
    var _d = useState(), suppressionToEdit = _d[0], setSuppressionToEdit = _d[1];
    var closeEditDialog = function () { return setShowEditDialog(false); };
    var openEditDialog = function (suppressionToEdit) {
        setSuppressionToEdit(suppressionToEdit);
        setShowEditDialog(true);
    };
    var alertColumns = [
        {
            header: "Message",
            accessorFn: function (r) { return r.message; },
        },
        {
            header: "Status",
            accessorFn: function (r) { return r.status; },
        },
        {
            header: "Acknowledged",
            accessorFn: function (r) { return (r.acknowledged ? "Yes" : "No"); },
        },
        {
            header: "Time Created",
            accessorFn: function (r) { return new Date(r.createdAt).toLocaleString(); },
        },
        {
            header: "Owner",
            accessorFn: function (r) { return r.owner; },
        },
        {
            header: "Priority",
            accessorFn: function (r) { return r.priority; },
        },
        {
            header: "Team",
            accessorFn: function (r) { var _a, _b; return (_b = (_a = alerts === null || alerts === void 0 ? void 0 : alerts.teams.find(function (t) { return t.id == r.ownerTeamId; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "None"; },
        },
    ];
    var onClick = function (alertId) {
        window.open("https://electric-era.app.opsgenie.com/alert/detail/".concat(alertId, "/details"), "_blank");
    };
    var onViewAllAlerts = function () {
        var query = details.powernodes
            .map(function (p) { return "message:[PNID%20".concat(p.id, "]"); })
            .join("%20OR%20");
        window.open("https://app.opsgenie.com/alert/list?query=".concat(query));
    };
    var ackAllHighPriority = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/alerts/ack"), {
                            method: "POST",
                            body: JSON.stringify(alerts === null || alerts === void 0 ? void 0 : alerts.activeAlerts.filter(function (a) { return !a.acknowledged; }).map(function (a) { return a.id; })),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var createAlertSuppression = function (suppression) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/alerts/suppression/").concat(details.id), {
                            method: "POST",
                            body: JSON.stringify(suppression),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateAlertSuppression = function (suppression) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/alerts/suppression/").concat(suppression.id), {
                            method: "PATCH",
                            body: JSON.stringify(suppression),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var deleteAlertSuppression = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/alerts/suppression/").concat(id), {
                            method: "DELETE",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-4" },
        alerts == null && React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon),
        alerts != null && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N5 text-white" }, "Teams"),
            alerts.teams.map(function (t, i) { return (React.createElement("a", { className: "font-N7 text-black-400 hover:text-white text-clip whitespace-nowrap w-fit", rel: "noopener noreferrer", href: "https://electric-era.app.opsgenie.com/teams/dashboard/".concat(t.id, "/main"), target: "_blank", key: i }, t.name)); }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] my-8" }),
            React.createElement("div", { className: "flex flex-row gap-x-4 items-center mt-8" },
                React.createElement("label", { className: "font-N5 ".concat(alerts.activeAlerts.length > 0 ? "text-red" : "text-white") }, "High Priority Open Alerts (".concat(alerts.activeAlerts.length, ")")),
                React.createElement(StyledButton, { style: StyledButtonStyle.Gray, text: "ACK ALL", disabled: alerts.activeAlerts.length == 0, onClick: ackAllHighPriority })),
            React.createElement(StaticTable, { data: alerts.activeAlerts, columnDefinitions: alertColumns, emptyMessage: "No active high priority alerts.", onClickRow: function (r) { return onClick(r.id); } }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] my-8" }),
            React.createElement("div", { className: "flex flex-col gap-y-4" },
                React.createElement("label", { className: "font-N5 text-white'} mt-8" }, "Site Alert Suppressions"),
                (alertsSuppressions !== null && alertsSuppressions !== void 0 ? alertsSuppressions : [])
                    .sort(function (a1, a2) {
                    return new Date(a2.until).getTime() - new Date(a1.until).getTime();
                })
                    .map(function (s, i) {
                    var active = new Date().getTime() < new Date(s.until).getTime();
                    return (React.createElement("div", { className: "flex flex-row gap-x-4 items-center", key: i },
                        React.createElement("label", { className: "font-N6p75 text-white" }, "".concat(active ? "ACTIVE" : "EXPIRED", " SUPPRESSION: ")),
                        React.createElement("label", { className: "font-N6p75 text-white" }, "".concat(s.description, " (created by ").concat(s.operator, ", expire").concat(active ? "s" : "d", " ").concat(new Date(s.until).toLocaleString(), ")")),
                        React.createElement("label", { className: "font-N7 text-black-400 hover:text-white cursor-pointer whitespace-nowrap w-fit", onClick: function () { return openEditDialog(s); } }, "Edit")));
                }),
                React.createElement(StyledButton, { text: "CREATE NEW", className: "mt-8", style: StyledButtonStyle.Green, onClick: function () { return openEditDialog(undefined); } })),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] my-8" }),
            React.createElement("div", { className: "flex flex-row gap-x-4 items-center mt-8" },
                React.createElement("label", { className: "font-N5 text-white" }, "Recent Alert History"),
                React.createElement(StyledButton, { style: StyledButtonStyle.Gray, text: "VIEW ALL", onClick: onViewAllAlerts })),
            React.createElement(StaticTable, { data: alerts.alertHistory, columnDefinitions: alertColumns, emptyMessage: "No alert history.", onClickRow: function (r) { return onClick(r.id); } }),
            React.createElement(Dialog, { open: showEditDialog, onClose: closeEditDialog, className: "relative z-50" },
                React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
                React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                    React.createElement(Dialog.Panel, { className: "mx-auto" },
                        React.createElement(AlertSuppressionEditDialog, { suppression: suppressionToEdit, onClose: closeEditDialog, onSubmit: suppressionToEdit
                                ? updateAlertSuppression
                                : createAlertSuppression, onDelete: suppressionToEdit
                                ? function () { return deleteAlertSuppression(suppressionToEdit.id); }
                                : undefined, alertHistory: alerts.alertHistory }))))))));
};
