export function GetDateString(date) {
    return date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    });
}
export var NumberLocaleStringStyle;
(function (NumberLocaleStringStyle) {
    NumberLocaleStringStyle["Decimal"] = "decimal";
    NumberLocaleStringStyle["Currency"] = "currency";
    NumberLocaleStringStyle["Percent"] = "percent";
    NumberLocaleStringStyle["Unit"] = "unit";
})(NumberLocaleStringStyle || (NumberLocaleStringStyle = {}));
export function FormatNumber(val, options) {
    var _a, _b, _c, _d;
    if (val === undefined || val === null) {
        return "";
    }
    var maximumFractionDigits = (_a = options === null || options === void 0 ? void 0 : options.maximumFractionDigits) !== null && _a !== void 0 ? _a : 2;
    var useLocaleString = (_b = options === null || options === void 0 ? void 0 : options.useLocaleString) !== null && _b !== void 0 ? _b : false;
    var localeStringStyle = (_c = options === null || options === void 0 ? void 0 : options.localeStringStyle) !== null && _c !== void 0 ? _c : NumberLocaleStringStyle.Decimal;
    // TODO: In the future we probably shouldn't default to USD, but we're currently working on the US Market.
    var currency = (options === null || options === void 0 ? void 0 : options.localeStringStyle) === NumberLocaleStringStyle.Currency
        ? (_d = options.currencyType) !== null && _d !== void 0 ? _d : "USD"
        : undefined;
    return useLocaleString
        ? val.toLocaleString(undefined, {
            maximumFractionDigits: maximumFractionDigits,
            style: localeStringStyle,
            currency: currency,
        })
        : val.toFixed(maximumFractionDigits);
}
