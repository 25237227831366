var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { process } from "../../Process";
import { OnOffRadio } from "../Radio";
import { HelpTooltip } from "../HelpTooltip";
import { StyledButton } from "../StyledButton";
import { LoadingIcon } from "ee-tailwind-components";
import { SuccessIndicator } from "../SuccessIndicator";
import { TextInput } from "../Input";
import { Transition } from "@headlessui/react";
export var SiteSettingsTab = function (_a) {
    var _b, _c, _d;
    var details = _a.details;
    var _e = useAuth0(), isAuthenticated = _e.isAuthenticated, isLoading = _e.isLoading, getAccessTokenSilently = _e.getAccessTokenSilently;
    var _f = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/settings/").concat(details.id),
            getAccessTokenSilently,
        ], Auth0Fetcher), newSettings = _f.data, mutate = _f.mutate;
    var _g = useState({
        enableRemotePaymentAuthorization: false,
    }), settings = _g[0], setSettings = _g[1];
    // Whenever we detect that settings have changed on the cloud side, overwrite local settings to the changed values.
    var cloudSettings = useRef();
    if (newSettings != null &&
        (cloudSettings.current == null ||
            JSON.stringify(cloudSettings.current) != JSON.stringify(newSettings))) {
        setSettings(__assign({}, newSettings));
        cloudSettings.current = newSettings;
    }
    var setEnableRemotePaymentAuthorization = function (enabled) {
        settings.enableRemotePaymentAuthorization = enabled;
        setSettings(__assign({}, settings));
    };
    var _h = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/taxes/").concat(details.id),
            getAccessTokenSilently,
        ], Auth0Fetcher), newTaxes = _h.data, mutateTaxes = _h.mutate;
    var _j = useState(), taxes = _j[0], setTaxes = _j[1];
    var cloudTaxSettings = useRef();
    if (newTaxes != null &&
        (cloudTaxSettings.current == null ||
            JSON.stringify(cloudTaxSettings.current) != JSON.stringify(newTaxes))) {
        setTaxes(JSON.parse(JSON.stringify(newTaxes)));
        cloudTaxSettings.current = newTaxes;
    }
    var setUseSuggestedTaxRate = function (value) {
        if (taxes) {
            taxes.useSuggestedTaxRate = value;
            setTaxes(__assign({}, taxes));
        }
    };
    var setManualTaxRate = function (value) {
        if (taxes) {
            var amount = parseFloat(value);
            console.log("RAW: " + value + " AMOUNT: " + amount);
            if (isNaN(amount)) {
                return;
            }
            if (amount > 100) {
                amount = 100;
            }
            taxes.taxOverride = [
                {
                    description: "Tax",
                    rate: amount / 100,
                },
            ];
            setTaxes(JSON.parse(JSON.stringify(taxes)));
        }
    };
    var _k = useState(), saveSuccess = _k[0], setSaveSuccess = _k[1];
    var _l = useState(), saveMessage = _l[0], setSaveMessage = _l[1];
    var displaySaveMessage = function (success, message) {
        setSaveMessage(message);
        setSaveSuccess(success);
        setTimeout(function () {
            setSaveMessage(undefined);
        }, 10000);
    };
    var saveSettings = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/site/settings/").concat(details.id), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(settings),
                        })];
                case 2:
                    res = _a.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    displaySaveMessage(false, "Something went wrong while saving settings. Please try again.");
                    return [2 /*return*/];
                case 3:
                    displaySaveMessage(true, "Settings saved.");
                    return [4 /*yield*/, mutate(settings)];
                case 4:
                    _a.sent();
                    cloudSettings.current = undefined;
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var _m = useState(), saveTaxesSuccess = _m[0], setSaveTaxesSuccess = _m[1];
    var _o = useState(), saveTaxesMessage = _o[0], setSaveTaxesMessage = _o[1];
    var displaySaveTaxesMessage = function (success, message) {
        setSaveTaxesMessage(message);
        setSaveTaxesSuccess(success);
        setTimeout(function () {
            setSaveTaxesMessage(undefined);
        }, 10000);
    };
    var saveTaxesSettings = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, setTaxes, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!taxes) {
                        displaySaveTaxesMessage(false, "Something went wrong while saving taxes. Please try again.");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    setTaxes = {
                        useSuggestedTaxRate: taxes.useSuggestedTaxRate,
                        taxOverride: taxes.taxOverride,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/taxes/set/").concat(details.id), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(setTaxes),
                        })];
                case 2:
                    res = _a.sent();
                    if (!!res.ok) return [3 /*break*/, 3];
                    displaySaveTaxesMessage(false, "Something went wrong while saving taxes. Please try again.");
                    return [2 /*return*/];
                case 3:
                    displaySaveTaxesMessage(true, "Tax configuration saved.");
                    return [4 /*yield*/, mutateTaxes(taxes)];
                case 4:
                    _a.sent();
                    cloudTaxSettings.current = undefined;
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px]" },
        React.createElement("label", { className: "font-N6 text-white" }, "Enable/Disable Features"),
        cloudSettings.current != null && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
                    React.createElement("label", { className: "text-black-400 font-N8p5" }, "ENABLE COMMAND CONSOLE PAYMENT AUTHORIZATION"),
                    React.createElement(HelpTooltip, { text: "When enabled, this setting allows for operators to use Command Console to remotely authorize a charging session without requiring payment at the charger. Once this feature is enabled, navigate to the \"Overview\" tab and click \"Authorize Session\" on any charger to authorize a charging session. Authorizations are only valid for two minutes." })),
                React.createElement(OnOffRadio, { on: settings.enableRemotePaymentAuthorization, setOn: setEnableRemotePaymentAuthorization })),
            React.createElement(StyledButton, { text: "SAVE CHANGES", onClick: saveSettings, disabled: JSON.stringify(cloudSettings.current) == JSON.stringify(settings) }))),
        cloudSettings.current == null && (React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon)),
        React.createElement(SuccessIndicator, { message: saveMessage, success: saveSuccess }),
        cloudTaxSettings.current != null && taxes != null && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N6 text-white mt-12" }, "Tax Settings"),
            taxes.suggestedTaxes != null && taxes.suggestedTaxes.length > 0 && (React.createElement("div", { className: "flex flex-col gap-y-4" },
                React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
                    React.createElement("label", { className: "font-N7 text-white" }, "Suggested Tax Rate:"),
                    React.createElement("label", { className: "font-N6p75 text-white" }, "".concat(taxes.suggestedTaxes
                        .reduce(function (acc, c) { return acc + c.rate * 100; }, 0)
                        .toFixed(3), "%")),
                    React.createElement(HelpTooltip, { text: "Tax Authorities:", additionalDetails: taxes.suggestedTaxes.map(function (t) { return "".concat(t.description, ": ").concat((t.rate * 100).toFixed(3), "%"); }) })),
                React.createElement("label", { className: "font-N8p5 text-white max-w-[700px]" }, "Tax rate estimation is informational only and is not guaranteed to be accurate. Users are responsible for charging appropriate sales tax and should independently verify and consult a tax professional for personalized advice. Suggested tax rates are updated once a day to ensure they are up-to-date."))),
            React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
                    React.createElement("label", { className: "text-black-400 font-N8p5" }, "USE SUGGESTED TAX RATE")),
                React.createElement(OnOffRadio, { on: taxes.useSuggestedTaxRate, setOn: setUseSuggestedTaxRate })),
            React.createElement(Transition, { show: !taxes.useSuggestedTaxRate, enter: "transition duration-100 ease-out", enterFrom: "transform scale-95 opacity-0", enterTo: "transform scale-100 opacity-100", leave: "transition duration-75 ease-out", leaveFrom: "transform scale-100 opacity-100", leaveTo: "transform scale-95 opacity-0", className: "flex flex-col gap-y-[8px]" },
                React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                    React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
                        React.createElement("label", { className: "text-black-400 font-N8p5" }, "APPLICABLE TAX RATE")),
                    React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                        React.createElement("span", { className: "relative after:content-['%'] after:absolute after:translate-y-[-50%] after:top-[50%] after:right-[8px] after:text-black-600" },
                            React.createElement(TextInput, { placeholder: "Tax Rate", value: (((_d = (_c = (_b = taxes.taxOverride) === null || _b === void 0 ? void 0 : _b.at(0)) === null || _c === void 0 ? void 0 : _c.rate) !== null && _d !== void 0 ? _d : 0) * 100).toFixed(3), type: "number", step: ".1", min: ".01", max: "100", pattern: "^\\d+(\\.)\\d{2}$", onChange: function (e) { return setManualTaxRate(e.target.value); }, className: "!pr-[24px]" }))))),
            React.createElement(StyledButton, { text: "SAVE CHANGES", onClick: saveTaxesSettings, disabled: JSON.stringify(cloudTaxSettings.current) == JSON.stringify(taxes) }))),
        cloudTaxSettings.current == null && (React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon)),
        React.createElement(SuccessIndicator, { message: saveTaxesMessage, success: saveTaxesSuccess })));
};
