import React, { useState } from "react";
import { DateRangeSelector, TimeHorizon, getSubDateRanges } from "../DateRange";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { Auth0FetcherWithBody } from "../../Auth0Fetcher";
import { StaticTable } from "../Table";
import { BarGraph } from "../Graph/BarGraph";
import { DowloadCSVButton } from "../DownloadCSVButton";
export var Usage = function (_a) {
    var resourceUrl = _a.resourceUrl, startDate = _a.startDate;
    var _b = useState(TimeHorizon.DAY), timeHorizon = _b[0], setTimeHorizon = _b[1];
    var _c = useState([
        new Date(),
        new Date(),
    ]), dateRange = _c[0], setDateRange = _c[1];
    var dateRanges = getSubDateRanges(timeHorizon, dateRange[0]);
    var _d = useAuth0(), isAuthenticated = _d.isAuthenticated, isLoading = _d.isLoading, getAccessTokenSilently = _d.getAccessTokenSilently;
    var _e = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            resourceUrl,
            getAccessTokenSilently,
            dateRanges.map(function (r) { return [r[0], r[1]]; }),
        ], Auth0FetcherWithBody), stats = _e.data, statsLoading = _e.isLoading;
    var tableData = (!statsLoading && stats ? stats : []).map(function (r, i) {
        return {
            time: i < dateRanges.length ? dateRanges[i][2] : "",
            usage: "".concat((r === null || r === void 0 ? void 0 : r.usage) == null ? "-" : "".concat(Number(r.usage * 100).toFixed(1), "%")),
            idle: "".concat((r === null || r === void 0 ? void 0 : r.idle) == null ? "-" : "".concat(Number(r.idle * 100).toFixed(1), "%")),
            offline: "".concat((r === null || r === void 0 ? void 0 : r.unavailable) == null
                ? "-"
                : "".concat(Number(r.unavailable * 100).toFixed(1), "%")),
            uptime: "".concat((r === null || r === void 0 ? void 0 : r.uptime) == null ? "-" : "".concat(Number(r.uptime * 100).toFixed(1), "%")),
        };
    });
    var columns = [
        {
            header: "Time",
            accessorKey: "time",
        },
        {
            header: "Station Uptime",
            accessorKey: "uptime",
        },
        {
            header: "Usage",
            accessorKey: "usage",
        },
        {
            header: "Idle",
            accessorKey: "idle",
        },
        {
            header: "Offline",
            accessorKey: "offline",
        },
    ];
    var graphData = (!statsLoading && stats ? stats : []).map(function (s, i) {
        return {
            timeLabel: i < dateRanges.length ? dateRanges[i][2] : "",
            time: i,
            usage: s ? s.usage : 0,
            idle: s ? s.idle : 0,
            offline: s ? s.unavailable : 0,
        };
    });
    var labelAccessor = function (data) { return data.timeLabel; };
    var yAxisLabelAccessor = function (data) {
        return "".concat(+Number(data * 100).toFixed(1), "%");
    };
    var valueAccessors = [
        {
            label: "Usage",
            accessor: function (data) { return data.usage; },
        },
        {
            label: "Idle",
            accessor: function (data) { return data.idle; },
        },
        {
            label: "Unavailable",
            accessor: function (data) { return data.offline; },
        },
    ];
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px]" },
        React.createElement(DateRangeSelector, { dateRange: dateRange, setDateRange: setDateRange, firstDate: new Date(startDate), onHorizonChanged: setTimeHorizon, hideAll: true }),
        React.createElement("div", { className: "max-w-[1150px]" },
            React.createElement(BarGraph, { data: graphData, labelAccessor: labelAccessor, valueAccessors: valueAccessors, yAxisLabelAccessor: yAxisLabelAccessor, yScale: [0, 1] })),
        React.createElement("div", { className: "flex flex-col w-full gap-y-4 max-w-[1150px]" },
            React.createElement("div", { className: "ml-auto mr-4" },
                React.createElement(DowloadCSVButton, { resourceUrl: "".concat(resourceUrl, "/csv"), body: dateRanges.map(function (r) { return [r[0], r[1]]; }) })),
            React.createElement(StaticTable, { data: tableData, columnDefinitions: columns, loading: statsLoading }))));
};
