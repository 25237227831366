"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.castArrayQueryParameter = void 0;
// This function can be used when using a query parameter that is intended to be an array. The query
// parser may only see one value and in that case it won't make it into an array. The code below
// will convert it into an array if a value was specified.
function castArrayQueryParameter(arg) {
    return arg && !Array.isArray(arg) ? [arg] : arg;
}
exports.castArrayQueryParameter = castArrayQueryParameter;
