var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from "react";
import { Card } from "../Card";
import { SingleStat } from "../SingleStat";
import { Bar } from "react-chartjs-2";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export var BarGraph = function (props) {
    var colors = [
        {
            gradient0: "rgb(151, 71, 255)",
            gradient1: "rgb(53, 194, 255)",
            tailwind: "bg-gradient-to-r from-[#9747FF] to-[#35C2FF]",
        },
        {
            gradient0: "rgb(56, 255, 160)",
            gradient1: "rgb(0, 255, 240)",
            tailwind: "bg-gradient-to-r from-[#38FFA0] to-[#00FFF0]",
        },
        {
            gradient0: "rgb(244, 157, 55)",
            gradient1: "rgb(255, 109, 109)",
            tailwind: "bg-gradient-to-r from-[#F49D37] to-[#FF6D6D]",
        },
    ];
    var _a = useState(Array(props.valueAccessors.length).fill("--")), tooltipValues = _a[0], setTooltipValues = _a[1];
    var chartRef = useRef(null);
    var _b = useState({
        datasets: [],
    }), chartData = _b[0], setChartData = _b[1];
    var createGradient = function (ctx, area, index) {
        var gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
        gradient.addColorStop(0, colors[index].gradient0);
        gradient.addColorStop(1, colors[index].gradient1);
        return gradient;
    };
    useEffect(function () {
        var chart = chartRef.current;
        if (!chart) {
            return;
        }
        var labels = props.data.map(function (d) { return props.labelAccessor(d); });
        var chartData = {
            labels: labels,
            datasets: props.valueAccessors.map(function (v, i) {
                return {
                    label: v.label,
                    data: props.data.map(function (d) { return v.accessor(d); }),
                    backgroundColor: createGradient(chart.ctx, chart.chartArea, i),
                    fill: true,
                    borderColor: "transparent",
                    borderWidth: 2,
                    // Stacked bar charts require border radius specified per-corner rather than a single number. Otherwise, it will only apply border radius to the top element.
                    borderRadius: {
                        topRight: 6,
                        bottomRight: 6,
                        topLeft: 6,
                        bottomLeft: 6,
                    },
                    borderSkipped: false,
                };
            }),
        };
        setChartData(chartData);
    }, [props, props.data]);
    var options = {
        responsive: true,
        maintainAspectRatio: false,
        events: [
            "mouseenter",
            "mouseleave",
            "mouseout",
            "mousedown",
            "mouseup",
            "mousemove",
        ],
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                intersect: true,
                callbacks: {
                    label: function () {
                        return "";
                    },
                },
                external: function (context) {
                    if (!context.tooltip.dataPoints) {
                        return;
                    }
                    var nearestData = props.data[context.tooltip.dataPoints[0].dataIndex];
                    var currentTooltipValues = __spreadArray([], tooltipValues, true);
                    props.valueAccessors.forEach(function (v, i) {
                        currentTooltipValues[i] = props.yAxisLabelAccessor(v.accessor(nearestData));
                    });
                    // Avoid excessive updates, since this can retrigger the tooltip to render.
                    if (JSON.stringify(currentTooltipValues) !=
                        JSON.stringify(tooltipValues)) {
                        setTooltipValues(currentTooltipValues);
                    }
                },
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    maxTicksLimit: 5,
                    callback: function (value) {
                        return props.yAxisLabelAccessor(value);
                    },
                },
                min: props.yScale ? props.yScale[0] : undefined,
                max: props.yScale ? props.yScale[1] : undefined,
            },
        },
    };
    var plugins = [
        {
            id: "mouseLeave",
            afterEvent: function (chart, args) {
                if (args.event.type == "mouseleave") {
                    setTooltipValues(Array(props.valueAccessors.length).fill("--"));
                }
            },
        },
    ];
    return (React.createElement(Card, { noPadding: true, className: "flex flex-col", spotlights: [], largeSpotlights: [
            "top-[-250px] right-[-250px]",
            "top-[0px] left-[250px]",
            "top-[-200px] left-[50px]",
        ] },
        React.createElement("div", { className: "flex flex-row gap-x-[64px] p-[16px] items-center" },
            props.valueAccessors.map(function (v, i) {
                return (React.createElement(SingleStat, { key: v.label, value: tooltipValues[i], title: v.label }));
            }),
            React.createElement("div", { className: "grow" }),
            React.createElement("div", { className: "flex flex-col gap-y-[8px]" }, props.valueAccessors.map(function (v, i) {
                return (React.createElement("div", { key: v.label, className: "flex flex-row gap-x-[8px]" },
                    React.createElement("div", { className: "".concat(colors[i].tailwind, " rounded-[50%] w-[14px] h-[14px]") }),
                    React.createElement("label", { className: "font-N8p5 text-black-400" }, v.label)));
            }))),
        React.createElement("div", { className: "h-[380px]" },
            React.createElement(Bar, { ref: chartRef, options: options, data: chartData, plugins: plugins }))));
};
export var ComparativeBarGraph = function (_a) {
    var values = _a.values;
    var max = Math.max.apply(Math, values);
    var valuePercents = values
        .map(function (v) { return (max > 0 ? v / max : 0); })
        .map(function (v) { return Math.round(v * 100); })
        .map(function (v) { return Math.max(v, 2); });
    return (React.createElement("div", { className: "flex flex-row gap-x-[2px] h-full items-end" }, valuePercents.map(function (v, i) { return (React.createElement("div", { key: i, className: "w-[8px] bg-gradient-to-t from-main-gradient-from to-main-gradient-to", style: {
            height: "".concat(v, "%"),
        } })); })));
};
