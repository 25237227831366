"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortTypeToString = void 0;
const ee_proto_interfaces_1 = require("ee-proto-interfaces");
function PortTypeToString(portType) {
    switch (portType) {
        case ee_proto_interfaces_1.charger.PortType.CHADEMO:
            return "CHAdeMO";
        case ee_proto_interfaces_1.charger.PortType.NACS:
            return "NACS";
        case ee_proto_interfaces_1.charger.PortType.CCS:
        default:
            return "CCS";
    }
}
exports.PortTypeToString = PortTypeToString;
