import React, { useEffect, useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
export var RadialGraph = function (_a) {
    var title = _a.title, value = _a.value, className = _a.className;
    var chartRef = useRef(null);
    var createGradient = function (ctx, area, alpha) {
        var gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
        gradient.addColorStop(0, "rgba(151,71,255,".concat(alpha, ")"));
        gradient.addColorStop(1, "rgba(53,194,255,".concat(alpha, ")"));
        return gradient;
    };
    var _b = useState({
        datasets: [],
    }), chartData = _b[0], setChartData = _b[1];
    useEffect(function () {
        var chart = chartRef.current;
        if (!chart) {
            return;
        }
        var newChartData = {
            labels: [title, ""],
            datasets: [
                {
                    label: title,
                    data: [value, 1 - value],
                    backgroundColor: [
                        createGradient(chart.ctx, chart.chartArea, 1.0),
                        createGradient(chart.ctx, chart.chartArea, 0.2),
                    ],
                    borderWidth: 0,
                },
            ],
        };
        if (JSON.stringify(newChartData) != JSON.stringify(chartData)) {
            setChartData(newChartData);
        }
    });
    var options = {
        maintainAspectRatio: false,
        animation: {
            duration: 100,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    };
    return (React.createElement("div", { className: className },
        React.createElement(Doughnut, { ref: chartRef, data: chartData, options: options })));
};
