import React, { useLayoutEffect, useRef, useState } from "react";
import { getDateRange, DateRange, TimeHorizon, GetHistoricalDateRanges, } from "../DateRange";
import { ChargerCard, PowernodeCard } from "../Card";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { Auth0Fetcher, Auth0FetcherWithBody } from "../../Auth0Fetcher";
import { process } from "../../Process";
import { events_svc } from "ee-proto-interfaces";
import { ResourceOverviewCards } from "../ResourceOverviewCards";
import { PowerIcon } from "../Images";
var PowerState;
(function (PowerState) {
    PowerState[PowerState["PowerTo"] = 0] = "PowerTo";
    PowerState[PowerState["PowerFrom"] = 1] = "PowerFrom";
    PowerState[PowerState["Inactive"] = 2] = "Inactive";
})(PowerState || (PowerState = {}));
var domToCanvas = function (canvas, x, y) {
    var canvasRect = canvas.getBoundingClientRect();
    return [x - canvasRect.x, y - canvasRect.y];
};
var drawCircle = function (context, x, y, radius, fillStyle) {
    context.beginPath();
    context.setLineDash([]);
    context.arc(x, y, radius, 0, 2 * Math.PI, false);
    context.fillStyle = fillStyle;
    context.fill();
    context.stroke();
};
var drawLine = function (context, x1, y1, x2, y2, state) {
    var fillStyle = state == PowerState.Inactive ? "rgba(100, 100, 100)" : "white";
    context.setLineDash([5, 3]);
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.strokeStyle = fillStyle;
    context.lineWidth = 1;
    context.stroke();
    drawCircle(context, x1, y1, 2, fillStyle);
    drawCircle(context, x2, y2, 2, fillStyle);
};
// Configuration for power indicators.
var segments = [12, 12, 10, 8, 6, 4, 2];
var segmentOpacity = [1, 1, 0.9, 0.7, 0.5, 0.4, 0.2];
var spaceBetweenSegments = 1;
var crossSize = 6;
var getFullIndicatorLength = function () {
    return (segments.reduce(function (s, a) { return s + a; }, 0) +
        spaceBetweenSegments * (segments.length - 1));
};
var PowerIndicatorDirection;
(function (PowerIndicatorDirection) {
    PowerIndicatorDirection[PowerIndicatorDirection["ToLeft"] = 0] = "ToLeft";
    PowerIndicatorDirection[PowerIndicatorDirection["ToRight"] = 1] = "ToRight";
    PowerIndicatorDirection[PowerIndicatorDirection["Down"] = 2] = "Down";
    PowerIndicatorDirection[PowerIndicatorDirection["Up"] = 3] = "Up";
})(PowerIndicatorDirection || (PowerIndicatorDirection = {}));
var PowerIndicatorColors;
(function (PowerIndicatorColors) {
    PowerIndicatorColors[PowerIndicatorColors["Blue"] = 0] = "Blue";
    PowerIndicatorColors[PowerIndicatorColors["Red"] = 1] = "Red";
})(PowerIndicatorColors || (PowerIndicatorColors = {}));
var drawPowerIndicator = function (context, xHead, yHead, direction, colors, lowLimit, highLimit, hardClipLow, hardClipHigh) {
    var start = 0;
    var reverseSegmentIndex = direction == PowerIndicatorDirection.ToLeft ||
        direction == PowerIndicatorDirection.Up;
    switch (direction) {
        case PowerIndicatorDirection.ToLeft:
        case PowerIndicatorDirection.ToRight:
            start = xHead;
            break;
        case PowerIndicatorDirection.Up:
        case PowerIndicatorDirection.Down:
            start = yHead;
            break;
    }
    var colorPalette = [
        {
            headSegmentColor: "71, 200, 255",
            gradientColor0: "151, 71, 255",
            gradientColor1: "26, 159, 234",
        },
        {
            headSegmentColor: "239, 45, 86",
            gradientColor0: "244, 157, 55",
            gradientColor1: "255, 109, 109",
        },
    ];
    for (var segment = 0; segment < segments.length; segment++) {
        var index = reverseSegmentIndex ? segments.length - 1 - segment : segment;
        var segmentSize = segments[index];
        var end = start - segmentSize;
        if (lowLimit != null && (!hardClipHigh ? end : start) < lowLimit) {
            break;
        }
        if (highLimit == null || (!hardClipLow ? start : end) < highLimit) {
            var x = 0;
            var y = 0;
            var width = 0;
            var height = 0;
            switch (direction) {
                case PowerIndicatorDirection.ToLeft:
                case PowerIndicatorDirection.ToRight:
                    x = end;
                    y = yHead - crossSize / 2;
                    width = segmentSize;
                    height = crossSize;
                    break;
                case PowerIndicatorDirection.Up:
                case PowerIndicatorDirection.Down:
                    x = xHead - crossSize / 2;
                    y = end;
                    width = crossSize;
                    height = segmentSize;
                    break;
            }
            var gradient = context.createLinearGradient(x, y, x + width, y + width);
            gradient.addColorStop(0, "rgba(".concat(colorPalette[colors].gradientColor0, ", ").concat(segmentOpacity[index], ")"));
            gradient.addColorStop(1, "rgba(".concat(colorPalette[colors].gradientColor1, ", ").concat(segmentOpacity[index], ")"));
            var headColor = "rgb(".concat(colorPalette[colors].headSegmentColor, ")");
            context.shadowBlur = 5 * segmentOpacity[index];
            context.shadowColor = headColor;
            context.fillStyle = index == 0 ? headColor : gradient;
            context.fillRect(x, y, width, height);
        }
        start -= segmentSize;
        start -= spaceBetweenSegments;
    }
};
var getElementPositionCanvasSpace = function (canvas, id, top) {
    var element = document.getElementById(id);
    if (!element) {
        return [0, 0];
    }
    var bounds = element.getBoundingClientRect();
    var midpointX = (bounds.left + bounds.right) / 2;
    if (top) {
        return domToCanvas(canvas, midpointX, bounds.top);
    }
    else {
        return domToCanvas(canvas, midpointX, bounds.bottom);
    }
};
export var StationOverview = function (_a) {
    var details = _a.details, isNoc = _a.isNoc, hasWriteAccess = _a.hasWriteAccess;
    var _b = useState(TimeHorizon.DAY), timeHorizon = _b[0], setTimeHorizon = _b[1];
    var dateRange = getDateRange(timeHorizon, new Date());
    var historicalDateRanges = GetHistoricalDateRanges(timeHorizon, 4, new Date());
    var _c = useAuth0(), isAuthenticated = _c.isAuthenticated, isLoading = _c.isLoading, getAccessTokenSilently = _c.getAccessTokenSilently;
    var siteStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/").concat(details.id, "/stats?from=").concat(dateRange[0].toISOString(), "&to=").concat(dateRange[1].toISOString()),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var revenue = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/revenue/").concat(details.id),
            getAccessTokenSilently,
            [dateRange],
        ], Auth0FetcherWithBody).data;
    var energy = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/energy/").concat(details.id),
            getAccessTokenSilently,
            [dateRange],
        ], Auth0FetcherWithBody).data;
    var historicalRevenue = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/revenue/").concat(details.id),
            getAccessTokenSilently,
            historicalDateRanges,
        ], Auth0FetcherWithBody).data;
    var historicalEnergy = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/energy/").concat(details.id),
            getAccessTokenSilently,
            historicalDateRanges,
        ], Auth0FetcherWithBody).data;
    var hardwareTelemetry = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/telemetry/").concat(details.id),
            getAccessTokenSilently,
        ], Auth0Fetcher, {
        refreshInterval: 1000,
    }).data;
    var canvasRef = useRef(null);
    var powerIndicatorCanvasRef = useRef(null);
    var stationCardContainerRef = useRef(null);
    var chargerToTopAnchorId = function (charger) {
        return "".concat(charger.friendlyId, "-top");
    };
    var powernodeToTopAnchorId = function (pn) { return "pn-".concat(pn.id, "-top"); };
    var gridPowerId = "grid-power";
    var powerLineAbove = 100;
    var paddingAboveAttachments = 32;
    var activePowerDistribution = useRef(null);
    useLayoutEffect(function () {
        var setCanvasBoundsToClientBounds = function (canvas) {
            if (!stationCardContainerRef.current) {
                return;
            }
            var rect = stationCardContainerRef.current.getBoundingClientRect();
            if (rect.width != canvas.width) {
                canvas.width = rect.width;
            }
            if (rect.height != canvas.height) {
                canvas.height = rect.height;
            }
        };
        if (powerIndicatorCanvasRef.current) {
            setCanvasBoundsToClientBounds(powerIndicatorCanvasRef.current);
        }
        if (canvasRef.current) {
            setCanvasBoundsToClientBounds(canvasRef.current);
        }
    });
    // This useLayoutEffect is responsible for drawing the static/non-animated grid lines.
    useLayoutEffect(function () {
        var canvas = canvasRef.current;
        if (canvas) {
            var getPowerLineY = function (y) { return y - powerLineAbove; };
            var chargers = hardwareTelemetry && hardwareTelemetry.chargers
                ? hardwareTelemetry.chargers.sort(function (a, b) {
                    return a.friendlyId
                        .toLowerCase()
                        .localeCompare(b.friendlyId.toLowerCase());
                })
                : [];
            var powernodes = hardwareTelemetry && hardwareTelemetry.powernodes
                ? hardwareTelemetry.powernodes
                : [];
            var gridPowerBounds = getElementPositionCanvasSpace(canvas, gridPowerId, false);
            var distribution = {
                rows: [],
                xGrid: gridPowerBounds[0],
                yGrid: gridPowerBounds[1] + paddingAboveAttachments,
            };
            var anyPowernodesDischarging_1 = false;
            // Figure out the power distribution.
            var canvasDistributions = powernodes
                .map(function (p) {
                var objectState = PowerState.Inactive;
                switch (p.batteryStatus) {
                    case events_svc.BatteryStatus.CHARGING:
                        objectState = PowerState.PowerTo;
                        break;
                    case events_svc.BatteryStatus.DISCHARGING:
                        objectState = PowerState.PowerFrom;
                        anyPowernodesDischarging_1 = true;
                        break;
                }
                return {
                    id: powernodeToTopAnchorId(p),
                    state: objectState,
                };
            })
                .concat(chargers.map(function (c) {
                var chargerActive = c.ports.filter(function (p) {
                    var state = p.ocppStatus.toUpperCase();
                    return (state == "CHARGING" ||
                        state == "FINISHING" ||
                        state == "PREPARING");
                }).length > 0;
                return {
                    id: chargerToTopAnchorId(c),
                    state: chargerActive ? PowerState.PowerTo : PowerState.Inactive,
                };
            }));
            // Convert canvas elements to raw x/y positions with states.
            for (var i = 0; i < canvasDistributions.length; ++i) {
                var cardBoundsCanvas = getElementPositionCanvasSpace(canvas, canvasDistributions[i].id, true);
                if (distribution.rows.length == 0 ||
                    distribution.rows[distribution.rows.length - 1].yDistribution !=
                        getPowerLineY(cardBoundsCanvas[1])) {
                    var row = {
                        yObject: cardBoundsCanvas[1] - paddingAboveAttachments,
                        yDistribution: getPowerLineY(cardBoundsCanvas[1]),
                        objects: [],
                        powerFromPowernode: i != 0 && anyPowernodesDischarging_1,
                        distributionState: PowerState.Inactive,
                    };
                    distribution.rows.push(row);
                }
                distribution.rows[distribution.rows.length - 1].objects.push({
                    x: cardBoundsCanvas[0],
                    objectState: canvasDistributions[i].state,
                    distributionState: PowerState.Inactive,
                });
            }
            // Fix up distribution to mark upstream nodes as active.
            var gridDistributionState = PowerState.Inactive;
            for (var i = distribution.rows.length - 1; i >= 0; i--) {
                var rowState = PowerState.Inactive;
                for (var j = distribution.rows[i].objects.length - 1; j >= 0; j--) {
                    var object = distribution.rows[i].objects[j];
                    if (object.objectState != PowerState.Inactive) {
                        rowState = object.objectState;
                    }
                    distribution.rows[i].objects[j].distributionState = rowState;
                }
                if (rowState != PowerState.Inactive) {
                    gridDistributionState = rowState;
                }
                distribution.rows[i].distributionState = gridDistributionState;
            }
            var context = canvas.getContext("2d");
            context.clearRect(0, 0, canvas.width, canvas.height);
            // Draw power distribution for cards (powernodes and chargers).
            for (var row = 0; row < distribution.rows.length; ++row) {
                for (var obj = 0; obj < distribution.rows[row].objects.length; ++obj) {
                    var activeObject = distribution.rows[row].objects[obj];
                    var distributionXLeft = obj == 0
                        ? distribution.xGrid
                        : distribution.rows[row].objects[obj - 1].x;
                    var distributionXRight = activeObject.x;
                    var objectY = distribution.rows[row].yObject;
                    var distributionY = distribution.rows[row].yDistribution;
                    drawLine(context, distributionXLeft, distributionY, distributionXRight, distributionY, activeObject.distributionState);
                    drawLine(context, distributionXRight, objectY, distributionXRight, distributionY, activeObject.objectState);
                }
            }
            // Draw grid connections (left side).
            var distributionX = distribution.xGrid;
            for (var row = 0; row < distribution.rows.length; ++row) {
                var distributionTop = row == 0
                    ? distribution.yGrid
                    : distribution.rows[row - 1].yDistribution;
                var distributionBottom = distribution.rows[row].yDistribution;
                drawLine(context, distributionX, distributionTop, distributionX, distributionBottom, distribution.rows[row].distributionState);
            }
            activePowerDistribution.current = distribution;
        }
    });
    var pixelsBetweenIndicators = 750;
    var animationPeriod = 1750;
    var requestRef = useRef();
    // Run exactly once to start the animations.
    useLayoutEffect(function () {
        var animationCallback = function () {
            var canvas = powerIndicatorCanvasRef.current;
            var distribution = activePowerDistribution.current;
            if (canvas && distribution) {
                var context_1 = canvas.getContext("2d");
                context_1.clearRect(0, 0, canvas.width, canvas.height);
                var t = (new Date().getTime() % animationPeriod) / animationPeriod;
                var gridPowerOffset = t * pixelsBetweenIndicators;
                var powernodePowerOffset = ((t + 0.5) % 1.0) * pixelsBetweenIndicators;
                // Draw power indicators draws all of the power indicators on a given graph. It should be called twice, once for grid-sourced power and once for powernode-sourced power.
                var drawPowerIndicators = function (pixelOffset, powernodePower) {
                    var colors;
                    if (powernodePower) {
                        colors = PowerIndicatorColors.Red;
                    }
                    else {
                        colors = PowerIndicatorColors.Blue;
                    }
                    // drawVerticalIndicators and drawHorizontalIndicators draw the power indicators at a pre-determined interval starting at a given position and an offset, returning the pixels that the next segment should offset when continuing. It handles clipping the indicator as well.
                    var drawVerticalIndicators = function (x, topY, bottomY, offset, draw, colors, direction, first, last) {
                        var y = topY + offset;
                        for (; y < bottomY; y += pixelsBetweenIndicators) {
                            if (draw) {
                                drawPowerIndicator(context_1, x, y, direction, colors, topY, bottomY, first, last);
                            }
                        }
                        var remainingPixels = y - bottomY;
                        if (draw && remainingPixels <= getFullIndicatorLength()) {
                            drawPowerIndicator(context_1, x, y, direction, colors, topY, bottomY, first, last);
                        }
                        return remainingPixels;
                    };
                    var drawHorizontalIndicators = function (leftX, rightX, y, offset, draw, colors, direction, first, last) {
                        var x = leftX + offset;
                        for (; x < rightX; x += pixelsBetweenIndicators) {
                            if (draw) {
                                drawPowerIndicator(context_1, x, y, direction, colors, leftX, rightX, first, last);
                            }
                        }
                        var remainingPixels = x - rightX;
                        if (draw && remainingPixels <= getFullIndicatorLength()) {
                            drawPowerIndicator(context_1, x, y, direction, colors, leftX, rightX, first, last);
                        }
                        return remainingPixels;
                    };
                    for (var row = 0; row < distribution.rows.length; ++row) {
                        if (distribution.rows[row].distributionState == PowerState.Inactive) {
                            break;
                        }
                        // Draw distribution column.
                        pixelOffset = drawVerticalIndicators(distribution.xGrid, row == 0
                            ? distribution.yGrid
                            : distribution.rows[row - 1].yDistribution, distribution.rows[row].yDistribution, pixelOffset, !powernodePower || distribution.rows[row].powerFromPowernode, colors, PowerIndicatorDirection.Down, row == 0, row == distribution.rows.length - 1);
                        // Draw distribution to row and distribution to object.
                        if (!powernodePower ||
                            (powernodePower && distribution.rows[row].powerFromPowernode)) {
                            var xOffset = pixelOffset;
                            for (var i = 0; i < distribution.rows[row].objects.length; ++i) {
                                xOffset = drawHorizontalIndicators(i == 0
                                    ? distribution.xGrid
                                    : distribution.rows[row].objects[i - 1].x, distribution.rows[row].objects[i].x, distribution.rows[row].yDistribution, xOffset, distribution.rows[row].objects[i].distributionState ==
                                    PowerState.PowerTo, colors, PowerIndicatorDirection.ToRight, i == 0, i == distribution.rows[row].objects.length - 1);
                                drawVerticalIndicators(distribution.rows[row].objects[i].x, distribution.rows[row].yDistribution, distribution.rows[row].yObject, xOffset, distribution.rows[row].objects[i].objectState ==
                                    PowerState.PowerTo, colors, PowerIndicatorDirection.Down, false, false);
                            }
                        }
                        // Draw distribution from row (only if we're drawing powernode power).
                        if (powernodePower) {
                            var xFromOffset = -pixelOffset + getFullIndicatorLength();
                            for (var i = 0; i < distribution.rows[row].objects.length; ++i) {
                                xFromOffset = drawHorizontalIndicators(i == 0
                                    ? distribution.xGrid
                                    : distribution.rows[row].objects[i - 1].x, distribution.rows[row].objects[i].x, distribution.rows[row].yDistribution, xFromOffset, distribution.rows[row].objects[0].distributionState ==
                                    PowerState.PowerFrom, colors, PowerIndicatorDirection.ToLeft, i == 0, i == distribution.rows[row].objects.length - 1);
                                drawVerticalIndicators(distribution.rows[row].objects[i].x, distribution.rows[row].yDistribution, distribution.rows[row].yObject, xFromOffset, distribution.rows[row].objects[i].objectState ==
                                    PowerState.PowerFrom, colors, PowerIndicatorDirection.Up, false, false);
                            }
                        }
                    }
                };
                drawPowerIndicators(gridPowerOffset, false);
                drawPowerIndicators(powernodePowerOffset, true);
            }
            requestRef.current = requestAnimationFrame(animationCallback);
        };
        requestRef.current = requestAnimationFrame(animationCallback);
        return function () { return cancelAnimationFrame(requestRef.current); };
    }, []);
    var requestUrl = "".concat(process.env.CORE_SVC_URL, "/pricing/current/").concat(details.id);
    var pricing = useSWR(isLoading || !isAuthenticated ? null : [requestUrl, getAccessTokenSilently], Auth0Fetcher).data;
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px]" },
        React.createElement(DateRange, { value: timeHorizon, setValue: setTimeHorizon }),
        React.createElement(ResourceOverviewCards, { revenue: revenue ? revenue[0] : undefined, uptime: siteStats === null || siteStats === void 0 ? void 0 : siteStats.uptime, usage: siteStats === null || siteStats === void 0 ? void 0 : siteStats.usage, energyDelivered: energy ? energy[0] : undefined, revenueHistory: historicalRevenue, energyDeliveredHistory: historicalEnergy }),
        React.createElement("div", { className: "grid mt-[64px]" },
            React.createElement("div", { className: "h-full w-full overlap-grid" },
                React.createElement("canvas", { ref: canvasRef, className: "h-full w-full" })),
            React.createElement("div", { className: "h-full w-full overlap-grid" },
                React.createElement("canvas", { ref: powerIndicatorCanvasRef, className: "h-full w-full" })),
            React.createElement("div", { className: "overlap-grid" },
                React.createElement("div", { className: "flex flex-col gap-y-[32px]", ref: stationCardContainerRef },
                    React.createElement("div", { className: "flex flex-row items-center" },
                        React.createElement("div", { className: "w-fit h-fit", id: gridPowerId }, PowerIcon),
                        React.createElement("div", { className: "flex flex-col gap-y-[4px]" },
                            React.createElement("label", { className: "font-N9 text-white" }, "GRID POWER"),
                            React.createElement("label", { className: "font-N7 text-white" }, "".concat(details.gridLimit, " kW")))),
                    React.createElement("div", { className: "flex flex-row flex-wrap gap-y-[128px] pt-[128px] gap-x-[64px] ml-[96px]" }, hardwareTelemetry &&
                        hardwareTelemetry.powernodes &&
                        hardwareTelemetry.powernodes
                            .sort(function (a, b) { return a.id - b.id; })
                            .map(function (pn) {
                            return (React.createElement(PowernodeCard, { key: pn.id, id: pn.id, soc: pn.soc, chargeRate: pn.chargeRateKw, batteryStatus: pn.batteryStatus, powernodeStatus: pn.powernodeStatus, anchorId: powernodeToTopAnchorId(pn) }));
                        })),
                    React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[64px] gap-y-[128px] pt-[64px] ml-[96px]" }, hardwareTelemetry &&
                        hardwareTelemetry.chargers &&
                        hardwareTelemetry.chargers
                            .sort(function (a, b) {
                            return a.friendlyId
                                .toLowerCase()
                                .localeCompare(b.friendlyId.toLowerCase());
                        })
                            .map(function (charger) {
                            return (React.createElement(ChargerCard, { key: charger.friendlyId, isNoc: isNoc, id: charger.friendlyId, chargerPowerHardcap: charger.chargerPowerHardcap, chargerAdvertisedMaxPower: charger.chargerAdvertisedMaxPower, ocppId: charger.ocppId, port1: charger.ports[0], port2: charger.ports[1], unavailableReason: charger.unavailableReason, unavailableReasons: charger.unavailableReasons, pricingModel: pricing, anchorId: chargerToTopAnchorId(charger), allowRemoteAuthorization: details.settings.enableRemotePaymentAuthorization &&
                                    hasWriteAccess }));
                        })))))));
};
