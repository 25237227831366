import React, { useState } from "react";
import useSWR from "swr";
import { useAuth0 } from "@auth0/auth0-react";
import { process } from "../../Process";
import { Auth0FetcherWithBody } from "../../Auth0Fetcher";
import { Card } from "../Card";
import { SingleStat } from "../SingleStat";
import { GetDisplayPrice } from "ee-utils";
import { Dropdown } from "../Dropdown";
import { LineGraph } from "../Graph/LineGraph";
import { events_svc } from "ee-proto-interfaces";
function getDateString(date) {
    return "".concat(date.getMonth() + 1, "/").concat(date.getDate(), "/").concat(date.getFullYear());
}
var MultiStatCard = function (_a) {
    var title = _a.title, stats = _a.stats, overlay = _a.overlay;
    return (React.createElement(Card, { className: "grid z-[-10]", spotlights: ["top-[-360px] right-[-230px]", "top-[-150px] left-[-250px]"], noPadding: true },
        React.createElement("div", { className: "overlap-grid flex flex-col gap-y-[16px] p-[16px] z-[-50]" },
            React.createElement("label", { className: "text-black-600 font-N8p5" }, title),
            React.createElement("div", { className: "flex flex-row gap-x-[48px]" }, stats.map(function (s, i) { return (React.createElement(SingleStat, { key: i, title: s[0], value: s[1], reverse: true })); }))),
        overlay != null && (React.createElement("div", { className: "overlap-grid bg-black-800/80 h-full w-full flex flex-col items-center justify-center" },
            React.createElement("label", { className: "font-N7 text-white px-[16px]" }, overlay)))));
};
export var StationPower = function (_a) {
    var _b, _c;
    var details = _a.details, isNoc = _a.isNoc, startDate = _a.startDate;
    var _d = useAuth0(), isAuthenticated = _d.isAuthenticated, isLoading = _d.isLoading, getAccessTokenSilently = _d.getAccessTokenSilently;
    var dropdownOptions = [];
    var date = new Date();
    var todayDateString = getDateString(date);
    var yesterday = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);
    var _e = useState(getDateString(yesterday)), dropdownState = _e[0], setDropdownState = _e[1];
    var todaySelected = dropdownState == todayDateString;
    var overlay = todaySelected
        ? "Demand is calculated at 12AM for the previous day."
        : undefined;
    var i = 0;
    while (date.getTime() > new Date(startDate).getTime()) {
        var dateString = getDateString(date);
        var label = dateString;
        if (i == 0) {
            label = "Today";
        }
        else if (i == 1) {
            label = "Yesterday";
        }
        dropdownOptions.push({
            name: label,
            value: dateString,
        });
        date.setDate(date.getDate() - 1);
        ++i;
    }
    var sitePowerStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/power/").concat(details.id),
            getAccessTokenSilently,
            { date: dropdownState },
        ], Auth0FetcherWithBody).data;
    var demandCharge = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/tariffs/daily_tariff/").concat(details.id),
            getAccessTokenSilently,
            { date: dropdownState },
        ], Auth0FetcherWithBody).data;
    var powerLabelAccesor = function (d) {
        return d.label;
    };
    var powerValueAccessors = [
        {
            label: "GRID LIMIT",
            accessor: function (d) { return d.gridLimitKw; },
            omitFill: true,
            dashed: true,
        },
        {
            label: "GRID DEMAND",
            accessor: function (d) { return d.gridPowerKw; },
        },
        {
            label: "GRID DEMAND W/O POWERNODE",
            accessor: function (d) { return d.gridPowerWithoutPowernodeKw; },
            omitFill: true,
        },
        {
            label: "POWERNODE CHARGE/DISCHARGE",
            accessor: function (d) { return -d.powernodePowerKw; },
            omitFill: true,
        },
    ];
    var hardwarePowerValueAccessors = [];
    hardwarePowerValueAccessors.push({
        label: "GRID LIMIT",
        accessor: function (d) { return d.gridLimitKw; },
        omitFill: true,
        dashed: true,
        colorOverride: 0,
    });
    hardwarePowerValueAccessors.push({
        label: "STATION NET DEMAND",
        accessor: function (d) { return d.gridPowerKw; },
        omitFill: true,
        colorOverride: 1,
    });
    hardwarePowerValueAccessors = hardwarePowerValueAccessors.concat(details.chargers
        .map(function (c) {
        return {
            label: "CHARGER ".concat(c.friendlyId),
            accessor: function (d) { var _a; return (_a = d.chargerPowersKw[c.ocppId]) !== null && _a !== void 0 ? _a : 0.0; },
            omitFill: true,
            colorOverride: 3,
            hideTopTooltip: true,
        };
    })
        .concat(details.powernodes.map(function (p) {
        return {
            label: "POWERNODE ".concat(p.id),
            accessor: function (d) { var _a; return (_a = -d.powernodePowersKw[p.id]) !== null && _a !== void 0 ? _a : 0.0; },
            omitFill: true,
            colorOverride: 2,
            hideTopTooltip: true,
        };
    })));
    var powerLabelSet = new Set();
    var powerGraphData = sitePowerStats
        ? sitePowerStats.history.map(function (h) {
            var _a, _b, _c, _d;
            var d = new Date(h.time);
            var label = d.toLocaleTimeString([], {
                hour: "numeric",
                hour12: true,
            });
            powerLabelSet.add(label);
            return {
                gridLimitKw: details.gridLimit,
                gridPowerKw: (_a = h.gridPowerKw) !== null && _a !== void 0 ? _a : 0.0,
                gridPowerWithoutPowernodeKw: (_b = h.baseloadKw) !== null && _b !== void 0 ? _b : 0.0,
                powernodePowerKw: (_d = (_c = h.entities) === null || _c === void 0 ? void 0 : _c.filter(function (e) { return e.type == events_svc.EntityType.POWERNODE; }).map(function (e) { var _a; return Number((_a = e.powerKw) !== null && _a !== void 0 ? _a : 0.0); }).reduce(function (s, a) { return s + a; }, 0)) !== null && _d !== void 0 ? _d : 0.0,
                time: d.getTime(),
                label: label,
                tooltip: d.toLocaleTimeString([], {
                    hour: "numeric",
                    hour12: true,
                    minute: "2-digit",
                }),
                chargerPowersKw: h.entities
                    .filter(function (e) { return e.type == null || e.type == events_svc.EntityType.CHARGER; })
                    .reduce(function (a, e) {
                    a[e.name] = e.powerKw;
                    return a;
                }, {}),
                powernodePowersKw: h.entities
                    .filter(function (e) { return e.type == null || e.type == events_svc.EntityType.POWERNODE; })
                    .reduce(function (a, e) {
                    a[e.name] = e.powerKw;
                    return a;
                }, {}),
            };
        })
        : [];
    var tooltipAccessor = function (data) { return "".concat(data.tooltip); };
    var demandChargeStats = [
        [
            "W/ POWERNODE",
            demandCharge && !todaySelected
                ? "$".concat(GetDisplayPrice(demandCharge.demandChargeWithPowernode, demandCharge.demandChargePrecision))
                : "--",
        ],
        [
            "W/O POWERNODE",
            demandCharge && !todaySelected
                ? "$".concat(GetDisplayPrice(demandCharge.demandChargeWithoutPowernode, demandCharge.demandChargePrecision))
                : "--",
        ],
        [
            "DIFFERENCE",
            demandCharge && !todaySelected
                ? "$".concat(GetDisplayPrice(demandCharge.demandChargeWithoutPowernode -
                    demandCharge.demandChargeWithPowernode, demandCharge.demandChargePrecision))
                : "--",
        ],
    ];
    var powerChargeStats = [
        [
            "W/ POWERNODE",
            demandCharge && !todaySelected
                ? "".concat(Number(demandCharge.peakPowerWithPowernodeKw).toFixed(0), " kW")
                : "--",
        ],
        [
            "W/O POWERNODE",
            demandCharge && !todaySelected
                ? "".concat(Number(demandCharge.peakPowerWithoutPowernodeKw).toFixed(0), " kW")
                : "--",
        ],
        [
            "DIFFERENCE",
            demandCharge && !todaySelected
                ? "".concat(Number(((_b = demandCharge.peakPowerWithoutPowernodeKw) !== null && _b !== void 0 ? _b : 0.0) -
                    ((_c = demandCharge.peakPowerWithPowernodeKw) !== null && _c !== void 0 ? _c : 0.0)).toFixed(0), " kW")
                : "--",
        ],
    ];
    return (React.createElement("div", { className: "flex flex-col gap-y-[48px] w-[75%]" },
        React.createElement("div", { className: "flex flex-row gap-x-[32px] items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Select Day"),
            React.createElement(Dropdown, { items: dropdownOptions, onClick: setDropdownState, currentSelection: dropdownState })),
        React.createElement("label", { className: "font-N6 text-white mt-[32px]" }, "Station Power"),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            isNoc && (React.createElement(MultiStatCard, { title: "ESTIMATED DEMAND CHARGE", stats: demandChargeStats, overlay: overlay })),
            React.createElement(MultiStatCard, { title: "PEAK POWER", stats: powerChargeStats, overlay: overlay })),
        React.createElement(LineGraph, { labelAccessor: powerLabelAccesor, valueAccessors: powerValueAccessors, data: powerGraphData, yAxisValueToLabel: function (v) { return "".concat(Math.round(v), " kW"); }, manualOrder: true, maxXTicks: powerLabelSet.size, tooltipLabelAccessor: tooltipAccessor }),
        React.createElement("label", { className: "font-N6 text-white mt-[128px]" }, "Hardware Power"),
        React.createElement(LineGraph, { labelAccessor: powerLabelAccesor, valueAccessors: hardwarePowerValueAccessors, data: powerGraphData, yAxisValueToLabel: function (v) { return "".concat(Math.round(v), " kW"); }, manualOrder: true, maxXTicks: powerLabelSet.size, showMouseTooltip: true, tooltipLabelAccessor: tooltipAccessor, colorLabelOverrides: [
                "GRID LIMIT",
                "STATION NET DEMAND",
                "POWERNODES",
                "CHARGERS",
            ] })));
};
