"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CPO_LOGO_URL = exports.SECONDS_TO_MILLISECONDS = exports.OCPI_GENERIC_SUCCESS_STATUS_CODE = exports.CUSTOMER_SERVICE_PHONE_NUMBER = exports.KWH_PRECISION = void 0;
// The precision we display on all driver-facing UIs. This is required to comply with CTEP which mandates at least 4 digits of precision.
exports.KWH_PRECISION = 4;
// The customer service phone number.
exports.CUSTOMER_SERVICE_PHONE_NUMBER = "(888) 409-2980";
exports.OCPI_GENERIC_SUCCESS_STATUS_CODE = 1000;
exports.SECONDS_TO_MILLISECONDS = 1000;
exports.CPO_LOGO_URL = "https://electriceradev1.blob.core.windows.net/images/EE_Icon_PNG.png";
