import React from "react";
import { BarStatCard, RadialStatCard } from "./Card";
import { GetDisplayPrice } from "ee-utils";
function uptimeToString(uptime) {
    var percentage = uptime * 100;
    if (percentage === 100) {
        return "".concat(percentage.toFixed(0));
    }
    else if (percentage < 99) {
        return "".concat(percentage.toFixed(2));
    }
    var trackingNumber = percentage * 10;
    for (var trackingFixedPositions = 1; trackingFixedPositions < 5; trackingFixedPositions++) {
        if (trackingNumber % 10 < 9) {
            return "".concat(percentage.toFixed(trackingFixedPositions));
        }
        trackingNumber *= 10;
    }
    trackingNumber = Math.floor(trackingNumber);
    trackingNumber /= 100000;
    return "".concat(trackingNumber.toFixed(5));
}
export var ResourceOverviewCards = function (_a) {
    var revenue = _a.revenue, revenueHistory = _a.revenueHistory, energyDelivered = _a.energyDelivered, energyDeliveredHistory = _a.energyDeliveredHistory, uptime = _a.uptime, usage = _a.usage, className = _a.className;
    return (React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px] ".concat(className !== null && className !== void 0 ? className : "") },
        React.createElement(BarStatCard, { title: "REVENUE", primaryValue: revenue, valueToString: function (v) {
                return "$".concat(GetDisplayPrice(v.revenueAmount, v.precision));
            }, historicalValues: revenueHistory
                ? revenueHistory.map(function (r) { return r.revenueAmount; })
                : undefined }),
        React.createElement(BarStatCard, { title: "ENERGY DELIVERED", primaryValue: energyDelivered, valueToString: function (v) { return "".concat(Number(v).toFixed(1), " kWh"); }, historicalValues: energyDeliveredHistory }),
        React.createElement(RadialStatCard, { title: "UPTIME", value: uptime, valueToString: uptimeToString }),
        React.createElement(RadialStatCard, { title: "USAGE", value: usage })));
};
