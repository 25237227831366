var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Disclosure } from "@headlessui/react";
import useSWR from "swr";
import { useReactTable, getCoreRowModel, getPaginationRowModel, getSortedRowModel, } from "@tanstack/react-table";
import { GetDisplayPrice } from "ee-utils";
import { DateRangeSelector } from "../DateRange";
import { TableBase } from "../Table";
import { useData } from "../../useData";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
export var NOCPaymentMismatches = function (_a) {
    var _b, _c, _d, _e;
    var earliestTransaction = _a.earliestTransaction, siteId = _a.siteId;
    var _f = useAuth0(), isAuthenticated = _f.isAuthenticated, isLoading = _f.isLoading, getAccessTokenSilently = _f.getAccessTokenSilently;
    var _g = useState([
        new Date(),
        new Date(),
    ]), dateRange = _g[0], setDateRange = _g[1];
    var apiSearchParams = new URLSearchParams();
    apiSearchParams.append("start", dateRange[0].toISOString());
    apiSearchParams.append("end", dateRange[1].toISOString());
    if (siteId) {
        apiSearchParams.append("siteIds", siteId.toString());
    }
    var requestUrl = "".concat(process.env.CORE_SVC_URL, "/pricing/current/").concat(siteId);
    var pricing = useSWR(isLoading || !isAuthenticated || !siteId
        ? null
        : [requestUrl, getAccessTokenSilently], Auth0Fetcher).data;
    var _h = useData("".concat(process.env.CORE_SVC_URL, "/transactions/revenue/mismatches?").concat(apiSearchParams.toString()), !isLoading && isAuthenticated), revenueMismatches = _h.data, dataIsLoading = _h.isLoading;
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("label", { className: "font-N5 text-white" }, "NOC Payment Mismatches"),
        React.createElement(DateRangeSelector, { dateRange: dateRange, setDateRange: setDateRange, firstDate: new Date(earliestTransaction) }),
        !dataIsLoading && (React.createElement(FailedTransactionsWithEnergyDispensed, { dataIsLoading: dataIsLoading, failedTransactionsWithEnergy: revenueMismatches === null || revenueMismatches === void 0 ? void 0 : revenueMismatches.failedTransactionsWithEnergy, pricePerKwh: pricing && !pricing.freemode && ((_c = (_b = pricing.pricePerKwh) === null || _b === void 0 ? void 0 : _b.amount) === null || _c === void 0 ? void 0 : _c.amount), pricePrecision: pricing &&
                !pricing.freemode &&
                ((_e = (_d = pricing.pricePerKwh) === null || _d === void 0 ? void 0 : _d.amount) === null || _e === void 0 ? void 0 : _e.precision) })),
        !dataIsLoading && (React.createElement(OnlyExistsInElectricEra, { dataIsLoading: dataIsLoading, onlyExistsInEE: revenueMismatches === null || revenueMismatches === void 0 ? void 0 : revenueMismatches.onlyExistsInEE })),
        !dataIsLoading && (React.createElement(OnlyExistsInWorldnet, { dataIsLoading: dataIsLoading, onlyExistsInMAPI: revenueMismatches === null || revenueMismatches === void 0 ? void 0 : revenueMismatches.onlyExistsInMAPI })),
        !dataIsLoading && (React.createElement(RevenueValueMismatches, { dataIsLoading: dataIsLoading, revenueValueMismatches: revenueMismatches === null || revenueMismatches === void 0 ? void 0 : revenueMismatches.revenueValueMismatches }))));
};
var RevenueValueMismatches = function (_a) {
    var dataIsLoading = _a.dataIsLoading, revenueValueMismatches = _a.revenueValueMismatches;
    var columns = [
        {
            id: "startTime",
            header: "Start Time",
            accessorFn: function (row) {
                return new Date(row.eeTransaction.sessionStart);
            },
            cell: function (info) {
                return info.getValue().toLocaleString();
            },
            sortingFn: "datetime",
        },
        {
            header: "Duration",
            accessorFn: function (row) {
                var totalSeconds = Math.floor((new Date(row.eeTransaction.sessionEnd).getTime() -
                    new Date(row.eeTransaction.sessionStart).getTime()) /
                    1000);
                if (totalSeconds <= 0) {
                    return "0m 0s";
                }
                var chargeSeconds = totalSeconds % 60;
                var chargeMinutes = Math.floor(totalSeconds / 60);
                return "".concat(chargeMinutes, "m ").concat(chargeSeconds, "s");
            },
        },
        {
            header: "Energy Delivered",
            accessorFn: function (row) {
                return "".concat(Number(row.eeTransaction.energyDeliveredKwh).toFixed(2), " kWh");
            },
        },
        {
            header: "Charger ID",
            accessorFn: function (row) {
                return row.eeTransaction.chargerFriendlyId;
            },
        },
        {
            header: "Amount Charged",
            accessorFn: function (row) {
                return "$".concat(GetDisplayPrice(row.eeTransaction.transactionAmount, row.eeTransaction.transactionAmountPrecision));
            },
        },
        {
            header: "Worldnet Amount",
            accessorFn: function (row) {
                return "$".concat(row.mapiTransaction.amount);
            },
        },
    ];
    var table = useReactTable({
        data: revenueValueMismatches,
        columns: columns,
        manualPagination: false,
        initialState: {
            sorting: [
                {
                    id: "startTime",
                    desc: true,
                },
            ],
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (React.createElement(ExpandableTableBase, { expandableTitle: "Revenue Value Mismatches", buildRowLink: function (row) { return "/transaction/".concat(row.eeTransaction.transactionId); }, table: table, paginated: true, loading: dataIsLoading, columnDefinitions: columns }));
};
var FailedTransactionsWithEnergyDispensed = function (_a) {
    var dataIsLoading = _a.dataIsLoading, failedTransactionsWithEnergy = _a.failedTransactionsWithEnergy, pricePerKwh = _a.pricePerKwh, pricePrecision = _a.pricePrecision;
    var columns = [
        {
            id: "startTime",
            header: "Start Time",
            accessorFn: function (row) {
                return new Date(row.sessionStart);
            },
            cell: function (info) {
                return info.getValue().toLocaleString();
            },
            sortingFn: "datetime",
        },
        {
            header: "Duration",
            accessorFn: function (row) {
                var totalSeconds = Math.floor((new Date(row.sessionEnd).getTime() -
                    new Date(row.sessionStart).getTime()) /
                    1000);
                if (totalSeconds <= 0) {
                    return "0m 0s";
                }
                var chargeSeconds = totalSeconds % 60;
                var chargeMinutes = Math.floor(totalSeconds / 60);
                return "".concat(chargeMinutes, "m ").concat(chargeSeconds, "s");
            },
        },
        {
            header: "Energy Delivered",
            accessorFn: function (row) {
                return "".concat(Number(row.energyDeliveredKwh).toFixed(2), " kWh");
            },
        },
        {
            header: "Charger ID",
            accessorFn: function (row) {
                return row.chargerFriendlyId;
            },
        },
        {
            header: "Amount Charged",
            accessorFn: function (row) {
                return "$".concat(GetDisplayPrice(row.transactionAmount, row.transactionAmountPrecision));
            },
        },
    ];
    if (pricePerKwh && pricePrecision) {
        columns.push({
            header: "Unaccounted for Amount",
            accessorFn: function (row) {
                var energyDelivered = Number(row.energyDeliveredKwh);
                var energyPrice = energyDelivered * pricePerKwh;
                var unaccountedForCost = energyPrice - row.transactionAmount;
                return "$".concat(GetDisplayPrice(unaccountedForCost, pricePrecision));
            },
        });
    }
    var table = useReactTable({
        data: failedTransactionsWithEnergy,
        columns: columns,
        manualPagination: false,
        initialState: {
            sorting: [
                {
                    id: "startTime",
                    desc: true,
                },
            ],
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (React.createElement(ExpandableTableBase, { expandableTitle: "Failed Transactions With Energy", buildRowLink: function (row) { return "/transaction/".concat(row.transactionId); }, table: table, paginated: true, loading: dataIsLoading, columnDefinitions: columns }));
};
var OnlyExistsInElectricEra = function (_a) {
    var dataIsLoading = _a.dataIsLoading, onlyExistsInEE = _a.onlyExistsInEE;
    var columns = [
        {
            id: "startTime",
            header: "Start Time",
            accessorFn: function (row) {
                return new Date(row.sessionStart);
            },
            cell: function (info) {
                return info.getValue().toLocaleString();
            },
            sortingFn: "datetime",
        },
        {
            header: "Duration",
            accessorFn: function (row) {
                var totalSeconds = Math.floor((new Date(row.sessionEnd).getTime() -
                    new Date(row.sessionStart).getTime()) /
                    1000);
                if (totalSeconds <= 0) {
                    return "0m 0s";
                }
                var chargeSeconds = totalSeconds % 60;
                var chargeMinutes = Math.floor(totalSeconds / 60);
                return "".concat(chargeMinutes, "m ").concat(chargeSeconds, "s");
            },
        },
        {
            header: "Energy Delivered",
            accessorFn: function (row) {
                return "".concat(Number(row.energyDeliveredKwh).toFixed(2), " kWh");
            },
        },
        {
            header: "Charger ID",
            accessorFn: function (row) {
                return row.chargerFriendlyId;
            },
        },
        {
            header: "Amount Charged",
            accessorFn: function (row) {
                return "$".concat(GetDisplayPrice(row.transactionAmount, row.transactionAmountPrecision));
            },
        },
    ];
    var table = useReactTable({
        data: onlyExistsInEE,
        columns: columns,
        manualPagination: false,
        initialState: {
            sorting: [
                {
                    id: "startTime",
                    desc: true,
                },
            ],
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (React.createElement(ExpandableTableBase, { expandableTitle: "Not Found in Worldnet", buildRowLink: function (row) { return "/transaction/".concat(row.transactionId); }, table: table, paginated: true, loading: dataIsLoading, columnDefinitions: columns }));
};
var OnlyExistsInWorldnet = function (_a) {
    var dataIsLoading = _a.dataIsLoading, onlyExistsInMAPI = _a.onlyExistsInMAPI;
    var columns = [
        {
            id: "transactionTime",
            header: "Transaction Time",
            accessorFn: function (row) {
                return new Date(row.dateTime);
            },
            cell: function (info) {
                return info.getValue().toLocaleString();
            },
            sortingFn: "datetime",
        },
        {
            header: "Card Type",
            accessorFn: function (row) {
                return row.cardType;
            },
        },
        {
            header: "Type",
            accessorFn: function (row) {
                return row.type;
            },
        },
        {
            header: "Status",
            accessorFn: function (row) {
                return row.status;
            },
        },
        {
            header: "Amount",
            accessorFn: function (row) {
                return "$".concat(row.amount);
            },
        },
    ];
    var table = useReactTable({
        data: onlyExistsInMAPI,
        columns: columns,
        initialState: {
            sorting: [
                {
                    id: "transactionTime",
                    desc: true,
                },
            ],
        },
        manualPagination: false,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (React.createElement(ExpandableTableBase, { expandableTitle: "Not Found in Electric Era", table: table, paginated: true, loading: dataIsLoading, columnDefinitions: columns }));
};
var ExpandableTableBase = function (props) {
    var expandableTitle = props.expandableTitle;
    var tableBaseProps = __assign({}, props);
    delete tableBaseProps.expandableTitle;
    return (React.createElement(Disclosure, { defaultOpen: true }, function (_a) {
        var open = _a.open;
        return (React.createElement(React.Fragment, null,
            React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                React.createElement("label", { className: "font-N6" }, expandableTitle),
                open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
            React.createElement(Disclosure.Panel, { className: "w-full" },
                React.createElement(TableBase, __assign({}, tableBaseProps)))));
    }));
};
