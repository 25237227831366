import React, { useEffect } from "react";
export var HubspotForm = function (_a) {
    var portalId = _a.portalId, formId = _a.formId, prefillData = _a.prefillData;
    useEffect(function () {
        var script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        document.body.appendChild(script);
        script.addEventListener("load", function () {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: portalId,
                    formId: formId,
                    target: "#hubspotForm-".concat(formId),
                    onFormReady: function ($form) {
                        for (var key in prefillData) {
                            var el = $form.querySelector("[name=\"".concat(key, "\"]"));
                            if (el) {
                                el.value = prefillData[key];
                            }
                        }
                    },
                });
            }
        });
    }, []);
    return (React.createElement("div", null,
        React.createElement("div", { id: "hubspotForm-".concat(formId) })));
};
