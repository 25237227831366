"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Err = exports.Ok = exports.encase = void 0;
/**
 * Encase wraps a function that may throw an error and returns a new function that returns a Result.
 */
const encase = (fn) => (...args) => {
    try {
        return { ok: true, value: fn(...args) };
    }
    catch (e) {
        return { ok: false, error: e };
    }
};
exports.encase = encase;
/**
 * Ok wraps a value and returns a Result with ok set to true.
 */
const Ok = (value) => ({ ok: true, value });
exports.Ok = Ok;
/**
 * Err wraps an error and returns a Result with ok set to false.
 */
const Err = (error) => ({ ok: false, error });
exports.Err = Err;
