import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
export var Dropdown = function (_a) {
    var _b;
    var items = _a.items, currentSelection = _a.currentSelection, onClick = _a.onClick, comparator = _a.comparator, disabled = _a.disabled;
    var comp = comparator !== null && comparator !== void 0 ? comparator : (function (i1, i2) { return i1 == i2; });
    var selectedItem = items.find(function (i) { return comp(i.value, currentSelection); });
    var dropdownLabel = (_b = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.selectedLabel) !== null && _b !== void 0 ? _b : selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.name;
    return (React.createElement(Menu, { as: "div", className: "relative inline-block text-left bg-black-1000 rounded-[8px] ".concat(disabled ? "opacity-50 cursor-not-allowed" : "") }, function (_a) {
        var open = _a.open;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(Menu.Button, { className: "inline-flex w-full justify-center px-[16px] py-[16px] font-N8p5 ".concat(disabled
                        ? "text-gray-400 bg-gray-200"
                        : "text-black-600 hover:bg-opacity-30 hover:text-white", " focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"), disabled: disabled },
                    dropdownLabel,
                    React.createElement(ChevronDownIcon, { className: "ml-[128px] -mr-1 h-3 w-3 ".concat(disabled ? "text-gray-400" : "text-black-600 hover:text-white"), "aria-hidden": "true" }))),
            React.createElement(Transition, { show: open && !disabled, enter: "transition duration-100 ease-out", enterFrom: "transform scale-95 opacity-0", enterTo: "transform scale-100 opacity-100", leave: "transition duration-75 ease-out", leaveFrom: "transform scale-100 opacity-100", leaveTo: "transform scale-95 opacity-0", className: "relative z-50" },
                React.createElement(Menu.Items, { static: true, className: "absolute w-56 rounded-md bg-black-1000 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-40 max-h-[512px] overflow-y-auto" }, items.map(function (item) {
                    return (React.createElement(Menu.Item, { key: item.name, as: Fragment },
                        React.createElement("label", { className: "font-N8p5 ".concat(disabled ? "text-gray-400" : "hover:text-white", " group flex w-full items-center rounded-md px-2 py-2 text-black-600 z-50"), onClick: function () {
                                if (!disabled)
                                    onClick(item.value);
                            } }, item.name)));
                })))));
    }));
};
