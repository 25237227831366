var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { classNames } from "../class-names";
export var CenteredSingleStat = function (_a) {
    var statName = _a.statName, statVal = _a.statVal, loading = _a.loading, _b = _a.statUnit, statUnit = _b === void 0 ? "" : _b, _c = _a.highlightColor, highlightColor = _c === void 0 ? "" : _c, _d = _a.prefixUnit, prefixUnit = _d === void 0 ? "" : _d, _e = _a.prefixIcon, prefixIcon = _e === void 0 ? undefined : _e, _f = _a.postfixIcon, postfixIcon = _f === void 0 ? undefined : _f;
    if (loading) {
        return (_jsxs("div", { children: [_jsx("label", __assign({ className: "text-sm text-gray-300 text-center" }, { children: statName })), _jsx(ArrowPathIcon, { className: "h-6 w-4 animate-spin" })] }));
    }
    return (_jsxs("div", __assign({ className: "flex flex-col items-center" }, { children: [_jsx("label", __assign({ className: "text-sm text-gray-300 text-center whitespace-pre-wrap" }, { children: statName })), _jsxs("div", __assign({ className: classNames("flex font-medium text-md text-center", highlightColor) }, { children: [prefixIcon ? prefixIcon : "", prefixUnit, statVal, statUnit, postfixIcon ? postfixIcon : ""] }))] })));
};
