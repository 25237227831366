import React, { Fragment, useCallback } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
export function convertStringArrayToMultiSelectDropdownItems(vals, includeAll) {
    if (!vals) {
        return [];
    }
    var items = (vals !== null && vals !== void 0 ? vals : []).map(function (m) { return ({ name: m, value: m }); });
    if (includeAll) {
        items.splice(0, 0, { name: "All", value: "" });
    }
    return items;
}
export var MultiSelectDropdown = function (_a) {
    var items = _a.items, currentSelections = _a.currentSelections, onChange = _a.onChange, comparator = _a.comparator;
    var comp = comparator !== null && comparator !== void 0 ? comparator : (function (i1, i2) { return i1 == i2; });
    var isDefaultValue = function (val) {
        return val === undefined || val === null || val === "";
    };
    var defaultItem = items.find(function (i) { return isDefaultValue(i.value); });
    var selectedItems = currentSelections.map(function (selection) {
        return items.find(function (i) { return comp(i.value, selection); });
    });
    var convertedItems = items.map(function (item) { return ({
        id: item === null || item === void 0 ? void 0 : item.value,
        name: item === null || item === void 0 ? void 0 : item.name,
    }); });
    var itemLookup = new Map(convertedItems.map(function (item) { return [item.id, item]; }));
    var convertedSelectedItems = selectedItems.map(function (item) {
        return itemLookup.get(item === null || item === void 0 ? void 0 : item.value);
    });
    var dropdownLabels = (selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.length) > 0
        ? selectedItems.map(function (selectedItem) { return ({
            id: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.value,
            name: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.name,
        }); })
        : [
            {
                id: defaultItem === null || defaultItem === void 0 ? void 0 : defaultItem.value,
                name: defaultItem === null || defaultItem === void 0 ? void 0 : defaultItem.name,
            },
        ];
    var onSelectionChange = useCallback(function (selection) {
        var _a;
        var onlyHadDefaultValue = ((_a = currentSelections === null || currentSelections === void 0 ? void 0 : currentSelections.filter(function (val) { return !isDefaultValue(val); })) === null || _a === void 0 ? void 0 : _a.length) === 0;
        // If previously this was only an empty list then use the selection and remove
        // the default value if it exists. We can also take the selection if a user hasn't
        // selected a default value. Otherwise if a user selected an empty value then we
        // should clear it.
        if (onlyHadDefaultValue ||
            (selection === null || selection === void 0 ? void 0 : selection.every(function (sel) { return !isDefaultValue(sel.id); }))) {
            var selectedIds = Array.from(new Set(selection
                .filter(function (sel) { return !isDefaultValue(sel.id); })
                .map(function (sel) { return sel.id; })));
            onChange(selectedIds);
        }
        else {
            onChange([]);
        }
    }, [currentSelections, isDefaultValue, onChange]);
    return (React.createElement(Listbox, { as: "div", value: convertedSelectedItems, onChange: onSelectionChange, multiple: true, className: "inline-block text-left bg-black-1000 rounded-[8px]" }, function (_a) {
        var _b;
        var open = _a.open;
        return (React.createElement(React.Fragment, null,
            React.createElement(Listbox.Button, { className: "inline-flex w-50 truncate px-[16px] py-[16px] font-N8p5 text-black-600 hover:bg-opacity-30 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" }, (_b = dropdownLabels === null || dropdownLabels === void 0 ? void 0 : dropdownLabels.map(function (label) { return label === null || label === void 0 ? void 0 : label.name; })) === null || _b === void 0 ? void 0 :
                _b.join(", "),
                React.createElement(ChevronDownIcon, { className: "ml-[128px] -mr-1 h-3 w-3 text-black-600 hover:text-white", "aria-hidden": "true" })),
            React.createElement(Transition, { show: open, enter: "transition duration-100 ease-out", enterFrom: "transform scale-95 opacity-0", enterTo: "transform scale-100 opacity-100", leave: "transition duration-75 ease-out", leaveFrom: "transform scale-100 opacity-100", leaveTo: "transform scale-95 opacity-0", className: "relative z-50" },
                React.createElement(Listbox.Options, { static: true, className: "absolute w-56 rounded-md bg-black-1000 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-40 max-h-[512px] overflow-y-auto" }, convertedItems.map(function (item) {
                    return (React.createElement(Listbox.Option, { key: item.id, as: Fragment, value: item }, function (_a) {
                        var active = _a.active, selected = _a.selected;
                        return (React.createElement("label", { className: "font-N8p5 hover:text-white group flex w-full items-center rounded-md px-2 py-2 ".concat(active || selected ? "text-white" : "text-black-600", " %{active && 'bg-blue-500'} z-50") },
                            selected && (React.createElement(CheckIcon, { className: "ui-selected:block h-5 w-5 text-white" })),
                            item.name));
                    }));
                })))));
    }));
};
