import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { process } from "../../Process";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { NOCControls } from "./NocControls";
export var NOCOrganizationControls = function () {
    var _a = useAuth0(), isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading, getAccessTokenSilently = _a.getAccessTokenSilently;
    var organizations = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/organizations/list"),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var controllableAssets = [];
    if (organizations) {
        controllableAssets = controllableAssets.concat(organizations.map(function (o) {
            return {
                title: "Organization ".concat(o.name, " (ID: ").concat(o.id, ")"),
                metadataUrl: "/organizations/metadata/".concat(o.id),
                setMetadataUrl: "/organizations/metadata/set/".concat(o.id),
            };
        }));
    }
    var siteParentConfig;
    if (organizations) {
        siteParentConfig = {
            possibleParents: organizations
                .sort(function (a, b) { return a.id - b.id; })
                .map(function (o) {
                return {
                    title: "".concat(o.name),
                    id: o.id,
                };
            }),
            title: "Parent Organization",
            key: "organizationId",
        };
    }
    var creatableAssets = [
        {
            title: "Provision New Organization",
            createUrl: "/organizations/create",
            subObjects: [
                {
                    metadataUrl: "/organizations/metadata",
                },
            ],
        },
        {
            title: "Provision New Site",
            createUrl: "/sites/create",
            parent: siteParentConfig,
            subObjects: [
                {
                    metadataUrl: "/sites/metadata",
                    subObjectKey: "site",
                },
                {
                    metadataUrl: "/sites/address/metadata",
                    subObjectKey: "address",
                },
            ],
        },
    ];
    return (React.createElement(NOCControls, { controllableAssets: controllableAssets, creatableAssets: creatableAssets }));
};
