import React from "react";
import { TextInput } from "./Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
export var Search = function (_a) {
    var placeHolder = _a.placeHolder, onSearchChanged = _a.onSearchChanged;
    return (React.createElement("span", { className: "relative" },
        React.createElement("div", { className: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" },
            React.createElement(MagnifyingGlassIcon, { className: "h-[16px] w-[16px]" })),
        React.createElement(TextInput, { type: "search", placeholder: placeHolder, className: "pl-[32px]", onChange: function (event) { return onSearchChanged(event.target.value); } })));
};
