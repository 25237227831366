import React from "react";
import { RadioGroup } from "@headlessui/react";
export var RadioStyle;
(function (RadioStyle) {
    RadioStyle[RadioStyle["Colorful"] = 0] = "Colorful";
    RadioStyle[RadioStyle["Gray"] = 1] = "Gray";
})(RadioStyle || (RadioStyle = {}));
export var Radio = function (_a) {
    var items = _a.items, value = _a.value, setValue = _a.setValue, style = _a.style, className = _a.className;
    return (React.createElement(RadioGroup, { className: "flex flex-row items-center ".concat(style == RadioStyle.Gray ? "bg-black-1200" : "bg-black-1000", " rounded-[10px] w-fit h-fit ").concat(className !== null && className !== void 0 ? className : ""), value: value, onChange: setValue }, items.map(function (item) {
        return (React.createElement(RadioGroup.Option, { value: item.value, key: item.name }, function (_a) {
            var checked = _a.checked;
            return (React.createElement("button", { className: "px-[24px] py-[16px] font-N10 rounded-[10px] ".concat(checked
                    ? "".concat(style == RadioStyle.Gray
                        ? "bg-black-800"
                        : "bg-gradient-to-r from-main-gradient-from to-main-gradient-to", " text-white hover:text-black-200")
                    : "".concat(style == RadioStyle.Gray
                        ? "bg-black-1200"
                        : "bg-black-1000", " text-black-400 hover:bg-black-800 hover:text-white")) }, item.name));
        }));
    })));
};
export var OnOffRadio = function (_a) {
    var on = _a.on, setOn = _a.setOn, className = _a.className;
    var items = [
        {
            name: "OFF",
            value: false,
        },
        {
            name: "ON",
            value: true,
        },
    ];
    return (React.createElement(Radio, { items: items, setValue: setOn, value: on, style: RadioStyle.Gray, className: className }));
};
