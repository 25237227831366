var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState, useCallback, useEffect, useMemo, } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { ExpandableHelpTooltip } from "../HelpTooltip";
import { TextInput } from "../Input";
import { process } from "../../Process";
import { StaticTable } from "../Table";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { useReactTable, getCoreRowModel, flexRender, } from "@tanstack/react-table";
import { ChevronDownIcon, ChevronUpIcon, CheckCircleIcon, } from "@heroicons/react/24/outline";
import { Dialog, Disclosure, Switch } from "@headlessui/react";
import { SuccessIndicator } from "../SuccessIndicator";
import { events_svc } from "ee-proto-interfaces";
import { v4 as uuidv4 } from "uuid";
import { StyledButton, StyledButtonStyle } from "../StyledButton";
import { Dropdown } from "../Dropdown";
var TableButton = function (_a) {
    var text = _a.text, onClick = _a.onClick, disabled = _a.disabled;
    return (React.createElement("button", { className: "h-12 w-12 rounded border p-1 hover:bg-gray-600 hover:shadow-lg hover:shadow-blue-500/30  disabled:cursor-not-allowed disabled:opacity-50", onClick: onClick, disabled: disabled }, text));
};
var CommandHistoryTable = function (_a) {
    var pnid = _a.pnid;
    var _b = React.useState({
        pageIndex: 0,
        pageSize: 10,
    }), _c = _b[0], pageIndex = _c.pageIndex, pageSize = _c.pageSize, setPagination = _b[1];
    var _d = useAuth0(), isAuthenticated = _d.isAuthenticated, isLoading = _d.isLoading, getAccessTokenSilently = _d.getAccessTokenSilently;
    var data = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/powernodes/commands/").concat(pnid, "?take=").concat(pageSize, "&skip=").concat(pageSize * pageIndex),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var columns = useMemo(function () { return [
        {
            accessorKey: "commandId",
            header: "Command ID",
        },
        {
            accessorFn: function (row) { return new Date(row.createdDate).toLocaleString(); },
            header: "Created Date",
        },
        {
            accessorFn: function (row) { return new Date(row.updatedDate).toLocaleString(); },
            header: "Updated Date",
        },
        {
            accessorFn: function (row) {
                return row.commandContent.length == 1 &&
                    row.commandContent[0].legacyCommand
                    ? row.commandContent[0].legacyCommand.command
                    : JSON.stringify(row.commandContent);
            },
            header: "Command",
        },
        {
            accessorKey: "latestStatus",
            header: "Status",
        },
        {
            accessorKey: "username",
            header: "Username",
        },
    ]; }, []);
    var pagination = useMemo(function () { return ({
        pageIndex: pageIndex,
        pageSize: pageSize,
    }); }, [pageIndex, pageSize]);
    var table = useReactTable({
        data: data ? data.commands : [],
        columns: columns,
        pageCount: data ? data.pages : -1,
        manualPagination: true,
        getCoreRowModel: getCoreRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination: pagination,
        },
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("table", { className: "table-auto w-full" },
            React.createElement("thead", { className: "border-b-2 border-slate-400" }, table.getHeaderGroups().map(function (headerGroup) { return (React.createElement("tr", { key: headerGroup.id }, headerGroup.headers.map(function (header) { return (React.createElement("th", { key: header.id, className: "text-left" }, header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext()))); }))); })),
            React.createElement("tbody", null, table.getRowModel().rows.map(function (row) { return (React.createElement("tr", { key: row.id, className: "even:bg-gray-800" }, row.getVisibleCells().map(function (cell) { return (React.createElement("td", { key: cell.id }, flexRender(cell.column.columnDef.cell, cell.getContext()))); }))); })),
            React.createElement("tfoot", null, table.getFooterGroups().map(function (footerGroup) { return (React.createElement("tr", { key: footerGroup.id }, footerGroup.headers.map(function (header) { return (React.createElement("th", { key: header.id }, header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.footer, header.getContext()))); }))); }))),
        React.createElement("div", { className: "mt-8 grid grid-cols-12 place-items-center gap-2" },
            React.createElement(TableButton, { text: "<<", onClick: function () { return table.setPageIndex(0); }, disabled: !table.getCanPreviousPage() }),
            React.createElement(TableButton, { text: "<", onClick: function () { return table.previousPage(); }, disabled: !table.getCanPreviousPage() }),
            React.createElement("div", { className: "col-span-3 grid w-full grid-rows-2 place-items-center" },
                React.createElement("span", { className: "flex items-center gap-1" },
                    React.createElement("div", null, "Page"),
                    React.createElement("strong", null,
                        table.getState().pagination.pageIndex + 1,
                        " of",
                        " ",
                        table.getPageCount()))),
            React.createElement(TableButton, { text: ">", onClick: function () { return table.nextPage(); }, disabled: !table.getCanNextPage() }),
            React.createElement(TableButton, { text: ">>", onClick: function () { return table.setPageIndex(table.getPageCount() - 1); }, disabled: !table.getCanNextPage() }))));
};
var MaintenanceWindowDisclosure = function (_a) {
    var pnid = _a.pnid;
    var _b = useState(false), showEditDialog = _b[0], setShowEditDialog = _b[1];
    var _c = useState(), maintenanceWindowToEdit = _c[0], setMaintenanceWindowToEdit = _c[1];
    var _d = useAuth0(), getAccessTokenSilently = _d.getAccessTokenSilently, isLoading = _d.isLoading, isAuthenticated = _d.isAuthenticated;
    var maintenanceWindows = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/powernodes/maintenance_window/").concat(pnid),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    maintenanceWindows = maintenanceWindows === null || maintenanceWindows === void 0 ? void 0 : maintenanceWindows.filter(function (mw) { return !mw.deleted && new Date(mw.end).getTime() >= new Date().getTime(); });
    var closeEditDialog = function () { return setShowEditDialog(false); };
    var openEditDialog = function (maintenanceWindowToEdit) {
        setMaintenanceWindowToEdit(maintenanceWindowToEdit);
        setShowEditDialog(true);
    };
    var createMaintenanceWindow = useCallback(function (maintenanceWindow) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, createMaintenanceWindow;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    createMaintenanceWindow = __assign({ powernodeId: pnid }, maintenanceWindow);
                    delete createMaintenanceWindow.id;
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/maintenance_window/create"), {
                            method: "POST",
                            body: JSON.stringify(createMaintenanceWindow),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [pnid]);
    var updateMaintenanceWindow = useCallback(function (maintenanceWindow) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, updateMaintenanceWindow;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    updateMaintenanceWindow = __assign({ powernodeId: pnid }, maintenanceWindow);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/maintenance_window/").concat(updateMaintenanceWindow.id), {
                            method: "PUT",
                            body: JSON.stringify(updateMaintenanceWindow),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [pnid]);
    var deleteMaintenanceWindow = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    if (!(confirm("Confirm to delete maintenance window ".concat(id)) == true)) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/maintenance_window/delete/").concat(id), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var maintenanceWindowColumns = [
        {
            header: "Allow Operation During Window",
            accessorFn: function (mw) { return mw.allowOperationDuringWindow; },
            cell: function (info) {
                return info.getValue() ? (React.createElement(CheckCircleIcon, { className: "text-green h-[24px] w-[24px]" })) : undefined;
            },
        },
        {
            id: "startTime",
            header: "Start",
            accessorFn: function (row) {
                return new Date(row.start);
            },
            cell: function (info) {
                return info.getValue().toLocaleString();
            },
            sortingFn: "datetime",
        },
        {
            header: "End",
            accessorFn: function (row) {
                return new Date(row.end);
            },
            cell: function (info) {
                return info.getValue().toLocaleString();
            },
        },
    ];
    var initialSorting = [
        {
            id: "startTime",
            desc: false,
        },
    ];
    return (React.createElement(Disclosure, null, function (_a) {
        var open = _a.open;
        return (React.createElement(React.Fragment, null,
            React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                React.createElement("label", { className: "font-N6" }, "Maintenance Windows"),
                open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
            React.createElement(Disclosure.Panel, { className: "w-full" },
                React.createElement(StaticTable, { data: maintenanceWindows, columnDefinitions: maintenanceWindowColumns, initialSorting: initialSorting, emptyMessage: "No maintenance windows.", onClickRow: function (mw) { return openEditDialog(mw); } }),
                React.createElement(Dialog, { open: showEditDialog, onClose: closeEditDialog, className: "relative z-50" },
                    React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
                    React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                        React.createElement(Dialog.Panel, { className: "mx-auto" },
                            React.createElement(MaintenanceWindowEditDialog, { maintenanceWindow: maintenanceWindowToEdit, onClose: closeEditDialog, onSubmit: maintenanceWindowToEdit
                                    ? updateMaintenanceWindow
                                    : createMaintenanceWindow, onDelete: maintenanceWindowToEdit
                                    ? function () {
                                        return deleteMaintenanceWindow(maintenanceWindowToEdit.id);
                                    }
                                    : undefined })))),
                React.createElement(StyledButton, { text: "CREATE NEW", className: "mt-8", style: StyledButtonStyle.Green, onClick: function () { return openEditDialog(undefined); } }))));
    }));
};
export var MaintenanceWindowEditDialog = function (_a) {
    var _b;
    var maintenanceWindow = _a.maintenanceWindow, onClose = _a.onClose, onSubmit = _a.onSubmit, onDelete = _a.onDelete;
    var buttons = [
        {
            title: "10 minutes",
            value: 1000 * 60 * 10,
        },
        {
            title: "30 minutes",
            value: 1000 * 60 * 30,
        },
        {
            title: "1 hour",
            value: 1000 * 60 * 60,
        },
        {
            title: "3 hours",
            value: 1000 * 60 * 60 * 3,
        },
        {
            title: "12 hours",
            value: 1000 * 60 * 60 * 12,
        },
        {
            title: "1 day",
            value: 1000 * 60 * 60 * 24,
        },
    ];
    var startWindowRef = useRef(null);
    var endWindowRef = useRef(null);
    var dateToInputString = function (date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0, 16);
    };
    var onButtonClick = function (value) {
        if (!endWindowRef.current) {
            return;
        }
        var date = startWindowRef.current.value
            ? new Date(startWindowRef.current.value)
            : new Date();
        date.setMilliseconds(date.getMilliseconds() + value);
        endWindowRef.current.value = dateToInputString(date);
    };
    var _c = useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState((_b = maintenanceWindow === null || maintenanceWindow === void 0 ? void 0 : maintenanceWindow.allowOperationDuringWindow) !== null && _b !== void 0 ? _b : false), allowOperationDuringWindow = _d[0], setAllowOperationDuringWindow = _d[1];
    var displayErrorMessage = function (message) {
        setErrorMessage(message);
        setTimeout(function () {
            setErrorMessage(undefined);
        }, 10000);
    };
    var onSave = useCallback(function () {
        if (!endWindowRef.current) {
            return;
        }
        if (!endWindowRef.current.value) {
            displayErrorMessage("Expiration must be set.");
            return;
        }
        var maintenanceWindowToCreate = {
            id: maintenanceWindow ? maintenanceWindow.id : 0,
            allowOperationDuringWindow: allowOperationDuringWindow,
            start: new Date(startWindowRef.current.value),
            end: new Date(endWindowRef.current.value),
        };
        setErrorMessage(undefined);
        onSubmit(maintenanceWindowToCreate);
        onClose();
    }, [allowOperationDuringWindow]);
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px]" },
        React.createElement("label", { className: "font-N5 text-white w-full text-center" }, "Edit Maintenance Window"),
        React.createElement("div", { className: "grid grid-cols-2 gap-x-4 gap-y-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" },
                "Allow operation during window:",
                " ",
                React.createElement(ExpandableHelpTooltip, { tooltip: React.createElement(React.Fragment, null,
                        "When selected the battery and software will remain active during this maintenance window. ",
                        React.createElement("br", null),
                        " ",
                        React.createElement("br", null),
                        "This is used to designate a maintenance windows that will not change battery or electric era software.") })),
            React.createElement(Switch, { checked: allowOperationDuringWindow, onChange: setAllowOperationDuringWindow, className: "".concat(allowOperationDuringWindow ? "bg-blue-600" : "bg-gray-500", " relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2") },
                React.createElement("span", { className: "".concat(allowOperationDuringWindow ? "translate-x-6" : "translate-x-1", " inline-block h-4 w-4 transform rounded-full bg-white transition-transform") })),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] col-span-2 mx-auto w-full" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Start:"),
            React.createElement(TextInput, { type: "datetime-local", ref: startWindowRef, defaultValue: dateToInputString((maintenanceWindow === null || maintenanceWindow === void 0 ? void 0 : maintenanceWindow.start)
                    ? new Date(maintenanceWindow.start)
                    : new Date()) }),
            React.createElement("label", { className: "font-N7 text-white" }, "End:"),
            React.createElement(TextInput, { type: "datetime-local", ref: endWindowRef, defaultValue: (maintenanceWindow === null || maintenanceWindow === void 0 ? void 0 : maintenanceWindow.end)
                    ? dateToInputString(new Date(maintenanceWindow.end))
                    : undefined }),
            React.createElement("div", { className: "flex flex-row flex-wrap col-span-2 gap-4 items-center" },
                React.createElement("label", { className: "font-N8 text-white" }, "End Presets:"),
                buttons.map(function (b, i) { return (React.createElement(StyledButton, { key: i, text: b.title, onClick: function () { return onButtonClick(b.value); } })); })),
            React.createElement("div", { className: "flex flex-rowcol-span-2 gap-x-4 mt-16" },
                React.createElement(StyledButton, { text: "SAVE", style: StyledButtonStyle.Green, onClick: onSave }),
                onDelete != null && (React.createElement(StyledButton, { text: "DELETE", style: StyledButtonStyle.Green, onClick: function () {
                        onDelete();
                        onClose();
                    } })),
                React.createElement(StyledButton, { text: "CLOSE", style: StyledButtonStyle.Gray, onClick: onClose }))),
        React.createElement(SuccessIndicator, { message: errorMessage, success: false })));
};
var MaintenanceWindowDisplay = function (_a) {
    var pnid = _a.pnid;
    return (React.createElement(React.Fragment, null,
        React.createElement(MaintenanceWindowDisclosure, { pnid: pnid })));
};
var LegacyCommandDisclosure = function (_a) {
    var pnid = _a.pnid;
    var cmdInput = useRef(null);
    var _b = useState(""), cmdResponse = _b[0], setCmdResponse = _b[1];
    var _c = useState(true), cmdSuccess = _c[0], setCmdSuccess = _c[1];
    var _d = useAuth0(), getAccessTokenSilently = _d.getAccessTokenSilently, user = _d.user;
    var onSendClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var commandValue, accessToken, command, response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    commandValue = (_a = cmdInput.current) === null || _a === void 0 ? void 0 : _a.value;
                    if (commandValue.length == 0) {
                        setCmdResponse("Invalid input.");
                        setCmdSuccess(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _c.sent();
                    command = {
                        pnid: pnid,
                        command: commandValue,
                        username: (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : user === null || user === void 0 ? void 0 : user.email,
                    };
                    if (!(confirm("Confirm command PNID: ".concat(pnid, ", CMD: ").concat(commandValue)) == true)) return [3 /*break*/, 3];
                    setCmdResponse("");
                    setCmdSuccess(true);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(command),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _c.sent();
                    cmdInput.current.value = "";
                    setCmdResponse("Ok: ".concat(response.ok, ", status: ").concat(response.status, ", statusText: ").concat(response.statusText));
                    setCmdSuccess(response.ok);
                    _c.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
            React.createElement("label", { className: "font-N7 text-white" }, "Command"),
            React.createElement("input", { className: "w-[1024px]", ref: cmdInput, onPaste: function (e) {
                    if (e.clipboardData) {
                        // This prevents leading and trailing whitespace from being pasted. This often happens when pasting from the command history table.
                        e.preventDefault();
                        e.currentTarget.value = e.clipboardData
                            .getData("text/plain")
                            .trim();
                    }
                } })),
        React.createElement("button", { className: "bg-red text-white w-fit px-[32px] py-[16px] rounded-[10px]", onClick: onSendClick }, "Send Command"),
        React.createElement(SuccessIndicator, { message: cmdResponse == "" ? undefined : cmdResponse, success: cmdSuccess })));
};
var JsonCommandDisclosure = function (_a) {
    var pnid = _a.pnid, details = _a.details;
    var SampleCommand;
    (function (SampleCommand) {
        SampleCommand["SEND_JSON_UPDATE_FIRMWARE"] = "Send OCPP JSON (update firmware)";
        SampleCommand["REBOOT_CHARGER"] = "Send OCPP JSON (reboot charger)";
    })(SampleCommand || (SampleCommand = {}));
    var getSampleMessage = function (command, ocppId) {
        switch (command) {
            case SampleCommand.SEND_JSON_UPDATE_FIRMWARE:
                return {
                    sendOcppMessage: {
                        message: "[2,\"".concat(uuidv4(), "\",\"UpdateFirmware\",{\"location\":\"http://192.168.225.31:80/fc200k_V2_AIO_EEra_0.4.zip\",\"retrieveDate\":\"").concat(new Date().toISOString(), "\"}]"),
                        ocppId: ocppId !== null && ocppId !== void 0 ? ocppId : "YOUR_ID_HERE",
                    },
                };
            case SampleCommand.REBOOT_CHARGER:
                return {
                    sendOcppMessage: {
                        message: "[2,\"".concat(uuidv4(), "\",\"Reset\",{\"type\":\"Hard\"}]"),
                        ocppId: ocppId !== null && ocppId !== void 0 ? ocppId : "YOUR_ID_HERE",
                    },
                };
        }
    };
    var cmdInput = useRef(null);
    var _b = useState(""), cmdResponse = _b[0], setCmdResponse = _b[1];
    var _c = useState(true), cmdSuccess = _c[0], setCmdSuccess = _c[1];
    var _d = useState(), selectedCharger = _d[0], setSelectedCharger = _d[1];
    var defaultItem = {
        name: "",
        value: undefined,
        selectedLabel: "Select Charger",
    };
    var dropdownItems = [defaultItem].concat(details.chargers
        .sort(function (c1, c2) { return c1.id - c2.id; })
        .map(function (c) {
        return {
            name: c.ocppId,
            value: c.ocppId,
            selectedLabel: c.ocppId,
        };
    }));
    var _e = useAuth0(), getAccessTokenSilently = _e.getAccessTokenSilently, user = _e.user;
    var onClickSampleCommand = function (command) {
        if (!cmdInput.current) {
            return;
        }
        var sampleMessage = getSampleMessage(command, selectedCharger);
        cmdInput.current.innerText = JSON.stringify(sampleMessage, null, 2);
    };
    var onClearClicked = function () {
        if (!cmdInput.current) {
            return;
        }
        cmdInput.current.innerText = "";
    };
    var onSendClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var commandValue, parsedCommand, accessToken, command, response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    commandValue = (_a = cmdInput.current) === null || _a === void 0 ? void 0 : _a.innerText;
                    if (!commandValue) {
                        setCmdResponse("Invalid input.");
                        setCmdSuccess(false);
                        return [2 /*return*/];
                    }
                    try {
                        parsedCommand = JSON.parse(commandValue);
                    }
                    catch (e) {
                        setCmdResponse("Invalid input. Failed to parse JSON: " + e);
                        setCmdSuccess(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _c.sent();
                    command = {
                        pnid: pnid,
                        command: parsedCommand,
                        username: (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : user === null || user === void 0 ? void 0 : user.email,
                    };
                    if (!(confirm("Confirm command PNID: ".concat(pnid, ", CMD: ").concat(commandValue)) == true)) return [3 /*break*/, 3];
                    setCmdResponse("");
                    setCmdSuccess(true);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send"), {
                            method: "POST",
                            body: JSON.stringify(command),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _c.sent();
                    cmdInput.current.innerText = "";
                    setCmdResponse("Ok: ".concat(response.ok, ", status: ").concat(response.status, ", statusText: ").concat(response.statusText));
                    setCmdSuccess(response.ok);
                    _c.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onPaste = function (e) {
        if (cmdInput.current) {
            e.preventDefault();
            var text = e.clipboardData.getData("text/plain");
            window.document.execCommand("insertHTML", false, text);
        }
    };
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("div", { className: "flex flex-row flex-wrap gap-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" }, "Placeholders"),
            React.createElement(Dropdown, { items: dropdownItems, currentSelection: selectedCharger, onClick: setSelectedCharger })),
        React.createElement("hr", { className: "border-black-800 max-w-[25%] my-2" }),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-4" },
            React.createElement(StyledButton, { text: "CLEAR", onClick: onClearClicked, style: StyledButtonStyle.Gray }),
            Object.values(SampleCommand).map(function (c) {
                return (React.createElement(StyledButton, { text: c, onClick: function () { return onClickSampleCommand(c); }, key: c }));
            })),
        React.createElement("hr", { className: "border-black-800 max-w-[25%] my-2" }),
        React.createElement("div", { contentEditable: true, ref: cmdInput, onPaste: onPaste, className: "p-[16px] whitespace-pre w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" }),
        React.createElement("button", { className: "bg-red text-white w-fit px-[32px] py-[16px] rounded-[10px]", onClick: onSendClick }, "Send Command"),
        React.createElement(SuccessIndicator, { message: cmdResponse == "" ? undefined : cmdResponse, success: cmdSuccess })));
};
var GUICommandDisclosure = function (_a) {
    var pnid = _a.pnid;
    var _b = useState(null), maintenanceMode = _b[0], setMaintenanceMode = _b[1];
    var _c = useAuth0(), getAccessTokenSilently = _c.getAccessTokenSilently, user = _c.user;
    var fetchMaintenanceModeStatus = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/status/").concat(pnid), {
                            headers: {
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    switch (events_svc.powernodeStatusFromJSON(data)) {
                        case events_svc.PowernodeStatus.MAINTENANCE:
                            setMaintenanceMode("On");
                            break;
                        case events_svc.PowernodeStatus.ONLINE:
                            setMaintenanceMode("Off");
                            break;
                        default:
                            setMaintenanceMode("Unknown");
                            break;
                    }
                    return [3 /*break*/, 5];
                case 4:
                    alert("Failed to fetch status.");
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [getAccessTokenSilently, setMaintenanceMode]);
    var sendCommand = function (command) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, legacyCommand;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _b.sent();
                    legacyCommand = {
                        pnid: pnid,
                        command: command,
                        username: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.email,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(legacyCommand),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var interval = setInterval(fetchMaintenanceModeStatus, 1000);
        return function () { return clearInterval(interval); };
    }, [fetchMaintenanceModeStatus]);
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("div", { className: "mt-4" },
            React.createElement("p", null,
                "Maintenance Mode Status: ", maintenanceMode !== null && maintenanceMode !== void 0 ? maintenanceMode : "Loading...")),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(StyledButton, { text: "Maintenance Mode ON", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to turn maintenance mode on at powernode ".concat(pnid)) == true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs maintenance_mode 1")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "Maintenance Mode OFF", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to turn maintenance mode off at powernode ".concat(pnid)) == true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs maintenance_mode 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Green }))));
};
var ATPCommandDisclosure = function (_a) {
    var pnid = _a.pnid;
    var cmdRechargeLimitInput = useRef(null);
    var cmdPowerCommandInput = useRef(null);
    var _b = useState(""), sendRechargeSocLimitResponse = _b[0], setSendRechargeSocLimitResponse = _b[1];
    var _c = useState(false), sendRechargeSocLimitSuccess = _c[0], setSendRechargeSocLimitSuccess = _c[1];
    var _d = useState(""), sendPowerCommandResponse = _d[0], setSendPowerCommandResponse = _d[1];
    var _e = useState(false), sendPowerCommandSuccess = _e[0], setSendPowerCommandSuccess = _e[1];
    var _f = useAuth0(), getAccessTokenSilently = _f.getAccessTokenSilently, user = _f.user;
    var onSetRechargeSocLimitClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var rechargeSocLimitValue, accessToken, command, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!cmdRechargeLimitInput.current) {
                        console.error("onSetRechargeSocLimitClick");
                        return [2 /*return*/];
                    }
                    rechargeSocLimitValue = cmdRechargeLimitInput.current.value;
                    if (rechargeSocLimitValue.length === 0) {
                        setSendRechargeSocLimitResponse("Invalid input.");
                        setSendRechargeSocLimitSuccess(false);
                        return [2 /*return*/];
                    }
                    if (isNaN(rechargeSocLimitValue)) {
                        setSendRechargeSocLimitResponse("Invalid input. Must be a number.");
                        setSendRechargeSocLimitSuccess(false);
                        return [2 /*return*/];
                    }
                    if (Number(rechargeSocLimitValue) > 100 ||
                        Number(rechargeSocLimitValue) < 0) {
                        setSendRechargeSocLimitResponse("Invalid input. Must be between 0-100%.");
                        setSendRechargeSocLimitSuccess(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _b.sent();
                    command = {
                        pnid: pnid,
                        command: "sc1 pn_ctrl_manual_inputs atp_recharge_soc_target ".concat(rechargeSocLimitValue),
                        username: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.email,
                    };
                    if (!(confirm("Confirm Set Recharge SOC Limit for PNID: ".concat(pnid, ", Limit: ").concat(rechargeSocLimitValue, "%")) === true)) return [3 /*break*/, 3];
                    setSendRechargeSocLimitResponse("");
                    setSendRechargeSocLimitSuccess(true);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(command),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _b.sent();
                    cmdRechargeLimitInput.current.value = "";
                    setSendRechargeSocLimitResponse("Ok: ".concat(response.ok, ", status: ").concat(response.status, ", statusText: ").concat(response.statusText));
                    setSendRechargeSocLimitSuccess(response.ok);
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSetPowerCommandClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var powerCommandValue, accessToken, command, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!cmdPowerCommandInput.current) {
                        console.error("onSetPowerCommandClick");
                        return [2 /*return*/];
                    }
                    powerCommandValue = cmdPowerCommandInput.current.value;
                    if (powerCommandValue.length === 0) {
                        setSendPowerCommandResponse("Invalid input.");
                        setSendPowerCommandSuccess(false);
                        return [2 /*return*/];
                    }
                    // Check for valid number
                    if (isNaN(Number(powerCommandValue))) {
                        setSendPowerCommandResponse("Invalid input. Must be a number.");
                        setSendPowerCommandSuccess(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _b.sent();
                    command = {
                        pnid: pnid,
                        command: "sc1 pn_ctrl_manual_inputs p_ac_cmd_kw ".concat(powerCommandValue),
                        username: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.email,
                    };
                    if (!(confirm("Confirm Set Power Command for PNID: ".concat(pnid, ", Command: ").concat(powerCommandValue, "kW")) === true)) return [3 /*break*/, 3];
                    setSendPowerCommandResponse("");
                    setSendPowerCommandSuccess(true);
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(command),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _b.sent();
                    cmdPowerCommandInput.current.value = "";
                    setSendPowerCommandResponse("Ok: ".concat(response.ok, ", status: ").concat(response.status, ", statusText: ").concat(response.statusText));
                    setSendPowerCommandSuccess(response.ok);
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var sendCommand = function (command) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, legacyCommand;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _b.sent();
                    legacyCommand = {
                        pnid: pnid,
                        command: command,
                        username: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.email,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/powernodes/commands/send_legacy"), {
                            method: "POST",
                            body: JSON.stringify(legacyCommand),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(StyledButton, { text: "ATP MODE OFF", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to DISABLE ATP MODE at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs en_batt_ctrl_override 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "ATP MODE ON", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to ENABLE ATP MODE at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 5];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 0")];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs p_ac_cmd_kw 0")];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs thermal_override 0")];
                            case 3:
                                _a.sent();
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs en_batt_ctrl_override 1")];
                            case 4:
                                _a.sent();
                                _a.label = 5;
                            case 5: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "SAFE PACK", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to SAFE PACK at powernode ".concat(pnid)) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "IDLE", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to take pack into IDLE at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 2")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "LIVE", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to take pack into LIVE at powernode ".concat(pnid, ". THIS WILL START RESPONDING TO POWER COMMANDS! ENSURE POWER COMMAND IS SET TO SAFE VALUE OR 0KW!!")) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 3")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "CLEAR FAULTS", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to take pack into CLEAR FAULTS at powernode ".concat(pnid)) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs fsm_cmd 4")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "THERMAL OVERRIDE ON", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to override control enclosure thermal management at powernode ".concat(pnid)) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs thermal_override 1")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "THERMAL OVERRIDE OFF", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to disable override control enclosure thermal management at powernode ".concat(pnid)) === true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs thermal_override 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "TEC ON", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to command TEC to ON at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs tec_power_pin 1")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray }),
            React.createElement(StyledButton, { text: "TEC OFF", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(confirm("Confirm to command TEC to OFF at powernode ".concat(pnid)) ===
                                    true)) return [3 /*break*/, 2];
                                return [4 /*yield*/, sendCommand("sc1 pn_ctrl_manual_inputs tec_power_pin 0")];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, style: StyledButtonStyle.Gray })),
        React.createElement("div", null,
            React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                React.createElement("label", { className: "font-N7 text-white" }, "Recharge SOC Limit (%)"),
                React.createElement("input", { className: "w-[8em] outline outline-2 hover:outline hover:outline-2 focus:outline focus:outline-2", ref: cmdRechargeLimitInput, onPaste: function (e) {
                        if (e.clipboardData) {
                            // This prevents leading and trailing whitespace from being pasted. This often happens when pasting from the command history table.
                            e.preventDefault();
                            e.currentTarget.value = e.clipboardData
                                .getData("text/plain")
                                .trim();
                        }
                    } })),
            React.createElement("button", { className: "bg-red text-white w-fit px-[8px] py-[8px] rounded-[10px]", onClick: onSetRechargeSocLimitClick }, "Set Recharge SOC Limit"),
            React.createElement(SuccessIndicator, { message: sendRechargeSocLimitResponse == ""
                    ? undefined
                    : sendRechargeSocLimitResponse, success: sendRechargeSocLimitSuccess })),
        React.createElement("div", null,
            React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                React.createElement("label", { className: "font-N7 text-white" }, "Power Command (kW)"),
                React.createElement("input", { className: "w-[8em] outline outline-2 hover:outline hover:outline-2 focus:outline focus:outline-2", ref: cmdPowerCommandInput, onPaste: function (e) {
                        if (e.clipboardData) {
                            // This prevents leading and trailing whitespace from being pasted. This often happens when pasting from the command history table.
                            e.preventDefault();
                            e.currentTarget.value = e.clipboardData
                                .getData("text/plain")
                                .trim();
                        }
                    } })),
            React.createElement("button", { className: "bg-red text-white w-fit px-[8px] py-[8px] rounded-[10px]", onClick: onSetPowerCommandClick }, "Set Power Command")),
        React.createElement(SuccessIndicator, { message: sendPowerCommandResponse == "" ? undefined : sendPowerCommandResponse, success: sendPowerCommandSuccess })));
};
var CommandPanel = function (_a) {
    var pnid = _a.pnid, details = _a.details, isNoc = _a.isNoc, hasChargePointVendorAccess = _a.hasChargePointVendorAccess;
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("hr", { className: "border-black-800 max-w-[50%] my-8" }),
        React.createElement("label", { className: "font-N6" }, "PNID ".concat(pnid)),
        isNoc && React.createElement(MaintenanceWindowDisplay, { pnid: pnid }),
        isNoc && (React.createElement(Disclosure, null, function (_a) {
            var open = _a.open;
            return (React.createElement(React.Fragment, null,
                React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                    React.createElement("label", { className: "font-N6" }, "Legacy Command"),
                    open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                React.createElement(Disclosure.Panel, { className: "w-full" },
                    React.createElement(LegacyCommandDisclosure, { pnid: pnid }))));
        })),
        (isNoc || hasChargePointVendorAccess) && (React.createElement(Disclosure, null, function (_a) {
            var open = _a.open;
            return (React.createElement(React.Fragment, null,
                React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                    React.createElement("label", { className: "font-N6" }, "JSON Command"),
                    open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                React.createElement(Disclosure.Panel, { className: "w-full" },
                    React.createElement(JsonCommandDisclosure, { pnid: pnid, details: details }))));
        })),
        isNoc && (React.createElement(React.Fragment, null,
            React.createElement(Disclosure, null, function (_a) {
                var open = _a.open;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                        React.createElement("label", { className: "font-N6" }, "GUI Command"),
                        open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                    React.createElement(Disclosure.Panel, { className: "w-full" },
                        React.createElement(GUICommandDisclosure, { pnid: pnid }))));
            }),
            React.createElement(Disclosure, null, function (_a) {
                var open = _a.open;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                        React.createElement("label", { className: "font-N6" }, "ATP Command"),
                        open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                    React.createElement(Disclosure.Panel, { className: "w-full" },
                        React.createElement(ATPCommandDisclosure, { pnid: pnid }))));
            }),
            React.createElement(Disclosure, null, function (_a) {
                var open = _a.open;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Disclosure.Button, { className: "bg-black-1000 hover:bg-black-800 rounded-[10px] px-[32px] py-[16px] flex flex-row gap-x-[32px] w-full justify-between" },
                        React.createElement("label", { className: "font-N6" }, "Command Log"),
                        open ? (React.createElement(ChevronDownIcon, { className: "w-[32px] h-[32px]" })) : (React.createElement(ChevronUpIcon, { className: "w-[32px] h-[32px]" }))),
                    React.createElement(Disclosure.Panel, { className: "w-full" },
                        React.createElement(CommandHistoryTable, { pnid: pnid }))));
            })))));
};
export var NOCCommands = function (_a) {
    var details = _a.details, isNoc = _a.isNoc, hasChargePointVendorAccess = _a.hasChargePointVendorAccess;
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("label", { className: "font-N5 text-white" }, "NOC Commands"),
        details &&
            details.powernodes
                .sort(function (a, b) { return a.id - b.id; })
                .map(function (pn) {
                return (React.createElement(CommandPanel, { pnid: pn.id, key: pn.id, details: details, isNoc: isNoc, hasChargePointVendorAccess: hasChargePointVendorAccess }));
            })));
};
