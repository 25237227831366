"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageCategory = void 0;
var ImageCategory;
(function (ImageCategory) {
    ImageCategory["CHARGER"] = "CHARGER";
    ImageCategory["ENTRANCE"] = "ENTRANCE";
    ImageCategory["LOCATION"] = "LOCATION";
    ImageCategory["NETWORK"] = "NETWORK";
    ImageCategory["OPERATOR"] = "OPERATOR";
    ImageCategory["OTHER"] = "OTHER";
    ImageCategory["OWNER"] = "OWNER";
})(ImageCategory = exports.ImageCategory || (exports.ImageCategory = {}));
