var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CheckCircleIcon, ExclamationCircleIcon, } from "@heroicons/react/24/outline";
import React from "react";
import { Transition } from "@headlessui/react";
export var SuccessIndicator = function (_a) {
    var success = _a.success, message = _a.message;
    var opacityTransitionProperties = {
        enter: "transition-opacity duration-500 pointer-events-none",
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leave: "transition-opacity duration-300 pointer-events-none",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
    };
    var showMessage = success != undefined && message != undefined;
    return (React.createElement(Transition, __assign({ className: "flex flex-row gap-x-2 items-center" }, opacityTransitionProperties, { show: showMessage }),
        success && (React.createElement(CheckCircleIcon, { className: "text-green h-[24px] w-[24px]" })),
        !success && (React.createElement(ExclamationCircleIcon, { className: "text-red h-[24px] w-[24px]" })),
        React.createElement("label", { className: "font-N8 text-white" }, message)));
};
