import React, { useRef, useEffect, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Radio } from "./Radio";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { SingleStat } from "./SingleStat";
import { GetDisplayPrice } from "ee-utils";
import { Circle } from "./Images";
import { useNavigate } from "react-router-dom";
var MapDetailsPopup = function (_a) {
    var _b, _c, _d, _e, _f;
    var title = _a.title, details = _a.details, onClose = _a.onClose;
    var navigate = useNavigate();
    return (React.createElement("div", { className: "absolute bottom-[24px] left-[24px] z-50 bg-black-1200 rounded-[10px] flex flex-col p-[24px] gap-y-[32px]" },
        React.createElement("div", { className: "flex flex-row" },
            React.createElement("div", { className: "flex flex-col gap-y-[12px]" },
                React.createElement("label", { className: "font-N7 text-white" }, title),
                React.createElement("label", { className: "font-N8p5 text-black-600" },
                    details.address1,
                    React.createElement("br", null),
                    details.address2),
                React.createElement("div", { className: "font-N8p5 text-black-600 flex flex-row items-center gap-x-[8px]" },
                    Circle, "".concat(details.activeSessions, " ACTIVE SESSION").concat(details.activeSessions != 1 ? "S" : ""))),
            React.createElement("div", { className: "grow min-w-[64px]" }),
            React.createElement("div", { className: "flex flex-row items-center h-fit gap-x-[16px]" },
                React.createElement("button", { className: "text-black-1200 font-N8p5 px-[12px] py-[12px] bg-black-200 hover:bg-black-400 rounded-[5px] cursor-pointer h-fit w-fit", onClick: function () { return navigate(details.stationLink); } }, "VIEW STATION"),
                React.createElement(XMarkIcon, { className: "h-[28px] w-[28px] text-white hover:text-black-400 cursor-pointer", onClick: onClose }))),
        React.createElement("div", { className: "flex flex-row gap-x-[40px]" },
            React.createElement(SingleStat, { title: "REVENUE", value: "$".concat(GetDisplayPrice((_b = details.revenueAmount) !== null && _b !== void 0 ? _b : 0, (_c = details.revenuePrecision) !== null && _c !== void 0 ? _c : 2)) }),
            React.createElement(SingleStat, { title: "ENERGY", value: "".concat(((_d = details.energyDeliveredKwh) !== null && _d !== void 0 ? _d : 0.0).toFixed(1), " kWh") }),
            React.createElement(SingleStat, { title: "UPTIME", value: "".concat(Math.round(((_e = details.uptime) !== null && _e !== void 0 ? _e : 0) * 100), "%") }),
            React.createElement(SingleStat, { title: "USAGE", value: "".concat(Math.round(((_f = details.usage) !== null && _f !== void 0 ? _f : 0) * 100), "%") }))));
};
var MapSelector;
(function (MapSelector) {
    MapSelector[MapSelector["REVENUE"] = 0] = "REVENUE";
    MapSelector[MapSelector["UPTIME"] = 1] = "UPTIME";
    MapSelector[MapSelector["ENERGY"] = 2] = "ENERGY";
    MapSelector[MapSelector["USAGE"] = 3] = "USAGE";
    MapSelector[MapSelector["NONE"] = 4] = "NONE";
})(MapSelector || (MapSelector = {}));
function getMarkerScale(selector, marker, allMarkers) {
    var _a, _b, _c, _d;
    var baseScale = 11;
    var scalingFactor = 20;
    var percentScale = 1;
    var getMagnitudePercentFromData = function (p, data) {
        var minVal = Math.min.apply(Math, data);
        var maxVal = Math.max.apply(Math, data);
        if (minVal - maxVal == 0) {
            return 0;
        }
        return (p - minVal) / (maxVal - minVal);
    };
    switch (selector) {
        case MapSelector.REVENUE:
            var revenue = allMarkers.map(function (m) { var _a, _b; return (_b = (_a = m.details) === null || _a === void 0 ? void 0 : _a.revenueAmount) !== null && _b !== void 0 ? _b : 0; });
            percentScale = getMagnitudePercentFromData((_b = (_a = marker.details) === null || _a === void 0 ? void 0 : _a.revenueAmount) !== null && _b !== void 0 ? _b : 0, revenue);
            break;
        case MapSelector.ENERGY:
            var energy = allMarkers.map(function (m) { var _a, _b; return (_b = (_a = m.details) === null || _a === void 0 ? void 0 : _a.energyDeliveredKwh) !== null && _b !== void 0 ? _b : 0; });
            percentScale = getMagnitudePercentFromData((_d = (_c = marker.details) === null || _c === void 0 ? void 0 : _c.energyDeliveredKwh) !== null && _d !== void 0 ? _d : 0, energy);
            break;
        default:
        case MapSelector.NONE:
            percentScale = 0;
            break;
    }
    return baseScale + percentScale * scalingFactor;
}
function getMarkerColor(selector, marker) {
    var _a, _b, _c, _d;
    var percent = 1;
    switch (selector) {
        case MapSelector.USAGE:
            percent = (_b = (_a = marker.details) === null || _a === void 0 ? void 0 : _a.usage) !== null && _b !== void 0 ? _b : 0;
            break;
        case MapSelector.UPTIME:
            percent = (_d = (_c = marker.details) === null || _c === void 0 ? void 0 : _c.uptime) !== null && _d !== void 0 ? _d : 0;
            break;
        default:
        case MapSelector.NONE:
            return "#FFFFFF";
    }
    var blueRGB = [26, 159, 234];
    var redRGB = [255, 109, 109];
    var percentBetween = function (p, n1, n2) {
        return Math.round(n1 + (n2 - n1) * p);
    };
    var color = [
        percentBetween(percent, redRGB[0], blueRGB[0]),
        percentBetween(percent, redRGB[1], blueRGB[1]),
        percentBetween(percent, redRGB[2], blueRGB[2]),
    ];
    return "rgb(".concat(color[0], ",").concat(color[1], ",").concat(color[2], ")");
}
function getMarkerOpacity(selector) {
    switch (selector) {
        case MapSelector.USAGE:
        case MapSelector.UPTIME:
            return 1.0;
    }
    return 0.8;
}
var MapComponent = function (_a) {
    var markers = _a.markers, disable = _a.disable;
    var ref = useRef(null);
    var mapRef = useRef(null);
    var activeMarkers = useRef(new Map());
    var _b = useState(null), selectedMarkerId = _b[0], setSelectedMarkerId = _b[1];
    var _c = useState(disable ? MapSelector.NONE : MapSelector.REVENUE), selectedMetric = _c[0], setSelectedMetric = _c[1];
    // This will be performed once, even if props change. Every time this runs, the map flashes.
    useEffect(function () {
        if (ref.current) {
            mapRef.current = new window.google.maps.Map(ref.current, {
                // See https://console.cloud.google.com/google/maps-apis/studio/styles
                mapId: "e08300f9e99b71ec",
                disableDefaultUI: true,
            });
            if (disable) {
                mapRef.current.setOptions({
                    draggable: false,
                    zoomControl: false,
                    scrollwheel: false,
                    disableDoubleClickZoom: true,
                });
            }
        }
    }, []);
    useEffect(function () {
        if (mapRef.current) {
            var anyNewMarker_1 = false;
            markers.forEach(function (m) {
                if (!activeMarkers.current.has(m.id)) {
                    var mapMarker = new google.maps.Marker({
                        position: {
                            lat: m.latitude,
                            lng: m.longitude,
                        },
                        map: mapRef.current,
                        title: m.title,
                    });
                    mapMarker.addListener("click", function () {
                        if (m.details) {
                            mapRef.current.setCenter(new google.maps.LatLng(m.latitude, m.longitude));
                            setSelectedMarkerId(m.id);
                        }
                    });
                    activeMarkers.current.set(m.id, {
                        marker: mapMarker,
                    });
                    anyNewMarker_1 = true;
                }
            });
            markers.forEach(function (m) {
                if (activeMarkers.current.has(m.id)) {
                    var activeMarker = activeMarkers.current.get(m.id);
                    activeMarker === null || activeMarker === void 0 ? void 0 : activeMarker.marker.setIcon({
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: getMarkerScale(selectedMetric, m, markers),
                        fillColor: getMarkerColor(selectedMetric, m),
                        fillOpacity: getMarkerOpacity(selectedMetric),
                        strokeWeight: 0.4,
                    });
                }
            });
            if (anyNewMarker_1) {
                if (markers.length > 0) {
                    // Set zoom.
                    var minLatitude = Math.min.apply(Math, markers.map(function (m) { return m.latitude; })) - 0.01;
                    var maxLatitude = Math.max.apply(Math, markers.map(function (m) { return m.latitude; })) + 0.01;
                    var minLongitude = Math.min.apply(Math, markers.map(function (m) { return m.longitude; })) - 0.01;
                    var maxLongitude = Math.max.apply(Math, markers.map(function (m) { return m.longitude; })) + 0.01;
                    mapRef.current.fitBounds(new google.maps.LatLngBounds(new google.maps.LatLng(minLatitude, minLongitude), new google.maps.LatLng(maxLatitude, maxLongitude)));
                }
                else {
                    mapRef.current.setCenter(new google.maps.LatLng(39.8097343, -98.5556199));
                    mapRef.current.setZoom(4);
                }
            }
        }
    });
    var radioItems = [
        {
            name: "REVENUE",
            value: MapSelector.REVENUE,
        },
        {
            name: "UPTIME",
            value: MapSelector.UPTIME,
        },
        {
            name: "ENERGY",
            value: MapSelector.ENERGY,
        },
        {
            name: "USAGE",
            value: MapSelector.USAGE,
        },
    ];
    var selectedMarker = selectedMarkerId != null
        ? markers.find(function (m) { return m.id == selectedMarkerId; })
        : null;
    return (React.createElement("div", { className: "h-full relative" },
        !disable && (React.createElement("div", { className: "absolute top-[24px] left-[24px] z-50" },
            React.createElement(Radio, { items: radioItems, value: selectedMetric, setValue: setSelectedMetric }))),
        selectedMarker != null && (React.createElement(MapDetailsPopup, { title: selectedMarker.title, details: selectedMarker.details, onClose: function () { return setSelectedMarkerId(null); } })),
        React.createElement("div", { ref: ref, id: "map", className: "h-full" })));
};
export var GoogleMap = function (_a) {
    var markers = _a.markers, disable = _a.disable;
    return (React.createElement(Wrapper, { apiKey: "AIzaSyDQLp-_Uu5dDGRMufyBIxYLIRaX3aEm-FA" },
        React.createElement(MapComponent, { markers: markers, disable: disable })));
};
