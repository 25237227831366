import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { process } from "../Process";
import { Auth0Fetcher } from "../Auth0Fetcher";
import { Link } from "react-router-dom";
import { GetDateString } from "../Util";
import { GoogleMap } from "../Components/GoogleMap";
import { Dropdown } from "../Components/Dropdown";
import { Circle } from "../Components/Images";
import { Card } from "../Components/Card";
import { Search } from "../Components/Search";
var SiteGrid = function (_a) {
    var sites = _a.sites;
    return (React.createElement(React.Fragment, null, sites.map(function (site, i) {
        return (React.createElement(Card, { key: i, wrapperClassName: "w-[360px]", className: "w-[360px] hover:bg-white/10", noPadding: true, spotlights: ["top-[-320px] right-[-300px]"] },
            React.createElement(Link, { to: "/station/".concat(site.id), className: "flex flex-row gap-x-[32px]" },
                React.createElement("img", { src: "/station.png", className: "z-[-10] ".concat(site.expectedDateOnline &&
                        new Date(site.expectedDateOnline) > new Date()
                        ? "filter grayscale blur-sm"
                        : "") }),
                React.createElement("div", { className: "flex flex-col gap-y-[8px] justify-center" },
                    React.createElement("div", { className: "font-N7 text-white mr-[8px]" }, site.name),
                    (!site.expectedDateOnline ||
                        new Date(site.expectedDateOnline) < new Date()) && (React.createElement("div", { className: "font-N9 text-white flex flex-row items-center gap-x-[8px]" },
                        Circle, "(".concat(site.activeSessions, ") ACTIVE SESSION").concat(site.activeSessions != 1 ? "S" : ""))),
                    site.expectedDateOnline &&
                        new Date(site.expectedDateOnline) > new Date() && (React.createElement("div", { className: "font-N9 text-white flex flex-row items-center gap-x-[16px]" }, "Online Date: ".concat(GetDateString(new Date(site.expectedDateOnline))))),
                    React.createElement("div", { className: "font-N9 text-black-600" },
                        site.addressLine1,
                        React.createElement("br", null),
                        site.addressLine2)))));
    })));
};
var FilterOptions;
(function (FilterOptions) {
    FilterOptions["INACTIVE"] = "Inactive";
    FilterOptions["ACTIVE"] = "Active";
    FilterOptions["ACTIVE_CHARGING_SESSIONS"] = "Active Charging Sessions";
    FilterOptions["MAINTENANCE"] = "Maintenance";
    FilterOptions["COMING_SOON"] = "Coming Soon";
    FilterOptions["NONE"] = "None";
})(FilterOptions || (FilterOptions = {}));
// Stations list page.
export var Stations = function (_a) {
    var _b;
    var isNoc = _a.isNoc, organizationId = _a.organizationId;
    var _c = useAuth0(), isAuthenticated = _c.isAuthenticated, isLoading = _c.isLoading, getAccessTokenSilently = _c.getAccessTokenSilently;
    var topHeight = "h-[256px]";
    var siteList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/list/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var filters = [
        {
            label: "Site Name",
            filter: function (site, search) {
                return site.name.toLowerCase().includes(search.toLowerCase());
            },
        },
        {
            label: "Address",
            filter: function (site, search) {
                return site.addressLine1.toLowerCase().includes(search.toLowerCase()) ||
                    site.addressLine2.toLowerCase().includes(search.toLowerCase());
            },
        },
    ];
    if (isNoc) {
        filters.push({
            label: "Site ID",
            filter: function (site, search) { return "".concat(site.id).includes(search); },
        });
        filters.push({
            label: "Charger Friendly ID",
            filter: function (site, search) {
                return site.chargerFriendlyIds &&
                    site.chargerFriendlyIds.find(function (e) { return "".concat(e).includes(search); }) != null;
            },
        });
        filters.push({
            label: "Charger OCPP ID",
            filter: function (site, search) {
                return site.chargerOcppIds &&
                    site.chargerOcppIds.find(function (e) { return e.toLowerCase().includes(search); }) !=
                        null;
            },
        });
        filters.push({
            label: "PowerNode ID",
            filter: function (site, search) {
                return site.chargerFriendlyIds &&
                    site.powernodeIds.find(function (e) {
                        return "".concat(e).includes(search.replace(/[^\d.]/g, ""));
                    }) != null;
            },
        });
    }
    var _d = useState(""), activeSearch = _d[0], setActiveSearch = _d[1];
    var _e = useState(FilterOptions.NONE), filterState = _e[0], setFilterState = _e[1];
    var filteredSiteList = ((_b = siteList === null || siteList === void 0 ? void 0 : siteList.sites) !== null && _b !== void 0 ? _b : [])
        .filter(function (site) {
        switch (filterState) {
            case FilterOptions.NONE:
                return true;
            case FilterOptions.COMING_SOON:
                return site.expectedDateOnline != null;
            case FilterOptions.INACTIVE:
                return (site.expectedDateOnline &&
                    new Date(site.expectedDateOnline) > new Date());
            case FilterOptions.ACTIVE:
                return (!site.expectedDateOnline ||
                    new Date(site.expectedDateOnline) <= new Date());
            case FilterOptions.ACTIVE_CHARGING_SESSIONS:
                return site.activeSessions > 0;
            case FilterOptions.MAINTENANCE:
                return site.maintenance;
            default:
                return true;
        }
    })
        .sort(function (a, b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); });
    return (React.createElement("div", { className: "px-[32px]" },
        React.createElement("div", { className: "absolute left-0 right-0 top-0 ".concat(topHeight) }, siteList && (React.createElement(GoogleMap, { markers: filteredSiteList.map(function (site) {
                return {
                    id: site.id,
                    latitude: Number(site.latitude),
                    longitude: Number(site.longitude),
                    title: site.name,
                };
            }), disable: true }))),
        React.createElement("div", { className: "".concat(topHeight, " mb-[64px]") }),
        React.createElement("label", { className: "text-white font-N4" }, "My Stations"),
        React.createElement("div", { className: "grid grid-cols-3 flex-wrap gap-[32px] my-[64px] w-fit" },
            React.createElement("div", { className: "flex flex-row gap-x-[32px] items-center" },
                React.createElement("label", { className: "font-N7 text-black-600" }, "Filter"),
                React.createElement(Dropdown, { items: [
                        {
                            name: "None",
                            value: FilterOptions.NONE,
                            selectedLabel: "Filter",
                        },
                        { name: "Inactive", value: FilterOptions.INACTIVE },
                        { name: "Active", value: FilterOptions.ACTIVE },
                        {
                            name: "Active Charging Sessions",
                            value: FilterOptions.ACTIVE_CHARGING_SESSIONS,
                        },
                        { name: "Coming Soon", value: FilterOptions.COMING_SOON },
                        { name: "Maintenance", value: FilterOptions.MAINTENANCE },
                    ], onClick: setFilterState, currentSelection: filterState })),
            React.createElement("div", null),
            React.createElement("div", { className: "flex flex-row items-center justify-end" },
                React.createElement(Search, { onSearchChanged: function (val) {
                        setActiveSearch(val);
                    }, placeHolder: "Search Stations" })),
            activeSearch == "" && React.createElement(SiteGrid, { sites: filteredSiteList }),
            activeSearch != "" && (React.createElement(React.Fragment, null, filters.map(function (f) {
                var searchSites = filteredSiteList.filter(function (s) {
                    return f.filter(s, activeSearch);
                });
                return (React.createElement(React.Fragment, null,
                    React.createElement("label", { className: "col-span-3 font-N5 text-white" }, "Sites matching ".concat(f.label, " `").concat(activeSearch, "`")),
                    searchSites.length > 0 && React.createElement(SiteGrid, { sites: searchSites })));
            }))))));
};
