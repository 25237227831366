var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { process } from "../../Process";
import { TextInput } from "../Input";
import { GetDisplayPrice } from "ee-utils";
import { OnOffRadio } from "../Radio";
import { HelpTooltip } from "../HelpTooltip";
import { StyledButton } from "../StyledButton";
import { Transition } from "@headlessui/react";
var IdleFee = function (_a) {
    var title = _a.title, helpText = _a.helpText, detail = _a.detail, minimumGracePeriod = _a.minimumGracePeriod, idleFeeEnabled = _a.idleFeeEnabled, idleFeeAmount = _a.idleFeeAmount, idleFeeGracePeriod = _a.idleFeeGracePeriod, setIdleFeeAmount = _a.setIdleFeeAmount, setIdleFeeEnabled = _a.setIdleFeeEnabled, setIdleFeeGracePeriod = _a.setIdleFeeGracePeriod;
    return (React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
        React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "text-black-400 font-N8p5" }, title),
            React.createElement(HelpTooltip, { text: helpText })),
        React.createElement("div", { className: "flex flex-row gap-x-[16px] items-center" },
            React.createElement(OnOffRadio, { on: idleFeeEnabled !== null && idleFeeEnabled !== void 0 ? idleFeeEnabled : false, setOn: setIdleFeeEnabled }),
            React.createElement("div", { className: "flex flex-row gap-x-[16px] items-center ".concat(!idleFeeEnabled ? "opacity-25" : "") },
                React.createElement("label", { className: "font-N8 text-white" }, detail),
                React.createElement(TextInput, { placeholder: "".concat(idleFeeGracePeriod !== null && idleFeeGracePeriod !== void 0 ? idleFeeGracePeriod : "60"), type: "number", step: "1", min: minimumGracePeriod, className: "w-[120px]", onChange: function (e) { return setIdleFeeGracePeriod(e.target.value); }, disabled: !idleFeeEnabled }),
                React.createElement("label", { className: "font-N8 text-white" }, "minutes:"),
                React.createElement("span", { className: "before:text-black-600 relative before:content-['$'] before:absolute before:pl-[8px] before:translate-y-[-50%] before:top-[50%] after:content-['/minute'] after:absolute after:translate-y-[-50%] after:top-[50%] after:right-[8px] after:text-black-600" },
                    React.createElement(TextInput, { placeholder: idleFeeAmount, type: "number", step: ".01", min: ".01", max: "1", pattern: "^\\d+(\\.)\\d{2}$", className: "!pr-[48px]", disabled: !idleFeeEnabled, onChange: function (e) { return setIdleFeeAmount(e.target.value); } }))))));
};
export var Pricing = function (_a) {
    var _b;
    var details = _a.details;
    var _c = useAuth0(), isAuthenticated = _c.isAuthenticated, isLoading = _c.isLoading, getAccessTokenSilently = _c.getAccessTokenSilently;
    var requestUrl = "".concat(process.env.CORE_SVC_URL, "/pricing/current/").concat(details.id);
    var newPricing = useSWR(isLoading || !isAuthenticated ? null : [requestUrl, getAccessTokenSilently], Auth0Fetcher).data;
    var _d = useState(), pricing = _d[0], setPricing = _d[1];
    if (JSON.stringify(pricing) != JSON.stringify(newPricing)) {
        setPricing(newPricing);
    }
    var formRef = useRef(null);
    var _e = useState("0.42"), pricePerKwh = _e[0], setPricePerKwh = _e[1];
    var _f = useState(false), freeMode = _f[0], setFreeMode = _f[1];
    var _g = useState(false), idleFeesAfterChargingStartsEnabled = _g[0], setIdleFeesAfterChargingStartsEnabled = _g[1];
    var _h = useState("0.50"), idleFeeAfterChargingStarts = _h[0], setIdleFeeAfterChargingStarts = _h[1];
    var _j = useState("60"), idleFeeAfterChargingStartsGracePeriod = _j[0], setIdleFeeAfterChargingStartsGracePeriod = _j[1];
    var _k = useState(false), idleFeesAfterChargingEndsEnabled = _k[0], setIdleFeesAfterChargingEndsEnabled = _k[1];
    var _l = useState("0.50"), idleFeeAfterChargingEnds = _l[0], setIdleFeeAfterChargingEnds = _l[1];
    var _m = useState("60"), idleFeeAfterChargingEndsGracePeriod = _m[0], setIdleFeeAfterChargingEndsGracePeriod = _m[1];
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (pricing != null) {
            setPricePerKwh(GetDisplayPrice((_c = (_b = (_a = pricing.pricePerKwh) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 : _b.amount) !== null && _c !== void 0 ? _c : 42, (_f = (_e = (_d = pricing.pricePerKwh) === null || _d === void 0 ? void 0 : _d.amount) === null || _e === void 0 ? void 0 : _e.precision) !== null && _f !== void 0 ? _f : 2));
            setFreeMode(pricing.freemode != null);
            setIdleFeesAfterChargingStartsEnabled(pricing.idleFeesAfterChargingStarts != null);
            if (pricing.idleFeesAfterChargingStarts != null) {
                setIdleFeeAfterChargingStarts(GetDisplayPrice((_g = pricing.idleFeesAfterChargingStarts.amountPerMinute) === null || _g === void 0 ? void 0 : _g.amount, (_h = pricing.idleFeesAfterChargingStarts.amountPerMinute) === null || _h === void 0 ? void 0 : _h.precision));
                setIdleFeeAfterChargingStartsGracePeriod("".concat(pricing.idleFeesAfterChargingStarts.gracePeriodMinutes));
            }
            setIdleFeesAfterChargingEndsEnabled(pricing.idleFeesAfterChargingEnds != null);
            if (pricing.idleFeesAfterChargingEnds != null) {
                setIdleFeeAfterChargingEnds(GetDisplayPrice((_j = pricing.idleFeesAfterChargingEnds.amountPerMinute) === null || _j === void 0 ? void 0 : _j.amount, (_k = pricing.idleFeesAfterChargingEnds.amountPerMinute) === null || _k === void 0 ? void 0 : _k.precision));
                setIdleFeeAfterChargingEndsGracePeriod("".concat(pricing.idleFeesAfterChargingEnds.gracePeriodMinutes));
            }
        }
    }, [pricing]);
    var onPriceSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var freemodeModel, pricePerKwhModel, amount, convertInputsToIdleFee, model, accessToken, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    freemodeModel = undefined;
                    pricePerKwhModel = undefined;
                    if (freeMode) {
                        freemodeModel = {};
                    }
                    else {
                        amount = Math.round(parseFloat(pricePerKwh) * 100);
                        if (isNaN(amount)) {
                            return [2 /*return*/];
                        }
                        pricePerKwhModel = {
                            amount: {
                                amount: amount,
                                precision: 2,
                                currencyCode: "USD",
                            },
                        };
                    }
                    convertInputsToIdleFee = function (enabled, amount, gracePeriodMinutes) {
                        var parsedAmount = Math.round(parseFloat(amount) * 100);
                        if (isNaN(parsedAmount)) {
                            return;
                        }
                        var gracePeriodParsed = Math.round(parseFloat(gracePeriodMinutes));
                        if (isNaN(gracePeriodParsed)) {
                            return;
                        }
                        return enabled
                            ? {
                                amountPerMinute: {
                                    amount: parsedAmount,
                                    precision: 2,
                                    currencyCode: "USD",
                                },
                                gracePeriodMinutes: gracePeriodParsed,
                            }
                            : undefined;
                    };
                    model = {
                        freemode: freemodeModel,
                        pricePerKwh: pricePerKwhModel,
                        surcharges: [],
                        idleFeesAfterChargingEnds: !freeMode
                            ? convertInputsToIdleFee(idleFeesAfterChargingEndsEnabled, idleFeeAfterChargingEnds, idleFeeAfterChargingEndsGracePeriod)
                            : undefined,
                        idleFeesAfterChargingStarts: !freeMode
                            ? convertInputsToIdleFee(idleFeesAfterChargingStartsEnabled, idleFeeAfterChargingStarts, idleFeeAfterChargingStartsGracePeriod)
                            : undefined,
                    };
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/pricing/current/").concat(details.id), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(model),
                        })];
                case 2:
                    res = _a.sent();
                    if (!res.ok) {
                        console.log("Error submitting price: ", res.statusText);
                        return [2 /*return*/];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var validateAndSubmitPricing = function () { return __awaiter(void 0, void 0, void 0, function () {
        var childInputs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!formRef.current) {
                        return [2 /*return*/];
                    }
                    if (!formRef.current.checkValidity()) {
                        console.log("Skipping submit as form is invalid.");
                        return [2 /*return*/];
                    }
                    childInputs = formRef.current.querySelectorAll("input");
                    return [4 /*yield*/, onPriceSubmit()];
                case 1:
                    _a.sent();
                    childInputs.forEach(function (i) {
                        i.value = "";
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var isCalifornia = ((_b = details.state) === null || _b === void 0 ? void 0 : _b.toLocaleLowerCase()) == "california";
    return (React.createElement("form", { className: "flex flex-col gap-y-[32px]", ref: formRef, onSubmit: function (e) { return e.preventDefault(); } },
        React.createElement("label", { className: "font-N6 text-white" }, "Free Mode"),
        React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
            React.createElement("label", { className: "text-black-400 font-N8p5" }, "ENABLE FREE MODE"),
            React.createElement(OnOffRadio, { on: freeMode, setOn: setFreeMode })),
        React.createElement(Transition, { show: !freeMode, enter: "transition duration-100 ease-out", enterFrom: "transform scale-95 opacity-0", enterTo: "transform scale-100 opacity-100", leave: "transition duration-75 ease-out", leaveFrom: "transform scale-100 opacity-100", leaveTo: "transform scale-95 opacity-0", className: "flex flex-col gap-y-[32px]" },
            React.createElement("label", { className: "font-N6 text-white" }, "Pricing"),
            React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                React.createElement("label", { className: "text-black-400 font-N8p5" }, "CURRENT PRICE"),
                React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                    React.createElement("span", { className: "before:text-black-600 relative before:content-['$'] before:absolute before:pl-[8px] before:translate-y-[-50%] before:top-[50%] after:content-['/kWh'] after:absolute after:translate-y-[-50%] after:top-[50%] after:right-[8px] after:text-black-600" },
                        React.createElement(TextInput, { placeholder: pricePerKwh, type: "number", step: ".01", min: ".01", max: "5", pattern: "^\\d+(\\.)\\d{2}$", className: "!pr-[48px]", onChange: function (e) { return setPricePerKwh(e.target.value); } })))),
            !isCalifornia && (React.createElement(React.Fragment, null,
                React.createElement("label", { className: "font-N6 text-white" }, "Idle Fees"),
                React.createElement(IdleFee, { title: "IDLE FEES FOR LONG SESSIONS", detail: "After charging has been ongoing for", minimumGracePeriod: "60", idleFeeEnabled: idleFeesAfterChargingStartsEnabled, idleFeeAmount: idleFeeAfterChargingStarts, idleFeeGracePeriod: idleFeeAfterChargingStartsGracePeriod, helpText: "These fees begin accruing after a customer has been actively charging for longer than the specified time. Use this to discourage long charging sessions. Note that this fee will only apply while the charger is actively charging a car.", setIdleFeeAmount: setIdleFeeAfterChargingStarts, setIdleFeeEnabled: setIdleFeesAfterChargingStartsEnabled, setIdleFeeGracePeriod: setIdleFeeAfterChargingStartsGracePeriod }),
                React.createElement(IdleFee, { title: "IDLE FEES FOR IDLE SESSIONS", detail: "After charger is idle and customer remains plugged in for", minimumGracePeriod: "5", idleFeeEnabled: idleFeesAfterChargingEndsEnabled, idleFeeAmount: idleFeeAfterChargingEnds, idleFeeGracePeriod: idleFeeAfterChargingEndsGracePeriod, helpText: "These fees begin accruing when the customer remains plugged in after the charger stops actively charging the car. Use this to discourage customers from idling at the charger when no useful work is being done.", setIdleFeeAmount: setIdleFeeAfterChargingEnds, setIdleFeeEnabled: setIdleFeesAfterChargingEndsEnabled, setIdleFeeGracePeriod: setIdleFeeAfterChargingEndsGracePeriod })))),
        React.createElement(StyledButton, { text: "SAVE CHANGES", onClick: validateAndSubmitPricing, className: "mt-20" })));
};
