import React, { useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap } from "../GoogleMap";
import { DateRange, TimeHorizon, getDateRange, GetHistoricalDateRanges, } from "../DateRange";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Fetcher, Auth0FetcherWithBody } from "../../Auth0Fetcher";
import { ResourceOverviewCards } from "../ResourceOverviewCards";
import { process } from "../../Process";
import useSWR from "swr";
import { BarStatCard, StatCard } from "../Card";
export var OrganizationOverview = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var siteList = _a.siteList, organizationId = _a.organizationId;
    var _h = useState(TimeHorizon.DAY), timeHorizon = _h[0], setTimeHorizon = _h[1];
    var dateRange = getDateRange(timeHorizon, new Date());
    var historicalDateRanges = GetHistoricalDateRanges(timeHorizon, 4, new Date());
    var _j = useAuth0(), isAuthenticated = _j.isAuthenticated, isLoading = _j.isLoading, getAccessTokenSilently = _j.getAccessTokenSilently;
    var revenue = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/revenue/org/").concat(organizationId),
            getAccessTokenSilently,
            [dateRange],
        ], Auth0FetcherWithBody).data;
    var historicalRevenue = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/revenue/org/").concat(organizationId),
            getAccessTokenSilently,
            historicalDateRanges,
        ], Auth0FetcherWithBody).data;
    var orgStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/org/").concat(organizationId, "/site_stats"),
            getAccessTokenSilently,
            [dateRange],
        ], Auth0FetcherWithBody).data;
    var energy = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/metrics_history/org/").concat(organizationId),
            getAccessTokenSilently,
            [dateRange],
        ], Auth0FetcherWithBody).data;
    var historicalEnergy = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/metrics_history/org/").concat(organizationId),
            getAccessTokenSilently,
            historicalDateRanges,
        ], Auth0FetcherWithBody).data;
    var liveStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/organization/liveStats/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var mapDivRef = useRef(null);
    var resourceDivRef = useRef(null);
    var sites = useMemo(function () {
        return siteList.sites.filter(function (s) {
            return !s.expectedDateOnline || new Date(s.expectedDateOnline) < new Date();
        });
    }, [siteList]);
    useEffect(function () {
        if (mapDivRef.current && resourceDivRef.current) {
            mapDivRef.current.style.width = "".concat(resourceDivRef.current.offsetWidth, "px");
        }
    });
    return (React.createElement("div", { className: "w-full flex flex-col gap-y-[32px]" },
        React.createElement(DateRange, { value: timeHorizon, setValue: setTimeHorizon }),
        React.createElement("div", { className: "w-fit gap-y-[16px] flex flex-col", ref: resourceDivRef },
            React.createElement(ResourceOverviewCards, { revenue: revenue ? revenue[0] : undefined, revenueHistory: historicalRevenue, usage: orgStats ? (_b = orgStats[0]) === null || _b === void 0 ? void 0 : _b.orgStats.usage : undefined, uptime: orgStats ? (_c = orgStats[0]) === null || _c === void 0 ? void 0 : _c.orgStats.uptime : undefined, energyDelivered: energy ? (_d = energy[0]) === null || _d === void 0 ? void 0 : _d.energyDispensed : undefined, energyDeliveredHistory: historicalEnergy
                    ? historicalEnergy.map(function (e) { return e.energyDispensed; })
                    : undefined }),
            React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
                React.createElement(BarStatCard, { title: "TRANSACTIONS", primaryValue: energy ? (_e = energy[0]) === null || _e === void 0 ? void 0 : _e.numberOfTransactions : undefined, valueToString: function (v) { return "".concat(v); }, historicalValues: historicalEnergy
                        ? historicalEnergy.map(function (e) { return e.numberOfTransactions; })
                        : undefined }),
                React.createElement(StatCard, { title: "SITES ONLINE", value: "".concat(sites.length) }),
                React.createElement(StatCard, { title: "CHARGERS ONLINE", value: "".concat(sites.map(function (s) { return s.chargerFriendlyIds; }).flat().length) }),
                React.createElement(StatCard, { title: "LIVE CHARGING POWER", value: "".concat((_g = (_f = liveStats === null || liveStats === void 0 ? void 0 : liveStats.totalChargerPowerKw) === null || _f === void 0 ? void 0 : _f.toFixed(1)) !== null && _g !== void 0 ? _g : "--", " kW") }))),
        React.createElement("div", { className: "h-[500px] rounded-[10px] overflow-hidden", ref: mapDivRef },
            React.createElement(GoogleMap, { markers: sites.map(function (site) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                    return {
                        latitude: Number(site.latitude),
                        longitude: Number(site.longitude),
                        title: site.name,
                        id: site.id,
                        details: {
                            address1: site.addressLine1,
                            address2: site.addressLine2,
                            activeSessions: site.activeSessions,
                            revenueAmount: orgStats
                                ? (_b = (_a = orgStats[0]) === null || _a === void 0 ? void 0 : _a.siteRevenueStats.find(function (s) { return s.id == site.id; })) === null || _b === void 0 ? void 0 : _b.revenueAmount
                                : undefined,
                            revenuePrecision: orgStats
                                ? (_d = (_c = orgStats[0]) === null || _c === void 0 ? void 0 : _c.siteRevenueStats.find(function (s) { return s.id == site.id; })) === null || _d === void 0 ? void 0 : _d.precision
                                : undefined,
                            energyDeliveredKwh: orgStats
                                ? (_f = (_e = orgStats[0]) === null || _e === void 0 ? void 0 : _e.siteTransactionStats.find(function (s) { return s.id == site.id; })) === null || _f === void 0 ? void 0 : _f.energyDispensed
                                : undefined,
                            usage: orgStats
                                ? (_h = (_g = orgStats[0]) === null || _g === void 0 ? void 0 : _g.siteStats.find(function (s) { return s.id == site.id; })) === null || _h === void 0 ? void 0 : _h.usage
                                : undefined,
                            uptime: orgStats
                                ? (_k = (_j = orgStats[0]) === null || _j === void 0 ? void 0 : _j.siteStats.find(function (s) { return s.id == site.id; })) === null || _k === void 0 ? void 0 : _k.uptime
                                : undefined,
                            stationLink: "/station/".concat(site.id),
                        },
                    };
                }) }))));
};
