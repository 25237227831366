var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import TextTransition, { presets } from "react-text-transition";
import { FormatNumber, NumberLocaleStringStyle, } from "../Util";
import { HelpTooltip } from "./HelpTooltip";
export var ComparisonStat = function (_a) {
    var _b;
    var header = _a.header, title = _a.title, firstValue = _a.firstValue, secondValue = _a.secondValue, isTimeInSeconds = _a.isTimeInSeconds, _c = _a.prefix, prefix = _c === void 0 ? "" : _c, _d = _a.suffix, suffix = _d === void 0 ? "" : _d, helpText = _a.helpText, helpDetails = _a.helpDetails, center = _a.center, large = _a.large, className = _a.className, reverse = _a.reverse, titleClassName = _a.titleClassName, valueClassName = _a.valueClassName, monospace = _a.monospace, numberFormatOptions = _a.numberFormatOptions;
    var titleSize = large ? "font-N7" : "font-N8p5";
    var valueSize = large ? "font-N6" : "font-N7";
    function getTimeTextFromSeconds(seconds) {
        var s = Math.round(seconds) % 60;
        var m = Math.floor(seconds / 60);
        return "".concat(m, "m ").concat(s, "s");
    }
    secondValue = isNaN(secondValue !== null && secondValue !== void 0 ? secondValue : 0) ? 0 : secondValue;
    var percentageSpecificFormatOptions = {
        useLocaleString: true,
        localeStringStyle: NumberLocaleStringStyle.Percent,
    };
    var percentageFormatOptions = __assign(__assign({}, (numberFormatOptions !== null && numberFormatOptions !== void 0 ? numberFormatOptions : {})), percentageSpecificFormatOptions);
    var percentageChangeValue = secondValue
        ? (_b = (firstValue - secondValue) / secondValue) !== null && _b !== void 0 ? _b : 1
        : 0;
    var percentageChange = FormatNumber(percentageChangeValue, percentageFormatOptions);
    return (React.createElement("div", { className: "flex ".concat(reverse ? "flex-col-reverse" : "flex-col", " gap-y-[8px] ").concat(center ? "items-center" : "", " ").concat(className !== null && className !== void 0 ? className : "") },
        header,
        React.createElement("div", { className: "flex flex-row gap-x-2" },
            React.createElement(TextTransition, { className: "".concat(titleSize, " text-black-400 whitespace-nowrap [&>*]:whitespace-nowrap ").concat(titleClassName !== null && titleClassName !== void 0 ? titleClassName : "", " ").concat(center ? "m-auto" : ""), springConfig: presets.stiff }, title),
            helpText != null && (React.createElement(HelpTooltip, { text: helpText, small: true, additionalDetails: helpDetails }))),
        React.createElement("div", { className: "flex flex-row gap-x-4" },
            React.createElement(TextTransition, { className: "".concat(valueSize, " text-white whitespace-nowrap [&>*]:whitespace-nowrap ").concat(valueClassName !== null && valueClassName !== void 0 ? valueClassName : "", " ").concat(center ? "m-auto" : "", " ").concat(monospace ? "font-mono" : ""), springConfig: presets.stiff }, isTimeInSeconds
                ? getTimeTextFromSeconds(firstValue)
                : prefix + FormatNumber(firstValue, numberFormatOptions) + suffix),
            React.createElement(TextTransition, { className: "".concat(valueSize, " text-white whitespace-nowrap [&>*]:whitespace-nowrap ").concat(valueClassName !== null && valueClassName !== void 0 ? valueClassName : "", " ").concat(center ? "m-auto" : "", " ").concat(monospace ? "font-mono" : ""), springConfig: presets.stiff }, isTimeInSeconds && secondValue
                ? getTimeTextFromSeconds(secondValue !== null && secondValue !== void 0 ? secondValue : 0)
                : secondValue
                    ? prefix + FormatNumber(secondValue, numberFormatOptions) + suffix
                    : secondValue),
            React.createElement(TextTransition, { className: "".concat(valueSize, " text-white whitespace-nowrap [&>*]:whitespace-nowrap ").concat(valueClassName !== null && valueClassName !== void 0 ? valueClassName : "", " ").concat(center ? "m-auto" : "", " ").concat(monospace ? "font-mono" : ""), springConfig: presets.stiff }, secondValue && "".concat(percentageChange)))));
};
