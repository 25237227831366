import React, { useCallback, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender, } from "@tanstack/react-table";
import { Auth0Fetcher } from "../Auth0Fetcher";
import { useNavigate } from "react-router-dom";
import { GetDisplayPrice } from "ee-utils";
import { HelpTooltip } from "./HelpTooltip";
// TUnused is left here because Typescript seems to blow up if there isn't a second template parameter. No idea why...
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export var TableBase = function (props) {
    var table = props.table, columnDefinitions = props.columnDefinitions, onClickRow = props.onClickRow, canClickRow = props.canClickRow, buildRowLink = props.buildRowLink, paginated = props.paginated, loading = props.loading, emptyMessage = props.emptyMessage;
    var navigate = useNavigate();
    var hasRows = table && table.getRowModel() && table.getRowModel().rows.length > 0;
    var maxFooterPages = 5;
    var canClickRowSimplified = useCallback(function (row) {
        return ((onClickRow || buildRowLink) &&
            (canClickRow == undefined || canClickRow(row)));
    }, [buildRowLink, canClickRow, onClickRow]);
    var onClickRowSimplified = useCallback(function (event, row) {
        if (canClickRowSimplified(row)) {
            if (onClickRow) {
                onClickRow(row);
                return;
            }
            else if (buildRowLink === null || buildRowLink === void 0 ? void 0 : buildRowLink(row)) {
                var rowLink = buildRowLink(row);
                // Since row level wrapping isn't supported:
                // https://github.com/TanStack/table/discussions/2336#discussioncomment-620512
                if (event.metaKey || event.ctrlKey) {
                    window.open(rowLink, "_blank");
                }
                else {
                    navigate(rowLink);
                }
            }
        }
    }, [buildRowLink, canClickRowSimplified, onClickRow]);
    return (React.createElement(React.Fragment, null,
        React.createElement("table", { className: "table-auto w-full max-w-[1150px]" },
            !hasRows && (React.createElement("tr", { className: "flex flex-row w-full items-center justify-center font-N7 text-white h-[250px]" },
                React.createElement("td", { className: "w-full text-center" }, "".concat(loading
                    ? "Loading..."
                    : emptyMessage !== null && emptyMessage !== void 0 ? emptyMessage : "No data for the selected time range. Please select a different time range.")))),
            hasRows && (React.createElement(React.Fragment, null,
                React.createElement("thead", { className: "" }, table.getHeaderGroups().map(function (headerGroup) { return (React.createElement("tr", { key: headerGroup.id }, headerGroup.headers.map(function (header, idx) { return (React.createElement("th", { key: header.id, className: "px-[32px] py-[16px] text-left font-N8 text-black-600 whitespace-nowrap", style: { width: columnDefinitions[idx].size } },
                    React.createElement("div", { className: "flex flex-row gap-x-2 items-center" },
                        header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext()),
                        columnDefinitions[idx].helpText != null && (React.createElement(HelpTooltip, { text: columnDefinitions[idx].helpText }))))); }))); })),
                React.createElement("tbody", null, table.getRowModel().rows.map(function (row, idx) { return (React.createElement("tr", { key: row.id, className: "".concat(idx % 2 == 0 ? "bg-black-1000" : "bg-black-1200", " ").concat(canClickRowSimplified(row.original) ? "cursor-pointer" : "", " ").concat(canClickRowSimplified(row.original)
                        ? "hover:bg-black-800"
                        : ""), onClick: function (event) {
                        if (canClickRowSimplified(row.original))
                            onClickRowSimplified(event, row.original);
                    } }, row.getVisibleCells().map(function (cell, cellIdx) { return (React.createElement("td", { key: cell.id, className: "text-black-400 font-N8 px-[32px] py-[16px] ".concat(cellIdx == 0 ? "rounded-l-[10px]" : "", " ").concat(cellIdx == columnDefinitions.length - 1
                        ? "rounded-r-[10px]"
                        : "", " ").concat(cellIdx == columnDefinitions.length - 2
                        ? "w-full"
                        : "whitespace-nowrap"), style: { width: columnDefinitions[cellIdx].size } }, flexRender(cell.column.columnDef.cell, cell.getContext()))); }))); })),
                React.createElement("tfoot", null, table.getFooterGroups().map(function (footerGroup) { return (React.createElement("tr", { key: footerGroup.id }, footerGroup.headers.map(function (header) { return (React.createElement("th", { key: header.id }, header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.footer, header.getContext()))); }))); }))))),
        React.createElement(PaginationComponent, { paginated: paginated !== null && paginated !== void 0 ? paginated : false, table: table, maxFooterPages: maxFooterPages })));
};
export var PaginatedTable = function (props) {
    var baseUrl = props.baseUrl, itemsPerPage = props.itemsPerPage, rowsFromResponse = props.rowsFromResponse, rowCountFromResponse = props.rowCountFromResponse, columnDefinitions = props.columnDefinitions, onClickRow = props.onClickRow, canClickRow = props.canClickRow, buildRowLink = props.buildRowLink;
    var _a = React.useState({
        pageIndex: 0,
        pageSize: itemsPerPage,
    }), _b = _a[0], pageIndex = _b.pageIndex, pageSize = _b.pageSize, setPagination = _a[1];
    var _c = useAuth0(), isAuthenticated = _c.isAuthenticated, isLoading = _c.isLoading, getAccessTokenSilently = _c.getAccessTokenSilently;
    var _d = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(baseUrl).concat(baseUrl.includes("?") ? "&" : "?", "take=").concat(pageSize, "&skip=").concat(pageSize * pageIndex),
            getAccessTokenSilently,
        ], Auth0Fetcher), data = _d.data, dataLoading = _d.isLoading;
    var columns = React.useMemo(function () { return columnDefinitions; }, [columnDefinitions]);
    var pagination = React.useMemo(function () { return ({
        pageIndex: pageIndex,
        pageSize: pageSize,
    }); }, [pageIndex, pageSize]);
    var pageCount = data ? rowCountFromResponse(data) : -1;
    if (pageCount > 0 && pageIndex >= pageCount) {
        setPagination({
            pageIndex: 0,
            pageSize: itemsPerPage,
        });
    }
    var table = useReactTable({
        data: data ? rowsFromResponse(data) : [],
        columns: columns,
        pageCount: pageCount,
        manualPagination: true,
        getCoreRowModel: getCoreRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination: pagination,
        },
    });
    return (React.createElement(TableBase, { table: table, onClickRow: onClickRow, buildRowLink: buildRowLink, canClickRow: canClickRow, paginated: true, loading: dataLoading, columnDefinitions: columnDefinitions }));
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export var StaticTable = function (props) {
    var data = props.data, columnDefinitions = props.columnDefinitions, onClickRow = props.onClickRow, canClickRow = props.canClickRow, initialSorting = props.initialSorting, loading = props.loading, emptyMessage = props.emptyMessage;
    var columns = React.useMemo(function () { return columnDefinitions; }, [columnDefinitions]);
    var initialState = useMemo(function () {
        if (!initialSorting)
            return undefined;
        return {
            sorting: initialSorting,
        };
    }, [initialSorting]);
    var table = useReactTable({
        data: data ? data : [],
        columns: columns,
        pageCount: -1,
        initialState: initialState,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (React.createElement(TableBase, { table: table, onClickRow: onClickRow, canClickRow: canClickRow, loading: loading, columnDefinitions: columnDefinitions, emptyMessage: emptyMessage }));
};
export var TransactionTable = function (_a) {
    var resourceUrl = _a.resourceUrl, buildRowLink = _a.buildRowLink, maxRows = _a.maxRows, isNoc = _a.isNoc;
    var rowsFromResponse = function (response) { return response.transactions; };
    var rowCountFromResponse = function (response) { return response.pages; };
    var columns = [
        {
            header: "Start Time",
            accessorFn: function (row) {
                return new Date(row.sessionStart).toLocaleString();
            },
        },
        {
            header: "Duration",
            accessorFn: function (row) {
                var totalSeconds = Math.floor((new Date(row.sessionEnd).getTime() -
                    new Date(row.sessionStart).getTime()) /
                    1000);
                if (totalSeconds <= 0) {
                    return "0m 0s";
                }
                var chargeSeconds = totalSeconds % 60;
                var chargeMinutes = Math.floor(totalSeconds / 60);
                return "".concat(chargeMinutes, "m ").concat(chargeSeconds, "s");
            },
        },
        {
            header: "Energy Delivered",
            accessorFn: function (row) {
                return "".concat(Number(row.energyDeliveredKwh).toFixed(2), " kWh");
            },
        },
        {
            header: "Peak Power",
            accessorFn: function (row) {
                return "".concat(Math.round(Number(row.peakPowerKw)), " kW");
            },
        },
        {
            header: "Charger ID",
            accessorFn: function (row) {
                return row.chargerFriendlyId;
            },
        },
        {
            header: "Total",
            accessorFn: function (row) {
                return "$".concat(GetDisplayPrice(row.transactionAmount, row.transactionAmountPrecision));
            },
        },
    ];
    if (isNoc) {
        columns.push({
            header: "Port ID",
            accessorFn: function (row) {
                return "".concat(row.portId);
            },
        });
    }
    return (React.createElement(PaginatedTable, { baseUrl: resourceUrl, itemsPerPage: maxRows !== null && maxRows !== void 0 ? maxRows : 10, rowsFromResponse: rowsFromResponse, rowCountFromResponse: rowCountFromResponse, columnDefinitions: columns, buildRowLink: buildRowLink }));
};
var PaginationComponent = function (_a) {
    var paginated = _a.paginated, table = _a.table, maxFooterPages = _a.maxFooterPages;
    var _b = useState(0), currentPage = _b[0], setCurrentPage = _b[1];
    var handlePageClick = function (pageIndex) {
        setCurrentPage(pageIndex);
        table.setPageIndex(pageIndex);
    };
    if (!paginated)
        return null;
    var totalPageCount = table.getPageCount();
    var basePage = Math.max(0, currentPage - Math.floor(maxFooterPages / 2));
    var maxPage = Math.min(totalPageCount, basePage + maxFooterPages);
    var pages = [];
    var _loop_1 = function (i) {
        pages.push(React.createElement("label", { key: i, className: "font-N8 text-black-400 hover:text-white cursor-pointer ".concat(currentPage === i ? "text-white" : ""), onClick: function () { return handlePageClick(i); } }, i + 1));
    };
    for (var i = basePage; i < maxPage; i++) {
        _loop_1(i);
    }
    return (React.createElement("div", { className: "mt-[16px] flex flex-row gap-x-[32px]" },
        React.createElement("label", { className: "font-N8 text-black-400 hover:text-white cursor-pointer", onClick: function () { return handlePageClick(0); } }, "Page"),
        pages,
        totalPageCount > maxFooterPages && maxPage != totalPageCount && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N8 text-black-400" }, "..."),
            React.createElement("label", { className: "font-N8 text-black-400 hover:text-white cursor-pointer ".concat(currentPage === totalPageCount - 1 ? "text-white" : ""), onClick: function () { return handlePageClick(totalPageCount - 1); } }, totalPageCount)))));
};
export default PaginationComponent;
