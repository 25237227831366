import React from "react";
import { Popover } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
export var HelpTooltip = function (_a) {
    var text = _a.text, small = _a.small, additionalDetails = _a.additionalDetails;
    return (React.createElement("div", { className: "has-tooltip" },
        React.createElement(QuestionMarkCircleIcon, { className: "".concat(small ? "w-[14px] h-[14px]" : "w-[20px] h-[20px]") }),
        React.createElement("span", { className: "tooltip rounded-[10px] bg-black-1200 border-[1px] border-white font-N8 text-white max-w-[250px] whitespace-normal p-4" },
            text,
            additionalDetails != null && (React.createElement("div", { className: "flex flex-col gap-y-2 mt-2" }, additionalDetails.map(function (t, i) { return (React.createElement("label", { className: "font-N8p5 text-white", key: i }, t)); }))))));
};
export var ExpandableHelpTooltip = function (_a) {
    var tooltip = _a.tooltip;
    return (React.createElement(Popover, { className: "relative inline" },
        React.createElement(Popover.Button, null,
            React.createElement(QuestionMarkCircleIcon, { className: "w-[20px] h-[20px]" })),
        React.createElement(Popover.Panel, { className: "absolute rounded-[10px] bg-black-1200 border-[1px] border-white font-L2 text-white w-[300px] whitespace-normal p-4" }, tooltip)));
};
