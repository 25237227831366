"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPriceNumericValue = exports.GetDisplayPrice = void 0;
/**
 * Returns a string with a displayable price, taking in to account the precision of the price provided.
 *
 * @param price The price.
 * @param pricePrecision The precision of @see price.
 * @returns The displayable price.
 */
function GetDisplayPrice(price, pricePrecision) {
    if (price == null || pricePrecision == null) {
        return "0.00";
    }
    return GetPriceNumericValue(price, pricePrecision)
        .toFixed(pricePrecision)
        .toString();
}
exports.GetDisplayPrice = GetDisplayPrice;
function GetPriceNumericValue(price, pricePrecision) {
    const priceMultiplicand = Math.pow(10, pricePrecision);
    return price / priceMultiplicand;
}
exports.GetPriceNumericValue = GetPriceNumericValue;
