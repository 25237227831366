var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { OnOffRadio, Radio } from "../Components/Radio";
import { HexColorPicker, HexAlphaColorPicker, HexColorInput, } from "react-colorful";
import { ArrowUpTrayIcon, PhotoIcon, VideoCameraIcon, ExclamationCircleIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import { useDropzone } from "react-dropzone";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { TabStyle } from "../Components/TabStyle";
import { TextInput } from "../Components/Input";
import { Dropdown } from "../Components/Dropdown";
import { StyledButton, StyledButtonStyle } from "../Components/StyledButton";
import { process } from "../Process";
import { Auth0Fetcher } from "../Auth0Fetcher";
import { LoadingIcon } from "ee-tailwind-components";
import useSWR from "swr";
import { Card, CardColors, GradientBorderCard } from "../Components/Card";
import { PenGradientIcon } from "../Components/Images";
import { SuccessIndicator } from "../Components/SuccessIndicator";
var DeployConfirmation = function (_a) {
    var onConfirm = _a.onConfirm, onClose = _a.onClose;
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-16" },
        React.createElement("label", { className: "font-N4 text-white" }, "Confirm Update"),
        React.createElement("label", { className: "font-N7 text-white max-w-[500px]" }, "You are about to update the UI profile for all chargers. These changes will take up to one minute to be reflected."),
        React.createElement("div", { className: "flex flex-row gap-4" },
            React.createElement(StyledButton, { text: "Deploy", onClick: onConfirm }),
            React.createElement(StyledButton, { text: "Cancel", onClick: onClose, style: StyledButtonStyle.Gray }))));
};
var themeComparator = function (i1, i2) {
    return (i1 === null || i1 === void 0 ? void 0 : i1.name) == (i2 === null || i2 === void 0 ? void 0 : i2.name) && (i1 === null || i1 === void 0 ? void 0 : i1.revision) == (i2 === null || i2 === void 0 ? void 0 : i2.revision);
};
var DeployProfileTab = function (_a) {
    var organizationId = _a.organizationId, readOnly = _a.readOnly;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    var themeList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/theme/list/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var _c = useState(), activeTheme = _c[0], setActiveTheme = _c[1];
    var defaultLabel = "Default Theme".concat(activeTheme == undefined ? " (currently active)" : "");
    var _d = useState(), selectedTheme = _d[0], setSelectedTheme = _d[1];
    var dropdownItems = [
        {
            name: defaultLabel,
            value: undefined,
            selectedLabel: defaultLabel,
        },
    ].concat((themeList !== null && themeList !== void 0 ? themeList : []).map(function (t) {
        var themeName = "".concat(t.name, " (").concat(new Date(t.updatedDate).toLocaleString(), ", revision ").concat(t.revision, ")");
        var label = "".concat(themeName).concat(themeComparator(activeTheme, t) ? " (currently active)" : "");
        return {
            name: label,
            selectedLabel: label,
            value: t,
        };
    }));
    var refreshActiveTheme = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, responseText, theme;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!true) return [3 /*break*/, 6];
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/theme/active/").concat(organizationId), {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    console.error("Something went wrong");
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, response.text()];
                case 4:
                    responseText = _a.sent();
                    theme = void 0;
                    if (responseText.length == 0) {
                        theme = undefined;
                    }
                    else {
                        theme = JSON.parse(responseText);
                    }
                    setActiveTheme(theme);
                    setSelectedTheme(theme);
                    return [3 /*break*/, 6];
                case 5: return [3 /*break*/, 0];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        void (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, refreshActiveTheme()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [organizationId]);
    var _e = useState(), deploySuccess = _e[0], setDeploySuccess = _e[1];
    var _f = useState(), deployMessage = _f[0], setDeployMessage = _f[1];
    var _g = useState(false), deployConfirmationOpen = _g[0], setDeployConfirmationOpen = _g[1];
    var displayDeployMessage = function (success, message) {
        setDeployMessage(message);
        setDeploySuccess(success);
        setTimeout(function () {
            setDeployMessage(undefined);
        }, 10000);
    };
    var onClickDeploy = function () {
        if (themeComparator(selectedTheme, activeTheme)) {
            displayDeployMessage(false, "This profile is currently deployed. Please select a different profile.");
            return;
        }
        setDeployConfirmationOpen(true);
    };
    var onConfirmDeploy = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDeployConfirmationOpen(false);
                    if (themeComparator(selectedTheme, activeTheme)) {
                        displayDeployMessage(false, "This profile is currently deployed. Please select a different profile.");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 7, , 8]);
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/theme/active/").concat(organizationId), {
                            method: "POST",
                            body: JSON.stringify(selectedTheme !== null && selectedTheme !== void 0 ? selectedTheme : {}),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 3:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 5];
                    displayDeployMessage(true, "Success.");
                    return [4 /*yield*/, refreshActiveTheme()];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    displayDeployMessage(false, "Something went wrong. Please try again.");
                    _a.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    e_1 = _a.sent();
                    displayDeployMessage(false, "Something went wrong. Please try again.");
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: deployConfirmationOpen, onClose: function () { return setDeployConfirmationOpen(false); }, className: "relative z-50" },
            React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
            React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                React.createElement(Dialog.Panel, { className: "mx-auto" },
                    React.createElement(DeployConfirmation, { onClose: function () { return setDeployConfirmationOpen(false); }, onConfirm: onConfirmDeploy })))),
        React.createElement("div", { className: "flex flex-col gap-y-[32px]" },
            React.createElement("label", { className: "font-N6 text-white" }, "Deploy Profile"),
            React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
                React.createElement("label", { className: "font-N8 text-black-600" }, "UI Profile"),
                React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
                React.createElement("div", { className: "flex flex-row gap-x-4" },
                    React.createElement(Dropdown, { items: dropdownItems, currentSelection: selectedTheme, onClick: setSelectedTheme, comparator: themeComparator }),
                    React.createElement(StyledButton, { text: "Deploy", onClick: onClickDeploy, disabled: readOnly })),
                React.createElement(SuccessIndicator, { message: deployMessage, success: deploySuccess })))));
};
var FilePickerIcon;
(function (FilePickerIcon) {
    FilePickerIcon[FilePickerIcon["Video"] = 0] = "Video";
    FilePickerIcon[FilePickerIcon["Image"] = 1] = "Image";
})(FilePickerIcon || (FilePickerIcon = {}));
var FilePicker = function (_a) {
    var title = _a.title, organizationId = _a.organizationId, maxFileSizeMB = _a.maxFileSizeMB, uploadUrlPath = _a.uploadUrlPath, selectedFileId = _a.selectedFileId, setSelectedFileId = _a.setSelectedFileId, icon = _a.icon, acceptedFileTypes = _a.acceptedFileTypes;
    var _b = useAuth0(), isLoading = _b.isLoading, isAuthenticated = _b.isAuthenticated, getAccessTokenSilently = _b.getAccessTokenSilently;
    var _c = useState(false), activeRequest = _c[0], setActiveRequest = _c[1];
    var _d = useState(), fileUploadSuccess = _d[0], setFileUploadSuccess = _d[1];
    var _e = useState(), fileUploadMessage = _e[0], setFileUploadMessage = _e[1];
    var fileMetadata = useSWR(!selectedFileId || isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.BIG_FILE_SVC_URL, "/themes/file/metadata/").concat(organizationId, "/").concat(selectedFileId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var displayErrorMessage = function (message) {
        setFileUploadMessage(message);
        setFileUploadSuccess(false);
        setTimeout(function () {
            setFileUploadSuccess(undefined);
        }, 10000);
    };
    var _f = useDropzone({
        accept: acceptedFileTypes,
        onDrop: function (files) { return __awaiter(void 0, void 0, void 0, function () {
            var file, accessToken, formData, response, fileId, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file = files[0];
                        if (file.size / (1000 * 1000) > maxFileSizeMB) {
                            displayErrorMessage("File is too large.");
                            return [2 /*return*/];
                        }
                        setActiveRequest(true);
                        return [4 /*yield*/, getAccessTokenSilently({
                                authorizationParams: {
                                    audience: process.env.AUTH0_AUDIENCE,
                                },
                            })];
                    case 1:
                        accessToken = _a.sent();
                        formData = new FormData();
                        formData.append("file", file);
                        formData.append("fileName", file.name);
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 7, , 8]);
                        return [4 /*yield*/, fetch("".concat(process.env.BIG_FILE_SVC_URL, "/themes/").concat(uploadUrlPath, "/").concat(organizationId), {
                                method: "POST",
                                body: formData,
                                headers: {
                                    authorization: "Bearer ".concat(accessToken),
                                },
                            })];
                    case 3:
                        response = _a.sent();
                        if (!response.ok) return [3 /*break*/, 5];
                        return [4 /*yield*/, response.text()];
                    case 4:
                        fileId = _a.sent();
                        setSelectedFileId(fileId);
                        return [3 /*break*/, 6];
                    case 5:
                        if (response.status == 413) {
                            displayErrorMessage("File is too large.");
                        }
                        else {
                            displayErrorMessage("Something went wrong. Please try again.");
                        }
                        _a.label = 6;
                    case 6:
                        setActiveRequest(false);
                        return [3 /*break*/, 8];
                    case 7:
                        e_2 = _a.sent();
                        displayErrorMessage("Something went wrong. Please try again.");
                        setActiveRequest(false);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); },
    }), getRootProps = _f.getRootProps, getInputProps = _f.getInputProps;
    var iconStyles = "w-[28px] h-[28px]";
    return (React.createElement("div", { className: "flex flex-col gap-y-4" },
        React.createElement("label", { className: "font-N7 text-black-600" }, title),
        React.createElement("div", __assign({ className: "bg-black-1000 rounded-[5px] grid" }, getRootProps()),
            React.createElement("div", { className: "overlap-grid flex flex-row w-fit gap-x-4 items-center px-8 py-6 transition-opacity ".concat(activeRequest ? "opacity-0" : "opacity-100") },
                React.createElement("input", __assign({}, getInputProps())),
                icon == FilePickerIcon.Image && React.createElement(PhotoIcon, { className: iconStyles }),
                icon == FilePickerIcon.Video && (React.createElement(VideoCameraIcon, { className: iconStyles })),
                React.createElement("div", { className: "flex flex-col mr-16 gap-y-1" },
                    React.createElement("label", { className: "font-N8p5" }, selectedFileId && fileMetadata
                        ? fileMetadata.name
                        : "No file selected"),
                    React.createElement("label", { className: "font-N9" }, "".concat(maxFileSizeMB, "MB MAX FILE SIZE, ").concat(Object.values(acceptedFileTypes)))),
                React.createElement(ArrowUpTrayIcon, { className: iconStyles })),
            React.createElement("div", { className: "overlap-grid w-full h-full flex flex-row items-center justify-center transition-opacity ".concat(!activeRequest ? "opacity-0" : "opacity-100") },
                React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon))),
        React.createElement(SuccessIndicator, { success: fileUploadSuccess, message: fileUploadMessage })));
};
var ColorPicker = function (_a) {
    var value = _a.value, setValue = _a.setValue, description = _a.description, alpha = _a.alpha;
    return (React.createElement("div", { className: "flex flex-col gap-y-4" },
        React.createElement("label", { className: "text-black-600 font-N7" }, description),
        !alpha && React.createElement(HexColorPicker, { color: value, onChange: setValue }),
        alpha && React.createElement(HexAlphaColorPicker, { color: value, onChange: setValue }),
        React.createElement(HexColorInput, { color: value, onChange: setValue, className: "px-3 py-3 rounded-[7px]", alpha: alpha })));
};
var ThemeCustomization = function (_a) {
    var organizationId = _a.organizationId, theme = _a.theme, setSelectedTheme = _a.setSelectedTheme, dropdownItems = _a.dropdownItems, readOnly = _a.readOnly, isNoc = _a.isNoc;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var iframeRef = useRef(null);
    var chargerStateRadioItems = [
        {
            name: "Available",
            value: "available",
        },
        {
            name: "Unavailable",
            value: "unavailable",
        },
        {
            name: "Emergency Stop Pressed",
            value: "estop",
        },
    ];
    var _b = useState(chargerStateRadioItems[0].value), chargerState = _b[0], setChargerState = _b[1];
    var updateChargerState = function (newState) {
        var _a;
        setChargerState(newState);
        if (iframeRef.current) {
            (_a = iframeRef.current.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
                testChargerState: newState,
            });
        }
    };
    var portStateRadioItems = [
        {
            name: "Available",
            value: "available",
        },
        {
            name: "Available (Car Plugged In)",
            value: "availablewithplug",
        },
        {
            name: "Available (Payment Ready)",
            value: "availablewithpayment",
        },
        {
            name: "Preparing Session",
            value: "preparing",
        },
        {
            name: "Charging",
            value: "charging",
        },
        {
            name: "Unavailable",
            value: "unavailable",
        },
    ];
    var _c = useState(portStateRadioItems[0].value), port1State = _c[0], setPort1State = _c[1];
    var _d = useState(portStateRadioItems[0].value), port2State = _d[0], setPort2State = _d[1];
    var setPortState = function (portId, state) {
        var _a;
        if (portId == 1) {
            setPort1State(state);
        }
        else {
            setPort2State(state);
        }
        var debugMessage = {
            testPortState: state,
            testPort: portId,
        };
        if (iframeRef.current) {
            (_a = iframeRef.current.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage(debugMessage);
        }
    };
    var layoutRadioItems = [
        {
            name: "Small Screen (FC150K)",
            value: "SMALL_SCREEN",
        },
        {
            name: "Large Screen (FC200K)",
            value: "EXPAND_PORTS",
        },
    ];
    var _e = useState(layoutRadioItems[0].value), screenLayout = _e[0], setScreenLayout = _e[1];
    var updateScreenLayout = function (layout) {
        var _a;
        setScreenLayout(layout);
        var debugMessage = {
            testLayout: layout,
        };
        if (iframeRef.current) {
            (_a = iframeRef.current.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage(debugMessage);
        }
    };
    var _f = useState("#aabbcc"), primaryBrandColor = _f[0], setPrimaryBrandColor = _f[1];
    var _g = useState("#aabbccff"), secondaryBrandColor = _g[0], setSecondaryBrandColor = _g[1];
    var _h = useState("#aabbcc"), chargeGaugeBrandColor = _h[0], setChargeGaugeBrandColor = _h[1];
    var _j = useState(), selectedLogoFileId = _j[0], setSelectedLogoFileId = _j[1];
    var _k = useState(), selectedBackgroundFileId = _k[0], setSelectedBackgroundFileId = _k[1];
    var _l = useState(), selectedVideoFileId = _l[0], setSelectedVideoFileId = _l[1];
    var refreshTheme = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, details;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    if (!true) return [3 /*break*/, 6];
                    if (theme == undefined) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _g.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/theme/details/").concat(organizationId, "/").concat(theme.name, "/").concat(theme.revision), {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _g.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    console.error("Something went wrong");
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, response.json()];
                case 4:
                    details = _g.sent();
                    setPrimaryBrandColor((_b = (_a = details.theme) === null || _a === void 0 ? void 0 : _a.brandColor) !== null && _b !== void 0 ? _b : "#aabbcc");
                    setSecondaryBrandColor((_d = (_c = details.theme) === null || _c === void 0 ? void 0 : _c.secondaryBrandColor) !== null && _d !== void 0 ? _d : "#aabbccff");
                    setChargeGaugeBrandColor((_f = (_e = details.theme) === null || _e === void 0 ? void 0 : _e.gaugeColor) !== null && _f !== void 0 ? _f : "#aabbcc");
                    setSelectedLogoFileId(details.primaryLogoImageFileId);
                    setSelectedBackgroundFileId(details.primaryBackgroundImageFileId);
                    setSelectedVideoFileId(details.primaryVideoFileId);
                    return [3 /*break*/, 6];
                case 5: return [3 /*break*/, 0];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        void (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, refreshTheme()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [organizationId, theme]);
    // IFrame's don't automatically resize the outer container to the inner container's contents. This makes sure that the size stays in sync. Super lame.
    var updateIFrameSize = function () {
        var _a;
        if (iframeRef.current) {
            var rootElement = (_a = iframeRef.current.contentWindow) === null || _a === void 0 ? void 0 : _a.document.getElementById("root");
            if (rootElement != null) {
                iframeRef.current.width = "".concat(rootElement.scrollWidth, "px");
                iframeRef.current.height = "".concat(rootElement.scrollHeight, "px");
            }
        }
    };
    var _m = useState(false), debugMode = _m[0], setDebugMode = _m[1];
    var updateIFrame = function () {
        var _a;
        var themeMessage = {
            setTheme: {
                theme: {
                    primaryBrandColorHex: primaryBrandColor,
                    secondaryBrandColorHex: secondaryBrandColor,
                    chargeGaugeBrandColorHex: chargeGaugeBrandColor,
                    logoImageUrl: selectedLogoFileId
                        ? "".concat(process.env.BIG_FILE_SVC_URL, "/themes/file/").concat(organizationId, "/").concat(selectedLogoFileId)
                        : undefined,
                    backgroundImageUrl: selectedBackgroundFileId
                        ? "".concat(process.env.BIG_FILE_SVC_URL, "/themes/file/").concat(organizationId, "/").concat(selectedBackgroundFileId)
                        : undefined,
                    videoContentUrl: selectedVideoFileId
                        ? "".concat(process.env.BIG_FILE_SVC_URL, "/themes/file/").concat(organizationId, "/").concat(selectedVideoFileId)
                        : undefined,
                },
            },
        };
        if (iframeRef.current) {
            (_a = iframeRef.current.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage(themeMessage);
        }
    };
    useEffect(function () {
        var interval = setInterval(function () {
            updateIFrameSize();
            updateIFrame();
        }, 2000);
        return function () { return clearInterval(interval); };
    });
    useEffect(function () {
        updateIFrame();
    }, [
        primaryBrandColor,
        secondaryBrandColor,
        chargeGaugeBrandColor,
        selectedBackgroundFileId,
        selectedVideoFileId,
    ]);
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, saveTheme, response, theme_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (theme == undefined) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    saveTheme = {
                        theme: {
                            brandColor: primaryBrandColor,
                            secondaryBrandColor: secondaryBrandColor,
                            gaugeColor: chargeGaugeBrandColor,
                        },
                        primaryLogoImageFileId: selectedLogoFileId,
                        primaryBackgroundImageFileId: selectedBackgroundFileId,
                        primaryVideoFileId: selectedVideoFileId,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/theme/save/").concat(organizationId, "/").concat(theme === null || theme === void 0 ? void 0 : theme.name), {
                            method: "POST",
                            body: JSON.stringify(saveTheme),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    console.error("Something went wrong.");
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, response.json()];
                case 4:
                    theme_1 = _a.sent();
                    setSelectedTheme(theme_1);
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-16" },
        React.createElement("div", { className: "flex flex-row gap-x-2" },
            React.createElement(Dropdown, { items: dropdownItems, currentSelection: theme, onClick: setSelectedTheme, comparator: themeComparator }),
            React.createElement("div", null),
            React.createElement(StyledButton, { text: "Save Changes", onClick: onSave, disabled: readOnly || theme == null }),
            React.createElement(StyledButton, { text: "Discard Changes", onClick: refreshTheme, disabled: readOnly || theme == null, style: StyledButtonStyle.Gray })),
        theme != null && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N6p5 text-black-600" }, "Editing \"".concat(theme.name, ", revision ").concat(theme.revision, "\"")),
            React.createElement("div", { className: "flex flex-row flex-wrap gap-4" },
                React.createElement(ColorPicker, { value: primaryBrandColor, setValue: setPrimaryBrandColor, description: "Primary brand color" }),
                React.createElement(ColorPicker, { value: secondaryBrandColor, setValue: setSecondaryBrandColor, description: "Secondary brand color", alpha: true }),
                React.createElement(ColorPicker, { value: chargeGaugeBrandColor, setValue: setChargeGaugeBrandColor, description: "Charge gauge color" })),
            !readOnly && (React.createElement("div", { className: "flex flex-col gap-y-4" },
                React.createElement("div", { className: "flex flex-row gap-x-4 flex-wrap" },
                    React.createElement(FilePicker, { title: "Logo", organizationId: organizationId, maxFileSizeMB: 4, selectedFileId: selectedLogoFileId, setSelectedFileId: setSelectedLogoFileId, icon: FilePickerIcon.Image, uploadUrlPath: "logo", acceptedFileTypes: { "image/png": [".png"] } }),
                    React.createElement(FilePicker, { title: "Background Image", organizationId: organizationId, maxFileSizeMB: 4, selectedFileId: selectedBackgroundFileId, setSelectedFileId: setSelectedBackgroundFileId, icon: FilePickerIcon.Image, uploadUrlPath: "background_image", acceptedFileTypes: { "image/png": [".png"] } }),
                    React.createElement(FilePicker, { title: "Video Content", organizationId: organizationId, maxFileSizeMB: 50, selectedFileId: selectedVideoFileId, setSelectedFileId: setSelectedVideoFileId, icon: FilePickerIcon.Video, uploadUrlPath: "video", acceptedFileTypes: { "video/mp4": [".mp4"] } })),
                React.createElement("label", { className: "font-N8p5 text-black-600" },
                    "By uploading content, you agree to our",
                    " ",
                    React.createElement("a", { className: "text-white hover:text-white text-clip whitespace-nowrap", rel: "noopener noreferrer", href: "https://eestaticstore1.blob.core.windows.net/docs/PowerNode_Command_Console_Privacy_Policy_2023-02-15.pdf", target: "_blank" }, "Privacy Policy"),
                    "."))),
            React.createElement("label", { className: "font-N6 text-white" }, "Profile Preview"),
            React.createElement("div", { className: "grid grid-cols-[auto_auto] w-fit gap-x-6 gap-y-4 items-center" },
                React.createElement("label", { className: "font-N7 text-black-600 w-fit" }, "Screen Layout"),
                React.createElement(Radio, { items: layoutRadioItems, value: screenLayout, setValue: updateScreenLayout }),
                React.createElement("label", { className: "font-N7 text-black-600 w-fit" }, "Charger State"),
                React.createElement(Radio, { items: chargerStateRadioItems, value: chargerState, setValue: updateChargerState }),
                React.createElement("label", { className: "font-N7 text-black-600 w-fit" }, "Left Port"),
                React.createElement(Radio, { items: portStateRadioItems, value: port1State, setValue: function (s) { return setPortState(1, s); } }),
                React.createElement("label", { className: "font-N7 text-black-600 w-fit" }, "Right Port"),
                React.createElement(Radio, { items: portStateRadioItems, value: port2State, setValue: function (s) { return setPortState(2, s); } })),
            React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                React.createElement("label", { className: "text-black-400 font-N8p5" }, "Enable Debug Mode"),
                isNoc && React.createElement(OnOffRadio, { on: debugMode, setOn: setDebugMode })),
            React.createElement("iframe", { src: "/charger-ui/index.html?debug".concat(debugMode ? "" : "&hideDebugMenu"), ref: iframeRef, className: "rounded-[10px] mb-8", onLoad: function () { return updateIFrame(); } })))));
};
var EditProfileTab = function (_a) {
    var organizationId = _a.organizationId, readOnly = _a.readOnly, isNoc = _a.isNoc;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    var themeList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/theme/list/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var dropdownItems = [
        {
            name: "Select a Theme",
            value: undefined,
            selectedLabel: "Select a Theme",
        },
    ].concat((themeList !== null && themeList !== void 0 ? themeList : []).map(function (t) {
        var themeName = "".concat(t.name, " (").concat(new Date(t.updatedDate).toLocaleString(), ", revision ").concat(t.revision, ")");
        return {
            name: themeName,
            selectedLabel: themeName,
            value: t,
        };
    }));
    var _c = useState(), selectedTheme = _c[0], setSelectedTheme = _c[1];
    var profileNameTextbox = useRef(null);
    var _d = useState(true), profileCreatedSuccess = _d[0], setProfileCreatedSuccess = _d[1];
    var _e = useState(), profileCreatedMessage = _e[0], setProfileCreatedMessage = _e[1];
    var createProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
        var textInput, accessToken, createTheme, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    textInput = (_a = profileNameTextbox.current) === null || _a === void 0 ? void 0 : _a.value;
                    if (!textInput) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _b.sent();
                    createTheme = {
                        name: textInput,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/theme/create/").concat(organizationId), {
                            method: "POST",
                            body: JSON.stringify(createTheme),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _b.sent();
                    if (!response.ok) {
                        console.error("Something went wrong.");
                        setProfileCreatedMessage("Something went wrong. Please try again.");
                    }
                    else {
                        profileNameTextbox.current.value = "";
                        setProfileCreatedMessage("Profile created.");
                    }
                    setProfileCreatedSuccess(response.ok);
                    setTimeout(function () {
                        setProfileCreatedSuccess(undefined);
                    }, 10000);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px]" },
        React.createElement("label", { className: "font-N6 text-white" }, "Create a New Profile"),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N8 text-white" },
            "Create a new profile below. Once your new profile is created,",
            React.createElement("br", null),
            "edit it by selecting it in the \"Edit Profile\" dropdown menu."),
        React.createElement("div", { className: "flex flex-row gap-x-4" },
            React.createElement(TextInput, { placeholder: "Profile Name", className: "w-fit", ref: profileNameTextbox }),
            React.createElement(StyledButton, { text: "Create Profile", onClick: createProfile, disabled: readOnly })),
        React.createElement(SuccessIndicator, { success: profileCreatedSuccess, message: profileCreatedMessage }),
        React.createElement("label", { className: "font-N6 text-white" }, "Edit Profile"),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N8 text-white" },
            "Select a profile to begin editing. Saved changes will not affect live",
            React.createElement("br", null),
            "chargers until they are deployed on the \"Deploy Profile\" tab."),
        React.createElement("label", { className: "font-N7 text-black-600" }, "Select a profile to edit"),
        React.createElement(ThemeCustomization, { organizationId: organizationId, theme: selectedTheme, setSelectedTheme: setSelectedTheme, dropdownItems: dropdownItems, readOnly: readOnly, isNoc: isNoc })));
};
// Customization list page.
export var Customize = function (_a) {
    var _b;
    var organizationId = _a.organizationId, readOnly = _a.readOnly, isNoc = _a.isNoc;
    var opacityTransitionProperties = {
        enter: "transition-opacity duration-500 pointer-events-none",
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leave: "transition-opacity duration-300 pointer-events-none",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
    };
    var localStorageKey = "showCustomChargerUIPopup";
    var _c = useState(JSON.parse((_b = localStorage.getItem(localStorageKey)) !== null && _b !== void 0 ? _b : "true")), showCustomChargerUIPopup = _c[0], setShowCustomChargerUIPopup = _c[1];
    var onCloseClicked = function () {
        localStorage.setItem(localStorageKey, "false");
        setShowCustomChargerUIPopup(false);
    };
    return (React.createElement("div", { className: "flex flex-col gap-y-[64px] mt-[64px]" },
        React.createElement("label", { className: "font-N4 text-white" }, "Customize Charger UI"),
        React.createElement(Transition, __assign({ className: "w-fit" }, opacityTransitionProperties, { show: showCustomChargerUIPopup }),
            React.createElement(GradientBorderCard, { colors: CardColors.GRADIENT, outerClassName: "w-fit" },
                React.createElement(Card, { spotlights: [], className: "flex flex-row w-fit gap-x-64" },
                    React.createElement("div", { className: "flex flex-col w-fit gap-y-4" },
                        React.createElement("div", { className: "flex flex-row gap-x-4" },
                            PenGradientIcon,
                            React.createElement("label", { className: "font-N6 text-white" }, "Custom Charger UI")),
                        React.createElement("div", { className: "flex flex-col gap-y-2" },
                            React.createElement("label", { className: "font-N7 text-white" }, "Interested in having one of our designers create a custom experience for your chargers?"),
                            React.createElement("label", { className: "font-N8 text-white" }, "We're here to help! Please contact your sales representative for more information."))),
                    React.createElement(XMarkIcon, { className: "h-[28px] w-[28px] text-white hover:text-black-400 cursor-pointer", onClick: onCloseClicked })))),
        readOnly && (React.createElement("div", { className: "flex flex-row gap-x-2 items-center" },
            React.createElement(ExclamationCircleIcon, { className: "text-caution-yellow h-[24px] w-[24px]" }),
            React.createElement("label", { className: "font-N8 text-white" }, "Your account is marked as read-only. Saving is disabled. Please contact your account administrator to change this."))),
        React.createElement(Tab.Group, null,
            React.createElement(Tab.List, { className: "flex flex-row gap-x-[16px] mb-[64px]" },
                React.createElement(Tab, { className: function (_a) {
                        var selected = _a.selected;
                        return TabStyle(selected);
                    } }, "Create & Edit Profiles"),
                React.createElement(Tab, { className: function (_a) {
                        var selected = _a.selected;
                        return TabStyle(selected);
                    } }, "Deploy Profile")),
            React.createElement(Tab.Panels, null,
                React.createElement(Tab.Panel, { unmount: false },
                    React.createElement(EditProfileTab, { organizationId: organizationId, readOnly: readOnly, isNoc: isNoc })),
                React.createElement(Tab.Panel, null,
                    React.createElement(DeployProfileTab, { organizationId: organizationId, readOnly: readOnly }))))));
};
