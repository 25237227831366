"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MovingAverage = void 0;
class MovingAverage {
    constructor(windowSize, initialValue = 0) {
        this.windowSize = windowSize;
        this.values = [];
        this.sum = 0;
        this.initialValue = initialValue;
        // If the number of values is less than the window size, backfill with the initial value.
        while (this.values.length < this.windowSize) {
            this.values.unshift(this.initialValue);
            this.sum += this.initialValue;
        }
    }
    add(value) {
        // Add the new value to the values array.
        this.values.push(value);
        // Add the new value to the sum
        this.sum += value;
        // If the number of values exceeds the window size, remove the oldest value.
        if (this.values.length > this.windowSize) {
            const removedValue = this.values.shift() || 0;
            // Subtract the removed value from the sum.
            this.sum -= removedValue;
        }
    }
    get() {
        // Calculate the moving average.
        return this.sum / this.windowSize;
    }
}
exports.MovingAverage = MovingAverage;
