import React, { useState } from "react";
import { DateRangeSelector, TimeHorizon, getSubDateRanges } from "../DateRange";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { Auth0FetcherWithBody } from "../../Auth0Fetcher";
import { GetDisplayPrice } from "ee-utils";
import { StaticTable } from "../Table";
import { LineGraph } from "../Graph/LineGraph";
export var Revenue = function (_a) {
    var feeResourceUrl = _a.feeResourceUrl, earliestTransaction = _a.earliestTransaction;
    var _b = useState(TimeHorizon.DAY), timeHorizon = _b[0], setTimeHorizon = _b[1];
    var _c = useState([
        new Date(),
        new Date(),
    ]), dateRange = _c[0], setDateRange = _c[1];
    var dateRanges = getSubDateRanges(timeHorizon, dateRange[0]);
    var _d = useAuth0(), isAuthenticated = _d.isAuthenticated, isLoading = _d.isLoading, getAccessTokenSilently = _d.getAccessTokenSilently;
    var _e = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            feeResourceUrl,
            getAccessTokenSilently,
            dateRanges.map(function (r) { return [r[0], r[1]]; }),
        ], Auth0FetcherWithBody, {
        refreshInterval: 30 * 1000,
    }), fees = _e.data, feesLoading = _e.isLoading;
    var hasFees = !feesLoading && fees && fees.length > 0;
    var cumulativeSum = (function (sum) { return function (value) {
        return (sum += value);
    }; })(0);
    var revenueCumulative = hasFees
        ? fees
            .map(function (r) { return (r.completions > 0 ? r.revenueAmount : 0); })
            .map(cumulativeSum)
        : [];
    var precision = hasFees
        ? Math.max.apply(Math, fees.map(function (r) { return r.revenueAmountPrecision; })) : 0;
    var revenueDatapoints = hasFees
        ? fees.map(function (r, i) {
            return {
                label: i < dateRanges.length ? dateRanges[i][2] : "",
                time: i,
                revenue: revenueCumulative[i],
            };
        })
        : [];
    var revenueLabelAccessor = function (d) { return d.label; };
    var revenueValueAccessors = [
        {
            label: "Revenue",
            accessor: function (d) { return (d ? d.revenue : 0); },
            precision: 1,
        },
    ];
    var tableData = (hasFees ? fees : []).map(function (f, i) {
        return {
            time: i < dateRanges.length ? dateRanges[i][2] : "",
            sessions: "".concat(f.completions > 0 ? f.completions : "-"),
            revenue: "".concat(f.completions > 0
                ? "$".concat(GetDisplayPrice(f.revenueAmount, f.revenueAmountPrecision > 0 ? f.revenueAmountPrecision : 2))
                : "-"),
            authfees: "".concat(f.authorizationCount > 0
                ? "$".concat(GetDisplayPrice(f.authorizationCost, f.authorizationCostPrecision))
                : "-"),
            intfees: "".concat(f.interchangeCost > 0
                ? "$".concat(GetDisplayPrice(f.interchangeCost, f.interchangeCostPrecision))
                : "-"),
        };
    });
    var columns = [
        {
            header: "Time",
            accessorKey: "time",
        },
        {
            header: "Sessions",
            accessorKey: "sessions",
        },
        {
            header: "Est. Authorization Fees",
            accessorKey: "authfees",
            helpText: "Authorization fees are estimated based on the number of credit card pre-authorizations and completed transactions.".concat(hasFees ? " Each authorization costs 6 cents." : ""),
        },
        {
            header: "Est. Interchange Fees",
            accessorKey: "intfees",
            helpText: "Electric Era estimates interchange fees based on the total transaction volume and an estimate of industry-average interchange fees.".concat(hasFees
                ? " Your estimated interchange fees are ".concat(fees[0].interchangePercentEstimate * 100, "%.")
                : ""),
        },
        {
            header: "Revenue",
            accessorKey: "revenue",
        },
    ];
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px]" },
        React.createElement(DateRangeSelector, { dateRange: dateRange, setDateRange: setDateRange, firstDate: new Date(earliestTransaction), onHorizonChanged: setTimeHorizon, hideAll: true }),
        feesLoading && (React.createElement("div", { className: "w-full h-[400px] flex flex-col items-center justify-center" },
            React.createElement("label", { className: "font-N5 text-white" }, "Loading..."))),
        !feesLoading && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "max-w-[1150px]" },
                React.createElement(LineGraph, { data: revenueDatapoints, labelAccessor: revenueLabelAccessor, valueAccessors: revenueValueAccessors, yAxisValueToLabel: function (v) { return "$".concat(GetDisplayPrice(v, precision)); } })),
            React.createElement(StaticTable, { data: tableData, columnDefinitions: columns, loading: feesLoading })))));
};
