var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { process } from "../Process";
import { Auth0Fetcher } from "../Auth0Fetcher";
import { useNavigate, useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { NOCStationControls } from "../Components/Tabs/NocStationControls";
import { NOCCommands } from "../Components/Tabs/NocCommands";
import { NOCPaymentMismatches } from "../Components/Tabs/NocPaymentMismatches";
import { StationOverview } from "../Components/Tabs/StationOverview";
import { BackButton } from "../Components/BackButton";
import { Revenue } from "../Components/Tabs/Revenue";
import { Transactions } from "../Components/Tabs/Transactions";
import { Usage } from "../Components/Tabs/Usage";
import { Pricing } from "../Components/Tabs/Pricing";
import { TabStyle } from "../Components/TabStyle";
import { BigCircle } from "../Components/Images";
import { StationPower } from "../Components/Tabs/StationPower";
import { SiteSettingsTab } from "../Components/Tabs/SiteSettings";
import { Alerts } from "../Components/Tabs/Alerts";
var NOCTelemetry = function (_a) {
    var details = _a.details;
    var startTimeRef = useRef(null);
    var endTimeRef = useRef(null);
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var retrieveNationalGridReport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var startTime, endTime, query, accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    startTime = startTimeRef.current.value;
                    endTime = endTimeRef.current.value;
                    query = "".concat(process.env.NOC_SVC_URL, "/reports/national_grid/").concat(details.id, "?from=").concat(startTime, "&to=").concat(endTime);
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    void fetch(query, {
                        headers: {
                            authorization: "Bearer ".concat(accessToken),
                        },
                    })
                        .then(function (response) { return response.blob(); })
                        .then(function (blob) {
                        var url = window.URL.createObjectURL(blob);
                        window.open(url, "_blank").focus();
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
        React.createElement("label", { className: "font-N5 text-white" }, "NOC Links"),
        details &&
            details.powernodes
                .sort(function (a, b) { return a.id - b.id; })
                .map(function (pn) {
                var _a;
                return (React.createElement("div", { key: pn.id, className: "flex flex-col gap-y-[8px]" },
                    React.createElement("label", { className: "font-N6" }, "PNID ".concat(pn.id, " (model ").concat(pn.model, ")")),
                    React.createElement("a", { href: "".concat(process.env.PN_DASHBOARD_URL).concat(pn.id), target: "_blank", className: "font-N7 text-navy-gradient-to" }, "Powernode Telemetry Grafana"),
                    React.createElement("a", { href: "".concat(process.env.CHARGER_DASHBOARD_URL).concat(pn.id), target: "_blank", className: "font-N7 text-navy-gradient-to" }, "Charger Telemetry Grafana"),
                    React.createElement("a", { href: "".concat(process.env.PN_LOGS_DASHBOARD_URL).concat(pn.id), target: "_blank", className: "font-N7 text-navy-gradient-to" }, "Powernode Logs Grafana"),
                    React.createElement("a", { href: "".concat(process.env.POWER_ANALYSIS_DASHBOARD_URL).concat(pn.id), target: "_blank", className: "font-N7 text-navy-gradient-to" }, "Power Analysis Grafana"),
                    React.createElement("a", { href: "https://dashboard.balena-cloud.com/devices/".concat((_a = pn.uuid) !== null && _a !== void 0 ? _a : ""), target: "_blank", className: "font-N7 text-navy-gradient-to" },
                        pn.uuid
                            ? "Balena (ID ".concat(pn.id, ")")
                            : "Balena Device List (UUID not specified)",
                        " ")));
            }),
        React.createElement("label", { className: "font-N6 text-white" }, "Download National Grid Report"),
        React.createElement("label", { className: "font-N8p5 text-black-400" }, "To download the report, please enter timestamps as YYYY-MM-DDTHH:MM:SSZ (UTC). Ex: 2023-08-10T19:13:12Z."),
        React.createElement("div", { className: "flex flex-row gap-x-[16px]" },
            React.createElement("label", null, "Start Time"),
            React.createElement("input", { className: "", ref: startTimeRef })),
        React.createElement("div", { className: "flex flex-row gap-x-[16px]" },
            React.createElement("label", null, "End Time"),
            React.createElement("input", { className: "", ref: endTimeRef })),
        React.createElement("button", { className: "px-[16px] py-[8px] rounded-[8px] bg-blue-700 hover:bg-blue-400 text-white w-fit", onClick: retrieveNationalGridReport }, "Download National Grid Report")));
};
// Station drilldown page.
export var Station = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var isNoc = _a.isNoc, hasWriteAccess = _a.hasWriteAccess, hasChargePointVendorAccess = _a.hasChargePointVendorAccess;
    var _h = useAuth0(), isAuthenticated = _h.isAuthenticated, isLoading = _h.isLoading, getAccessTokenSilently = _h.getAccessTokenSilently;
    var stationId = useParams().stationId;
    var navigate = useNavigate();
    var details = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/detail/").concat(stationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var tab = useParams().tab;
    var tabIndex = tab ? parseInt(tab) : undefined;
    var alerts = useSWR(!isNoc || isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/alerts/list/").concat(stationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    return (React.createElement("div", { className: "flex flex-col gap-y-[64px] p-[32px]" },
        React.createElement(BackButton, null),
        details && (React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
            React.createElement("label", { className: "font-N4 text-white" }, details.name),
            React.createElement("div", { className: "flex flex-row gap-x-[8px]" },
                BigCircle,
                React.createElement("label", { className: "font-N8 text-black-600" }, "".concat(details.activeSessions, " Active Session").concat(details.activeSessions != 1 ? "s" : ""))),
            React.createElement("label", { className: "font-N8 text-black-600 mt-[16px]" },
                details.addressLine1,
                React.createElement("br", null),
                details.addressLine2))),
        details && (React.createElement("div", null,
            React.createElement(Tab.Group, { selectedIndex: tabIndex, onChange: function (index) { return navigate("/station/".concat(stationId, "/").concat(index)); } },
                React.createElement(Tab.List, { className: "flex flex-row gap-x-[16px] mb-[64px]" },
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Overview"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Revenue"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Transactions"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Power"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Usage"),
                    hasWriteAccess && (React.createElement(React.Fragment, null,
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Pricing"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Site Settings"))),
                    isNoc && (React.createElement(React.Fragment, null,
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Payment Mismatches"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Asset Controls"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Telemetry/Reports"))),
                    (isNoc || hasChargePointVendorAccess) && (React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Commanding")),
                    isNoc && (React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return "".concat(TabStyle(selected)).concat(!selected &&
                                alerts != null &&
                                alerts.activeAlerts.length > 0
                                ? " !text-red"
                                : "");
                        } }, "Alerts".concat(alerts != null && alerts.activeAlerts.length > 0
                        ? " (".concat(alerts.activeAlerts.length, ")")
                        : "")))),
                React.createElement(Tab.Panels, null,
                    React.createElement(Tab.Panel, null,
                        React.createElement(StationOverview, { details: details, isNoc: isNoc, hasWriteAccess: hasWriteAccess })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(Revenue, { resourceUrl: "".concat(process.env.CORE_SVC_URL, "/transactions/revenue/").concat(details.id), feeResourceUrl: "".concat(process.env.CORE_SVC_URL, "/transactions/fees/").concat(details.id), earliestTransaction: (_b = details.onlineSince) !== null && _b !== void 0 ? _b : new Date() })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(Transactions, { resourceUrlBase: "".concat(process.env.CORE_SVC_URL, "/transactions/site/").concat(details.id), earliestTransaction: (_c = details.onlineSince) !== null && _c !== void 0 ? _c : new Date(), isNoc: isNoc })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(StationPower, { details: details, isNoc: isNoc, startDate: (_d = details.onlineSince) !== null && _d !== void 0 ? _d : new Date() })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(Usage, { resourceUrl: "".concat(process.env.CORE_SVC_URL, "/site/").concat(details.id, "/stats"), startDate: (_f = (_e = details.expectedDateOnline) !== null && _e !== void 0 ? _e : details.onlineSince) !== null && _f !== void 0 ? _f : new Date() })),
                    hasWriteAccess && (React.createElement(React.Fragment, null,
                        React.createElement(Tab.Panel, null,
                            React.createElement(Pricing, { details: details })),
                        React.createElement(Tab.Panel, null,
                            React.createElement(SiteSettingsTab, { details: details })))),
                    isNoc && (React.createElement(React.Fragment, null,
                        React.createElement(Tab.Panel, null,
                            React.createElement(NOCPaymentMismatches, { earliestTransaction: (_g = details.onlineSince) !== null && _g !== void 0 ? _g : new Date(), siteId: details.id })),
                        React.createElement(Tab.Panel, null,
                            React.createElement(NOCStationControls, { details: details })),
                        React.createElement(Tab.Panel, null,
                            React.createElement(NOCTelemetry, { details: details })))),
                    (isNoc || hasChargePointVendorAccess) && (React.createElement(Tab.Panel, null,
                        React.createElement(NOCCommands, { details: details, isNoc: isNoc, hasChargePointVendorAccess: hasChargePointVendorAccess }))),
                    isNoc && (React.createElement(Tab.Panel, null,
                        React.createElement(Alerts, { details: details, alerts: alerts })))))))));
};
