"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOCPPVendorErrorDetails = void 0;
function GetOCPPVendorErrorDetails(vendorError) {
    switch (vendorError) {
        case "14":
        case "16":
        case "25":
        case "38":
        case "40":
        case "42":
        case "44":
        case "45":
        case "76":
        case "109":
        case "114":
            return {
                commandConsoleDescription: `This charging session had a fault when starting the charger. This is often caused by a loose connector or malfunctioning adapter. Retrying the charging session often resolves it.${vendorError == "76" || vendorError == "44"
                    ? ` This error can also be caused by Teslas that lack hardware which enables charging at CCS stations.`
                    : ""}`,
                chargerUIDescription: "The charge session failed to start. Please verify that the plug is secure and try again.",
            };
        case "13":
            return {
                commandConsoleDescription: "This charging session ended due to the emergency stop being pressed.",
                chargerUIDescription: "The charge session ended early due to the emergency stop being pressed. To restart your session, please unplug your car and plug it back in.",
            };
        case "21":
        case "39":
            return {
                commandConsoleDescription: "This charging session had a fault when starting the charger. This error is often caused by the vehicle refusing to start the session due to its charge limit being set below its current state of charge.",
                chargerUIDescription: "The charge session failed to start. Please verify that your car's charge limit is below its battery level and try again.",
            };
        case "61":
            return undefined;
    }
    return {
        commandConsoleDescription: "This charging session encountered a fault. Electric Era monitors faults in every charging station and investigates each instance to make sure your station is operating reliably.",
        chargerUIDescription: "The charge session has ended early. Please refer to your vehicle's onboard screen or owners manual for more information.",
    };
}
exports.GetOCPPVendorErrorDetails = GetOCPPVendorErrorDetails;
