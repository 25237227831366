var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Fragment, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { process } from "../Process";
import { Auth0Fetcher } from "../Auth0Fetcher";
import { UserTags, } from "ee-nestjs-interfaces";
import { Dropdown } from "../Components/Dropdown";
import { Dialog } from "@headlessui/react";
import { StyledButton, StyledButtonStyle } from "../Components/StyledButton";
import { TextInput } from "../Components/Input";
import { SuccessIndicator } from "../Components/SuccessIndicator";
import * as EmailValidator from "email-validator";
import { LoadingIcon } from "ee-tailwind-components";
var UserType;
(function (UserType) {
    UserType["ADMIN"] = "Administrator";
    UserType["NORMAL"] = "Normal";
    UserType["READ_ONLY"] = "Read Only";
    UserType["CHARGE_POINT_VENDOR"] = "Charge Point Vendor";
})(UserType || (UserType = {}));
var tagsToUserType = function (tags) {
    if (tags.find(function (t) { return t == UserTags.TAG_ADMIN; })) {
        return UserType.ADMIN;
    }
    else if (tags.find(function (t) { return t == UserTags.TAG_READ_ONLY; })) {
        return UserType.READ_ONLY;
    }
    else if (tags.find(function (t) { return t == UserTags.TAG_CHARGE_POINT_VENDOR; })) {
        return UserType.CHARGE_POINT_VENDOR;
    }
    else {
        return UserType.NORMAL;
    }
};
var userTypeToTags = function (type) {
    switch (type) {
        case UserType.ADMIN:
            return [UserTags.TAG_ADMIN];
        case UserType.READ_ONLY:
            return [UserTags.TAG_READ_ONLY];
        case UserType.CHARGE_POINT_VENDOR:
            return [UserTags.TAG_CHARGE_POINT_VENDOR];
        default:
            return [];
    }
};
export var PermissionsDropdown = function (_a) {
    var tags = _a.tags, onSetUserType = _a.onSetUserType, isNoc = _a.isNoc;
    var items = [
        {
            name: UserType.ADMIN,
            value: UserType.ADMIN,
        },
        {
            name: UserType.NORMAL,
            value: UserType.NORMAL,
        },
        {
            name: UserType.READ_ONLY,
            value: UserType.READ_ONLY,
        },
    ];
    if (isNoc) {
        items.push({
            name: UserType.CHARGE_POINT_VENDOR,
            value: UserType.CHARGE_POINT_VENDOR,
        });
    }
    return (React.createElement("div", { className: "grow-0" },
        React.createElement(Dropdown, { items: items, currentSelection: tagsToUserType(tags), onClick: onSetUserType })));
};
var EditUser = function (_a) {
    var userInput = _a.userInput, organizationId = _a.organizationId, close = _a.close, isNoc = _a.isNoc;
    var _b = useState(JSON.parse(JSON.stringify(userInput))), user = _b[0], setUser = _b[1];
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(), updateUserSuccess = _d[0], setUpdateUserSuccess = _d[1];
    var _e = useState(), updateUserMessage = _e[0], setUpdateUserMessage = _e[1];
    var displayMessage = function (success, message) {
        setUpdateUserMessage(message);
        setUpdateUserSuccess(success);
        setTimeout(function () {
            setUpdateUserSuccess(undefined);
        }, 10000);
    };
    var setUserProprety = function (propertyName, value) {
        user.data[propertyName] = value;
        setUser(__assign({}, user));
    };
    var setUserType = function (type) {
        user.data.tags = userTypeToTags(type);
        setUser(__assign({}, user));
    };
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, request, res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!EmailValidator.validate(user.data.emailAddress)) {
                        displayMessage(false, "Please enter a valid email address.");
                        return [2 /*return*/];
                    }
                    if (!user.data.familyName) {
                        displayMessage(false, "Please enter a family name.");
                        return [2 /*return*/];
                    }
                    if (!user.data.givenName) {
                        displayMessage(false, "Please enter a given name.");
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    request = {
                        id: user.id,
                        data: user.data,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/users/").concat(organizationId, "/update"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(request),
                        })];
                case 2:
                    res = _a.sent();
                    setLoading(false);
                    if (!res.ok) {
                        console.log("Error submitting user update: ", res.statusText);
                        displayMessage(false, "Something went wrong. Please try again.");
                        return [2 /*return*/];
                    }
                    else {
                        displayMessage(true, "User data updated.");
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    displayMessage(false, "Something went wrong. Please try again.");
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px]" },
        React.createElement("label", { className: "font-N5 text-white w-full text-center" }, "Edit User"),
        React.createElement("div", { className: "grid grid-cols-[auto_1fr] items-center gap-y-4 w-full gap-x-4" },
            React.createElement("label", { className: "font-N8 text-white" }, "Email Address"),
            React.createElement(TextInput, { placeholder: "Email Address", value: user.data.emailAddress, onChange: function (e) { return setUserProprety("emailAddress", e.target.value); } }),
            React.createElement("label", { className: "font-N8 text-white" }, "Given Name"),
            React.createElement(TextInput, { placeholder: "Given Name", value: user.data.givenName, onChange: function (e) { return setUserProprety("givenName", e.target.value); } }),
            React.createElement("label", { className: "font-N8 text-white" }, "Family Name"),
            React.createElement(TextInput, { placeholder: "Family Name", value: user.data.familyName, onChange: function (e) { return setUserProprety("familyName", e.target.value); } }),
            React.createElement("label", { className: "font-N8 text-white" }, "Permissions"),
            React.createElement(PermissionsDropdown, { tags: user.data.tags, onSetUserType: setUserType, isNoc: isNoc })),
        React.createElement("div", { className: "flex flex-row gap-x-4" },
            React.createElement(StyledButton, { text: "SAVE", onClick: onSave, disabled: loading }),
            React.createElement(StyledButton, { text: "CLOSE", style: StyledButtonStyle.Gray, onClick: close })),
        loading && React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon),
        React.createElement(SuccessIndicator, { success: updateUserSuccess, message: updateUserMessage })));
};
var CreateUser = function (_a) {
    var organizationId = _a.organizationId, close = _a.close, isNoc = _a.isNoc;
    var _b = useState({
        emailAddress: "",
        familyName: "",
        givenName: "",
        tags: [],
    }), user = _b[0], setUser = _b[1];
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(), updateUserSuccess = _d[0], setUpdateUserSuccess = _d[1];
    var _e = useState(), updateUserMessage = _e[0], setUpdateUserMessage = _e[1];
    var displayMessage = function (success, message) {
        setUpdateUserMessage(message);
        setUpdateUserSuccess(success);
        setTimeout(function () {
            setUpdateUserSuccess(undefined);
        }, 10000);
    };
    var setUserProprety = function (propertyName, value) {
        user[propertyName] = value;
        setUser(__assign({}, user));
    };
    var setUserType = function (type) {
        user.tags = userTypeToTags(type);
        setUser(__assign({}, user));
    };
    var onCreate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, request, res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!EmailValidator.validate(user.emailAddress)) {
                        displayMessage(false, "Please enter a valid email address.");
                        return [2 /*return*/];
                    }
                    if (!user.familyName) {
                        displayMessage(false, "Please enter a family name.");
                        return [2 /*return*/];
                    }
                    if (!user.givenName) {
                        displayMessage(false, "Please enter a given name.");
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    request = {
                        data: user,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/users/").concat(organizationId, "/create"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(request),
                        })];
                case 2:
                    res = _a.sent();
                    setLoading(false);
                    if (!res.ok) {
                        console.log("Error creating user: ", res.statusText);
                        displayMessage(false, "Something went wrong. Please try again.");
                        return [2 /*return*/];
                    }
                    else {
                        close();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    displayMessage(false, "Something went wrong. Please try again.");
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px]" },
        React.createElement("label", { className: "font-N5 text-white w-full text-center" }, "Edit User"),
        React.createElement("div", { className: "grid grid-cols-[auto_1fr] items-center gap-y-4 w-full gap-x-4" },
            React.createElement("label", { className: "font-N8 text-white" }, "Email Address"),
            React.createElement(TextInput, { placeholder: "Email Address", value: user.emailAddress, onChange: function (e) { return setUserProprety("emailAddress", e.target.value); } }),
            React.createElement("label", { className: "font-N8 text-white" }, "First (Given) Name"),
            React.createElement(TextInput, { placeholder: "First (Given) Name", value: user.givenName, onChange: function (e) { return setUserProprety("givenName", e.target.value); } }),
            React.createElement("label", { className: "font-N8 text-white" }, "Last (Family) Name"),
            React.createElement(TextInput, { placeholder: "Last (Family) Name", value: user.familyName, onChange: function (e) { return setUserProprety("familyName", e.target.value); } }),
            React.createElement("label", { className: "font-N8 text-white" }, "Permissions"),
            React.createElement(PermissionsDropdown, { tags: user.tags, onSetUserType: setUserType, isNoc: isNoc })),
        React.createElement("div", { className: "flex flex-row gap-x-4" },
            React.createElement(StyledButton, { text: "CREATE", onClick: onCreate, disabled: loading }),
            React.createElement(StyledButton, { text: "CLOSE", style: StyledButtonStyle.Gray, onClick: close })),
        loading && React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon),
        React.createElement(SuccessIndicator, { success: updateUserSuccess, message: updateUserMessage })));
};
var ResetPassword = function (_a) {
    var user = _a.user, organizationId = _a.organizationId, close = _a.close;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(), updateUserSuccess = _c[0], setUpdateUserSuccess = _c[1];
    var _d = useState(), updateUserMessage = _d[0], setUpdateUserMessage = _d[1];
    var displayMessage = function (success, message) {
        setUpdateUserMessage(message);
        setUpdateUserSuccess(success);
        setTimeout(function () {
            setUpdateUserSuccess(undefined);
        }, 10000);
    };
    var onResetPassword = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, request, res, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    setLoading(true);
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    request = {
                        email: user.data.emailAddress,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/users/").concat(organizationId, "/resetPassword"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(request),
                        })];
                case 2:
                    res = _a.sent();
                    setLoading(false);
                    if (!res.ok) {
                        console.log("Error submitting password reset: ", res.statusText);
                        displayMessage(false, "Something went wrong. Please try again.");
                        return [2 /*return*/];
                    }
                    else {
                        displayMessage(true, "Password reset email sent to ".concat(user.data.emailAddress, "."));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    displayMessage(false, "Something went wrong. Please try again.");
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px] items-center" },
        React.createElement("label", { className: "font-N5 text-white w-full text-center" }, "Confirm Action"),
        React.createElement("label", { className: "font-N8 text-white text-center" }, "Please confirm that you'd like to reset the password for ".concat(user.data.emailAddress, ".")),
        React.createElement("div", { className: "flex flex-row gap-x-4" },
            React.createElement(StyledButton, { text: "RESET PASSWORD", onClick: onResetPassword, disabled: loading }),
            React.createElement(StyledButton, { text: "CLOSE", style: StyledButtonStyle.Gray, onClick: close })),
        loading && React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon),
        React.createElement(SuccessIndicator, { success: updateUserSuccess, message: updateUserMessage })));
};
var DeleteUser = function (_a) {
    var user = _a.user, organizationId = _a.organizationId, close = _a.close;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(), updateUserSuccess = _c[0], setUpdateUserSuccess = _c[1];
    var _d = useState(), updateUserMessage = _d[0], setUpdateUserMessage = _d[1];
    var displayMessage = function (success, message) {
        setUpdateUserMessage(message);
        setUpdateUserSuccess(success);
        setTimeout(function () {
            setUpdateUserSuccess(undefined);
        }, 10000);
    };
    var onDeleteUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, request, res, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    setLoading(true);
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    request = {
                        id: user.id,
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.CORE_SVC_URL, "/users/").concat(organizationId, "/delete"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(request),
                        })];
                case 2:
                    res = _a.sent();
                    setLoading(false);
                    if (!res.ok) {
                        console.log("Error deleting user: ", res.statusText);
                        displayMessage(false, "Something went wrong. Please try again.");
                        return [2 /*return*/];
                    }
                    else {
                        close();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _a.sent();
                    displayMessage(false, "Something went wrong. Please try again.");
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px] items-center" },
        React.createElement("label", { className: "font-N5 text-white w-full text-center" }, "Confirm Action"),
        React.createElement("label", { className: "font-N8 text-white text-center max-w-[450px]" }, "Please confirm that you'd like to delete the user ".concat(user.data.emailAddress, ". THIS CANNOT BE UNDONE.")),
        React.createElement("div", { className: "flex flex-row gap-x-4" },
            React.createElement(StyledButton, { text: "DELETE USER", onClick: onDeleteUser, disabled: loading || !user }),
            React.createElement(StyledButton, { text: "CLOSE", style: StyledButtonStyle.Gray, onClick: close })),
        loading && React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon),
        React.createElement(SuccessIndicator, { success: updateUserSuccess, message: updateUserMessage })));
};
// Stations list page.
export var ManageUsers = function (_a) {
    var organizationId = _a.organizationId, isNoc = _a.isNoc;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently, user = _b.user;
    var userList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/users/").concat(organizationId, "/list"),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var ActiveDialog;
    (function (ActiveDialog) {
        ActiveDialog[ActiveDialog["NONE"] = 0] = "NONE";
        ActiveDialog[ActiveDialog["EDIT_USER"] = 1] = "EDIT_USER";
        ActiveDialog[ActiveDialog["RESET_PASSWORD"] = 2] = "RESET_PASSWORD";
        ActiveDialog[ActiveDialog["DELETE_USER"] = 3] = "DELETE_USER";
        ActiveDialog[ActiveDialog["CREATE_USER"] = 4] = "CREATE_USER";
    })(ActiveDialog || (ActiveDialog = {}));
    var _c = useState(), activeUser = _c[0], setActiveUser = _c[1];
    var _d = useState(ActiveDialog.NONE), activeDialog = _d[0], setActiveDialog = _d[1];
    var openUserDialog = function (user, dialog) {
        setActiveUser(user);
        setActiveDialog(dialog);
    };
    return (React.createElement("div", { className: "flex flex-col gap-y-[64px] mt-[64px]" },
        React.createElement("label", { className: "font-N4 text-white" }, "Manage Users"),
        userList != null && (React.createElement("div", { className: "grid grid-cols-4 items-center gap-y-4 mr-[32px]" },
            React.createElement("label", { className: "font-N7 text-white" }, "Name"),
            React.createElement("label", { className: "font-N7 text-white" }, "Email Address"),
            React.createElement("label", { className: "font-N7 text-white" }, "User Type"),
            React.createElement("label", { className: "font-N7 text-white" }, "Actions"),
            React.createElement("hr", { className: "border-black-800 col-span-4" }),
            userList.users
                .sort(function (u1, u2) {
                return "".concat(u1.data.givenName, " ").concat(u1.data.familyName).localeCompare("".concat(u2.data.givenName, " ").concat(u2.data.familyName));
            })
                .map(function (u, i) {
                return (React.createElement(Fragment, { key: i },
                    React.createElement("label", { className: "font-N8 text-white" }, "".concat(u.data.givenName, " ").concat(u.data.familyName)),
                    React.createElement("label", { className: "font-N8 text-white" }, u.data.emailAddress),
                    React.createElement("label", { className: "font-N8 text-white" }, tagsToUserType(u.data.tags)),
                    React.createElement("div", { className: "flex flex-row gap-x-2 items-center" },
                        React.createElement("a", { className: "hover:text-black-600 font-N8p5 text-white text-clip whitespace-nowrap cursor-pointer", onClick: function () {
                                openUserDialog(u, ActiveDialog.EDIT_USER);
                            } }, "Edit User"),
                        (user === null || user === void 0 ? void 0 : user.email) != u.data.emailAddress && (React.createElement(React.Fragment, null,
                            React.createElement("label", { className: "font-N8p5 text-white" }, "|"),
                            React.createElement("a", { className: "hover:text-black-600 font-N8p5 text-white text-clip whitespace-nowrap cursor-pointer", onClick: function () {
                                    openUserDialog(u, ActiveDialog.RESET_PASSWORD);
                                } }, "Reset Password"),
                            React.createElement("label", { className: "font-N8p5 text-white" }, "|"),
                            React.createElement("a", { className: "hover:text-black-600 font-N8p5 text-white text-clip whitespace-nowrap cursor-pointer", onClick: function () {
                                    openUserDialog(u, ActiveDialog.DELETE_USER);
                                } }, "Delete User"))))));
            }),
            React.createElement(Dialog, { open: activeDialog != ActiveDialog.NONE, onClose: function () { return setActiveDialog(ActiveDialog.NONE); }, className: "relative z-50" },
                React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
                React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                    React.createElement(Dialog.Panel, { className: "mx-auto" },
                        activeDialog == ActiveDialog.EDIT_USER && (React.createElement(EditUser, { userInput: activeUser, organizationId: organizationId, close: function () { return setActiveDialog(ActiveDialog.NONE); }, isNoc: isNoc })),
                        activeDialog == ActiveDialog.RESET_PASSWORD && (React.createElement(ResetPassword, { user: activeUser, organizationId: organizationId, close: function () { return setActiveDialog(ActiveDialog.NONE); } })),
                        activeDialog == ActiveDialog.DELETE_USER && (React.createElement(DeleteUser, { user: activeUser, organizationId: organizationId, close: function () { return setActiveDialog(ActiveDialog.NONE); } })),
                        activeDialog == ActiveDialog.CREATE_USER && (React.createElement(CreateUser, { organizationId: organizationId, close: function () { return setActiveDialog(ActiveDialog.NONE); }, isNoc: isNoc }))))),
            React.createElement(StyledButton, { text: "ADD NEW USER", onClick: function () { return setActiveDialog(ActiveDialog.CREATE_USER); }, className: "mt-16" })))));
};
