"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertToCSV = void 0;
function ConvertToCSV(rows, columnsInOrder) {
    return [
        columnsInOrder,
        ...rows.map((entry) => columnsInOrder.map((column) => entry[column] ?? "")),
    ]
        .map((row) => row
        .map((entry) => `${entry}`.replaceAll('"', '""'))
        .map((entry) => `"${entry}"`)
        .join(","))
        .join("\r\n");
}
exports.ConvertToCSV = ConvertToCSV;
