import React from "react";
import { Dropdown } from "./Dropdown";
import useSWR from "swr";
import { Auth0Fetcher } from "../Auth0Fetcher";
import { useAuth0 } from "@auth0/auth0-react";
import { process } from "../Process";
import { Switch } from "@headlessui/react";
export var NocOrganizationSelection = function (_a) {
    var userOrganizationId = _a.userOrganizationId, selectedOrganizationId = _a.selectedOrganizationId, setOrganizationId = _a.setOrganizationId, hideNocFeatures = _a.hideNocFeatures, setHideNocFeatures = _a.setHideNocFeatures;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    var organizations = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/organizations/list"),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var items = [
        {
            name: "All Organizations",
            value: userOrganizationId,
            selectedLabel: "Change Organization",
        },
    ];
    if (organizations) {
        organizations
            .filter(function (o) { return o.id != userOrganizationId; })
            .forEach(function (o) {
            items.push({
                name: o.name,
                value: o.id,
            });
        });
    }
    return (React.createElement("div", { className: "flex flex-row items-center gap-x-[16px] bg-black-1150/75 rounded-[10px] px-[16px] py-[8px]" },
        React.createElement("label", { className: "font-N8 text-black-400" }, "Hide NOC Features"),
        React.createElement(Switch, { checked: hideNocFeatures, onChange: setHideNocFeatures, className: "".concat(hideNocFeatures ? "bg-blue-600" : "bg-gray-500", " relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2") },
            React.createElement("span", { className: "".concat(hideNocFeatures ? "translate-x-6" : "translate-x-1", " inline-block h-4 w-4 transform rounded-full bg-white transition-transform") })),
        React.createElement(Dropdown, { items: items, currentSelection: selectedOrganizationId, onClick: setOrganizationId })));
};
