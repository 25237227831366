var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { StaticTable } from "../Table";
import { Auth0Fetcher } from "../../Auth0Fetcher";
export var NocThirdPartyAppToken = function () {
    var _a = useState(""), credentialA = _a[0], setCredentialA = _a[1];
    var _b = useState(""), credentialAOwner = _b[0], setCredentialAOwner = _b[1];
    var _c = useState(""), credentialARecipient = _c[0], setCredentialARecipient = _c[1];
    var _d = useState(""), thirdPartyUrl = _d[0], setThirdPartyUrl = _d[1];
    var _e = useState("idle"), requestStatus = _e[0], setRequestStatus = _e[1];
    var _f = useState(""), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = useState({
        credentialAOwner: "",
        credentialA: "",
        credentialARecipient: "",
        thirdPartyUrl: "",
    }), validationMessages = _g[0], setValidationMessages = _g[1];
    var _h = useAuth0(), isAuthenticated = _h.isAuthenticated, isLoading = _h.isLoading, getAccessTokenSilently = _h.getAccessTokenSilently;
    var credentialTokensList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/reports/tokens/list"),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var generateTokenBAndSendCredentials = function () { return __awaiter(void 0, void 0, void 0, function () {
        var valid, newValidationMessages, cpoToken, data, response, resErrMessage, _a, _b, credentials, thirdPartyToken;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    valid = true;
                    newValidationMessages = {
                        credentialAOwner: "",
                        credentialA: "",
                        credentialARecipient: "",
                        thirdPartyUrl: "",
                    };
                    if (!credentialAOwner) {
                        newValidationMessages.credentialAOwner =
                            "Please enter the recipient name";
                        valid = false;
                    }
                    if (!credentialA) {
                        newValidationMessages.credentialA = "Please enter Credential A";
                        valid = false;
                    }
                    if (!thirdPartyUrl) {
                        newValidationMessages.thirdPartyUrl = "Please enter the Third Party Url";
                        valid = false;
                    }
                    setValidationMessages(newValidationMessages);
                    if (!valid)
                        return [2 /*return*/];
                    cpoToken = uuidv4();
                    data = createCredentialsReturnObject(cpoToken);
                    setRequestStatus("loading");
                    return [4 /*yield*/, fetch("".concat(process.env.THIRD_PARTY_URL, "/credentials"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Token ".concat(credentialA),
                            },
                            body: JSON.stringify(data),
                        })];
                case 1:
                    response = _c.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    _b = (_a = "Failed to send credentials. Status: ".concat(response.status, " - ").concat(response.statusText, ". Details: ")).concat;
                    return [4 /*yield*/, response.text()];
                case 2:
                    resErrMessage = _b.apply(_a, [_c.sent()]);
                    console.error(resErrMessage);
                    setErrorMessage(resErrMessage);
                    setRequestStatus("error");
                    return [2 /*return*/];
                case 3: return [4 /*yield*/, response.json()];
                case 4:
                    credentials = _c.sent();
                    thirdPartyToken = credentials.token;
                    if (!(thirdPartyToken && credentialAOwner)) return [3 /*break*/, 6];
                    return [4 /*yield*/, sendTokensToNOC(credentialAOwner, "ECE", credentialA, cpoToken, thirdPartyToken, thirdPartyUrl)];
                case 5:
                    _c.sent();
                    _c.label = 6;
                case 6:
                    console.log("Credentials successfully sent");
                    return [2 /*return*/];
            }
        });
    }); };
    var generateTokenAInDatabase = function () { return __awaiter(void 0, void 0, void 0, function () {
        var valid, newValidationMessages, credentialA;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    valid = true;
                    newValidationMessages = {
                        credentialAOwner: "",
                        credentialA: "",
                        credentialARecipient: "",
                        thirdPartyUrl: "",
                    };
                    if (!credentialARecipient) {
                        newValidationMessages.credentialARecipient =
                            "Please enter the recipient name";
                        valid = false;
                    }
                    if (!thirdPartyUrl) {
                        newValidationMessages.thirdPartyUrl = "Please enter the Third Party Url";
                        valid = false;
                    }
                    setValidationMessages(newValidationMessages);
                    if (!valid)
                        return [2 /*return*/];
                    credentialA = uuidv4();
                    console.log("uuid is ", credentialA);
                    if (!credentialA) return [3 /*break*/, 2];
                    return [4 /*yield*/, sendTokensToNOC("ECE", credentialARecipient, credentialA, "", "", thirdPartyUrl)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var sendTokensToNOC = function (credentialAOwner, credentialARecipient, tokenA, cpoToken, thirdPartyToken, thirdPartyBaseUrl) { return __awaiter(void 0, void 0, void 0, function () {
        var nocQuery, accessToken, credentialTokens, nocResponse, resErrMessage, _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    nocQuery = "".concat(process.env.NOC_SVC_URL, "/reports/addCredentialTokens");
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _c.sent();
                    credentialTokens = {
                        TOKEN_A_OWNER: credentialAOwner,
                        TOKEN_A_RECIPIENT: credentialARecipient,
                        Credential_Token_A: tokenA,
                        CPO_TOKEN: cpoToken,
                        THIRD_PARTY_MSP_TOKEN: thirdPartyToken,
                        THIRD_PARTY_BASE_URL: thirdPartyBaseUrl,
                    };
                    return [4 /*yield*/, fetch(nocQuery, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(credentialTokens),
                        })];
                case 2:
                    nocResponse = _c.sent();
                    if (!!nocResponse.ok) return [3 /*break*/, 4];
                    _b = (_a = "Failed to send tokens to NOC. Status: ".concat(nocResponse.status, " - ").concat(nocResponse.statusText, ". Details: ")).concat;
                    return [4 /*yield*/, nocResponse.text()];
                case 3:
                    resErrMessage = _b.apply(_a, [_c.sent()]);
                    console.error(resErrMessage);
                    setErrorMessage(resErrMessage);
                    setRequestStatus("error");
                    return [2 /*return*/];
                case 4:
                    setRequestStatus("success");
                    return [2 /*return*/, true];
            }
        });
    }); };
    var createCredentialsReturnObject = function (credentials) {
        return {
            token: credentials,
            url: "https://".concat(process.env.BASE_URL, "/ocpi/versions/"),
            roles: [
                {
                    role: "CPO",
                    business_details: {
                        name: "Electric Era",
                        phone_number: process.env.CUSTOMER_SERVICE_PHONE_NUMBER,
                        website: "https://electriceratechnologies.com/",
                    },
                    party_id: "ECE",
                    country_code: "US",
                },
            ],
        };
    };
    var columns = useMemo(function () { return [
        {
            header: "Token A",
            accessorFn: function (row) { return row.token; },
            size: 30,
        },
        {
            header: "Status",
            accessorFn: function (row) {
                return row.status === "NOT_USED" ? "NOT USED" : "USED";
            },
            size: 10,
        },
        {
            header: "Owner",
            accessorFn: function (row) { return row.owner; },
            size: 10,
        },
        {
            header: "Consumer",
            accessorFn: function (row) { return row.consumer; },
            size: 10,
        },
        {
            header: "URL",
            accessorFn: function (row) { return row.thirdPartyUrl; },
            size: 40,
        },
    ]; }, []);
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex flex-col gap-y-[32px] pt-[32px]" },
            React.createElement("div", { className: "pt-[32px]" },
                React.createElement("h2", { className: "text-l font-bold mb-4 text-white" }, "Token Table"),
                React.createElement(StaticTable, { data: credentialTokensList !== null && credentialTokensList !== void 0 ? credentialTokensList : [], columnDefinitions: columns, emptyMessage: "No tokens available." })),
            React.createElement("p", null,
                " ",
                "Please Enter both the Third Party URL and choose either of the 2 generate options based on our OCPI credentials role - receiver (1st option) or receiver(2nd option)"),
            React.createElement("label", { className: "font-N6 text-white" }, "API Tokens"),
            React.createElement("div", null,
                React.createElement("label", { className: "font-semibold text-white" }, "Enter Third Party URL:"),
                React.createElement("input", { type: "text", value: thirdPartyUrl, onChange: function (e) { return setThirdPartyUrl(e.target.value); }, placeholder: "Enter Third Party URL", className: "text-black p-3 rounded-md m-2", required: true }),
                validationMessages.thirdPartyUrl && (React.createElement("div", { className: "text-red" }, validationMessages.thirdPartyUrl))),
            React.createElement("label", { className: "font-N6 text-white" }, "Generate and Send Token B for Third Party Usage"),
            React.createElement("div", { className: "flex flex-col gap-y-4" },
                React.createElement("div", null,
                    React.createElement("label", { className: "font-semibold text-white" }, "Name of the Third Party:"),
                    React.createElement("input", { type: "text", value: credentialAOwner, onChange: function (e) { return setCredentialAOwner(e.target.value); }, placeholder: "Enter Recipient Name", className: "text-black p-3 rounded-md m-2", required: true }),
                    validationMessages.credentialAOwner && (React.createElement("div", { className: "text-red" }, validationMessages.credentialAOwner))),
                React.createElement("div", null,
                    React.createElement("label", { className: "font-semibold text-white" }, "Enter Credential A of Third Party:"),
                    React.createElement("input", { type: "text", value: credentialA, onChange: function (e) { return setCredentialA(e.target.value); }, placeholder: "Enter Credential A", className: "text-black p-3 rounded-md m-2", required: true }),
                    validationMessages.credentialA && (React.createElement("div", { className: "text-red" }, validationMessages.credentialA))),
                React.createElement("button", { className: "px-[16px] py-[8px] rounded-[8px] bg-blue-700 hover:bg-blue-400 text-white w-fit", onClick: generateTokenBAndSendCredentials }, "Generate Token B and Send Credentials"))),
        React.createElement("div", { className: "flex flex-col gap-y-[32px] pt-[32px]" },
            React.createElement("label", { className: "font-N6 text-white" }, "Generate Token A for Third Party Usage"),
            React.createElement("div", null,
                React.createElement("label", { className: "font-semibold text-white" }, "Name of the Third Party Recipient:"),
                React.createElement("input", { type: "text", value: credentialARecipient, onChange: function (e) { return setCredentialARecipient(e.target.value); }, placeholder: "Enter Recipient Name", className: "text-black p-3 rounded-md m-2", required: true }),
                validationMessages.credentialARecipient && (React.createElement("div", { className: "text-red" }, validationMessages.credentialARecipient))),
            React.createElement("div", { className: "flex flex-col gap-y-4" },
                React.createElement("button", { className: "px-[16px] py-[8px] rounded-[8px] bg-blue-700 hover:bg-blue-400 text-white w-fit", onClick: generateTokenAInDatabase }, "Generate Token A"))),
        requestStatus === "success" && (React.createElement("div", { className: "text-green" }, "Request was successful!")),
        requestStatus === "loading" && (React.createElement("div", { className: "text-yellow" }, "Loading Request..")),
        requestStatus === "error" && (React.createElement("div", { className: "text-red" },
            "Request failed: ",
            errorMessage))));
};
