"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nativeToProtoTimestamp = exports.protoTimestampToNative = void 0;
/**
 * Converts a protobuf timestamp to a native Javascript representation.
 */
function protoTimestampToNative(stamp) {
    if (!stamp || !stamp.seconds) {
        return new Date();
    }
    const seconds = stamp.seconds.low
        ? stamp.seconds.low
        : stamp.seconds;
    const nanos = stamp.nanos ?? 0;
    return new Date(seconds * 1000 + nanos / 1000000);
}
exports.protoTimestampToNative = protoTimestampToNative;
/**
 * Converts a native javascript date object to a protobuf date.
 *
 * @param date The date to convert.
 * @returns The protobuf structured date.
 */
function nativeToProtoTimestamp(date) {
    const seconds = Math.floor(date.getTime() / 1000);
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
exports.nativeToProtoTimestamp = nativeToProtoTimestamp;
