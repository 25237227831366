var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWRImmutable from "swr/immutable";
import { process } from "../Process";
import { Auth0Fetcher } from "../Auth0Fetcher";
import { useNavigate, useParams } from "react-router-dom";
import { SingleStat } from "../Components/SingleStat";
import { GetCarInformationFromEVCCID, GetDisplayPrice, GetOCPPVendorErrorDetails, PortTypeToString, protoTimestampToNative, } from "ee-utils";
import { BackButton } from "../Components/BackButton";
import { AlertIcon } from "../Components/Images";
import { LoadingIcon } from "ee-tailwind-components";
import { LineGraph } from "../Components/Graph/LineGraph";
import { Transition } from "@headlessui/react";
import { StyledButton, StyledButtonStyle } from "../Components/StyledButton";
import { money } from "ee-proto-interfaces";
import { TextInput } from "../Components/Input";
import { MINUTES_TO_MS } from "../Constants";
export var RelatedTransactionsTable = function (_a) {
    var transactions = _a.transactions, title = _a.title;
    var navigate = useNavigate();
    return (React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
        React.createElement("label", { className: "mt-[64px] mb-[8px] font-N6 text-white" }, title),
        transactions.map(function (p, i) {
            var chargeSeconds = p
                ? Math.round((new Date(p.sessionEnd).getTime() -
                    new Date(p.sessionStart).getTime()) /
                    1000)
                : 0;
            var s = chargeSeconds % 60;
            var m = Math.floor(chargeSeconds / 60);
            var durationString = "".concat(m, "m ").concat(s, "s");
            var label = "".concat(p.transactionId, " (").concat(new Date(p.sessionStart).toLocaleString(), ", ").concat(Math.round(Number(p.peakPowerKw)), " kW peak, ").concat(Number(p.energyDeliveredKwh).toFixed(2), " kWh, ").concat(durationString).concat(p.faultCode ? ", fault ".concat(p.faultCode) : "", ")");
            return (React.createElement("button", { onClick: function () { return navigate("/transaction/".concat(p.transactionId)); }, className: "text-white hover:text-black-400 font-N8 whitespace-pre w-fit", key: i }, label));
        })));
};
var PaymentDetails = function (_a) {
    var transactionId = _a.transactionId;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    var _c = useState(false), showDetails = _c[0], setShowDetails = _c[1];
    var details = useSWRImmutable(isLoading || !isAuthenticated || !transactionId || !showDetails
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/transaction/paymentDetails/").concat(transactionId),
            getAccessTokenSilently,
        ], Auth0Fetcher, {
        refreshInterval: undefined,
    }).data;
    var opacityTransitionProperties = {
        enter: "transition-opacity duration-500",
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leave: "transition-opacity duration-300",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
    };
    return (React.createElement("div", { className: "grid" },
        React.createElement(Transition, __assign({ className: "overlap-grid flex flex-col gap-y-4" }, opacityTransitionProperties, { show: !showDetails }),
            React.createElement(StyledButton, { text: "View Credit Card Information", onClick: function () { return setShowDetails(true); }, style: StyledButtonStyle.Gray })),
        React.createElement(Transition, __assign({ className: "overlap-grid flex flex-col gap-y-4" }, opacityTransitionProperties, { show: showDetails }),
            details == null && (React.createElement("div", { className: "h-[32px] w-[32px]" }, LoadingIcon)),
            details != null && (React.createElement(React.Fragment, null,
                React.createElement("hr", { className: "border-black-800 max-w-[275px]" }),
                React.createElement("div", { className: "flex flex-row gap-x-[32px]" },
                    React.createElement(SingleStat, { title: "Card Type", value: details.cardType }),
                    React.createElement(SingleStat, { title: "Card Number", value: details.maskedPan }),
                    React.createElement(SingleStat, { title: "Cardholder Name", value: details.customerName })))))));
};
var InsightCard = function (_a) {
    var title = _a.title, body = _a.body;
    return (React.createElement("div", { className: "flex flex-row gap-x-[16px] p-[32px] justify-center rounded-[20px] bg-black-1000 max-w-[350px]" },
        React.createElement("div", { className: "mt-[4px] w-fit" }, AlertIcon),
        React.createElement("div", { className: "flex flex-col gap-y-[16px]" },
            React.createElement("label", { className: "text-white font-N7" }, title),
            React.createElement("label", { className: "text-white font-N8p5" }, body))));
};
export var Transaction = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    var isNoc = _a.isNoc;
    var _w = useAuth0(), isAuthenticated = _w.isAuthenticated, isLoading = _w.isLoading, getAccessTokenSilently = _w.getAccessTokenSilently;
    var transactionId = useParams().transactionId;
    var startDateInput = useRef(null);
    var endDateInput = useRef(null);
    var details = useSWRImmutable(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/transactions/transaction/").concat(transactionId),
            getAccessTokenSilently,
        ], Auth0Fetcher, {
        refreshInterval: undefined,
    }).data;
    var chargeTime = details
        ? Math.round((new Date(details.sessionEnd).getTime() -
            new Date(details.sessionStart).getTime()) /
            1000)
        : 0;
    var secondsToDisplayTime = function (seconds) {
        var chargeSeconds = seconds % 60;
        var chargeMinutes = Math.floor(seconds / 60);
        var displayTime = seconds == 0 ? "--" : "";
        if (chargeMinutes > 0) {
            displayTime = "".concat(chargeMinutes, " minute").concat(chargeMinutes == 1 ? "" : "s", " ");
        }
        if (chargeSeconds > 0) {
            displayTime = "".concat(displayTime).concat(chargeSeconds, " second").concat(chargeSeconds == 1 ? "" : "s");
        }
        return displayTime;
    };
    var secondsToGraphDisplayTime = function (seconds) {
        var chargeSeconds = seconds % 60;
        var chargeMinutes = Math.floor(seconds / 60);
        return "".concat(chargeMinutes, "m ").concat(chargeSeconds, "s");
    };
    var displayTime = secondsToDisplayTime(chargeTime);
    if (!details) {
        return React.createElement("div", null);
    }
    var hasDetails = details.details && details.details.length > 0;
    var socDifference = hasDetails
        ? Number(details.details[details.details.length - 1].SoC) -
            Number((_b = details.details.find(function (detail) { return detail.SoC > 0; })) === null || _b === void 0 ? void 0 : _b.SoC)
        : 0;
    var startTime = hasDetails
        ? new Date(details.details[0].detailTime).getTime() / 1000
        : new Date(details.sessionStart).getTime() / 1000;
    var hasPowerRequest = false;
    var powerGraphData = hasDetails
        ? details.details.map(function (detail) {
            if (detail.powerRequestKW > 0) {
                hasPowerRequest = true;
            }
            return {
                power: Math.max(0, detail.powerKW),
                limit: Math.max(0, detail.powerLimitKW),
                request: Math.max(0, detail.powerRequestKW),
                time: new Date(detail.detailTime).getTime() / 1000 - startTime,
            };
        })
        : [];
    var deliveredEnergyData = hasDetails
        ? details.details.map(function (detail) {
            return {
                energy: detail.totalEnergyDelivered,
                soc: detail.SoC,
                time: new Date(detail.detailTime).getTime() / 1000 - startTime,
            };
        })
        : [];
    var hasLowRateDetails = isNoc && details.lowRateDetails && details.lowRateDetails.length > 0;
    var powerDiagnosticsLabelAccesor = function (d) {
        return "".concat(secondsToGraphDisplayTime(d.time));
    };
    var powerDiagnosticsValueAccessors = [
        {
            label: "Power",
            accessor: function (d) { return d.power; },
        },
        {
            label: "Power Limit",
            accessor: function (d) { return d.powerLimit; },
        },
        {
            label: "EV Max Power",
            accessor: function (d) { return d.evMaxPower; },
        },
    ];
    var powerDiagnosticsGraphData = hasLowRateDetails
        ? details.lowRateDetails.map(function (detail) {
            return {
                power: Math.max(0, detail.powerKW),
                powerLimit: Math.max(0, detail.powerLimitKW),
                evMaxPower: Math.max(0, detail.evMaxPower),
                time: new Date(detail.detailTime).getTime() / 1000 - startTime,
            };
        })
        : [];
    var voltageDiagnosticsLabelAccesor = function (d) {
        return "".concat(secondsToGraphDisplayTime(d.time));
    };
    var voltageDiagnosticsValueAccessors = [
        {
            label: "Voltage",
            accessor: function (d) { return d.voltage; },
        },
        {
            label: "EV Max Voltage",
            accessor: function (d) { return d.evMaxVoltage; },
        },
        {
            label: "EVSE Max Voltage",
            accessor: function (d) { return d.evseMaxVoltage; },
        },
        {
            label: "EV Target Voltage",
            accessor: function (d) { return d.evTargetVoltage; },
        },
    ];
    var voltageDiagnosticsGraphData = hasLowRateDetails
        ? details.lowRateDetails.map(function (detail) {
            return {
                voltage: Math.max(0, detail.voltage),
                evMaxVoltage: Math.max(0, detail.evMaxVoltage),
                evseMaxVoltage: Math.max(0, detail.evseMaxVoltage),
                evTargetVoltage: Math.max(0, detail.evTargetVoltage),
                time: new Date(detail.detailTime).getTime() / 1000 - startTime,
            };
        })
        : [];
    var currentDiagnosticsLabelAccesor = function (d) {
        return "".concat(secondsToGraphDisplayTime(d.time));
    };
    var currentDiagnosticsValueAccessors = [
        {
            label: "Current",
            accessor: function (d) { return d.current; },
        },
        {
            label: "EV Max Current",
            accessor: function (d) { return d.evMaxCurrent; },
        },
        {
            label: "EVSE Max Current",
            accessor: function (d) { return d.evseMaxCurrent; },
        },
        {
            label: "EV Target Current",
            accessor: function (d) { return d.evTargetCurrent; },
        },
    ];
    var currentDiagnosticsGraphData = hasLowRateDetails
        ? details.lowRateDetails.map(function (detail) {
            return {
                current: Math.max(0, detail.current),
                evMaxCurrent: Math.max(0, detail.evMaxCurrent),
                evseMaxCurrent: Math.max(0, detail.evseMaxCurrent),
                evTargetCurrent: Math.max(0, detail.evTargetCurrent),
                time: new Date(detail.detailTime).getTime() / 1000 - startTime,
            };
        })
        : [];
    var socDiagnosticsLabelAccesor = function (d) { return "".concat(d.time, "s"); };
    var socDiagnosticsValueAccessors = [
        {
            label: "SoC",
            accessor: function (d) { return d.soc; },
        },
        {
            label: "Time to Bulk SoC",
            accessor: function (d) { return d.timeToBulkSoc; },
            secondAxis: true,
        },
        {
            label: "Time to Full SoC",
            accessor: function (d) { return d.timeToFullSoc; },
            secondAxis: true,
        },
    ];
    var socDiagnosticsGraphData = hasLowRateDetails
        ? details.lowRateDetails.map(function (detail) {
            return {
                soc: Math.max(0, detail.SoC),
                timeToBulkSoc: Math.max(0, detail.timeToBulkSoc),
                timeToFullSoc: Math.max(0, detail.timeToFullSoc),
                time: new Date(detail.detailTime).getTime() / 1000 - startTime,
            };
        })
        : [];
    var sessionInsights = [];
    if ((details === null || details === void 0 ? void 0 : details.faultCode) != null) {
        var errorDetails = GetOCPPVendorErrorDetails(details.faultCode);
        if (errorDetails) {
            sessionInsights.push([
                "Session Faulted",
                errorDetails.commandConsoleDescription,
            ]);
        }
    }
    if (isNoc && (details === null || details === void 0 ? void 0 : details.faultCode) != null) {
        sessionInsights.push([
            "NOC Fault Details",
            "Vendor error code: ".concat(details === null || details === void 0 ? void 0 : details.faultCode),
        ]);
    }
    var powerLabelAccesor = function (d) {
        return "".concat(secondsToGraphDisplayTime(d.time));
    };
    var powerValueAccessors = [
        {
            label: "Power",
            accessor: function (d) { return d.power; },
        },
        {
            label: "Power Limit",
            accessor: function (d) { return d.limit; },
        },
    ];
    if (hasPowerRequest) {
        powerValueAccessors.push({
            label: "Vehicle Request",
            accessor: function (d) { return d.request; },
        });
    }
    var energyLabelAccesor = function (d) {
        return "".concat(secondsToGraphDisplayTime(d.time));
    };
    var energyValueAccessors = [
        {
            label: "Car Battery %",
            accessor: function (d) { return d.soc; },
        },
        {
            label: "Energy Delivered",
            accessor: function (d) { return d.energy; },
            secondAxis: true,
        },
    ];
    var currentLabelAccesor = function (d) {
        return "".concat(secondsToGraphDisplayTime(d.time));
    };
    var currentValueAccessors = [
        {
            label: "Current",
            accessor: function (d) { return d.current; },
        },
        {
            label: "Voltage",
            accessor: function (d) { return d.voltage; },
            secondAxis: true,
        },
    ];
    var currentData = [];
    if (isNoc && hasDetails && details.details) {
        currentData = details.details
            .map(function (d) {
            if (d.current == null || d.voltage == null) {
                return null;
            }
            return {
                current: d.current,
                voltage: d.voltage,
                time: new Date(d.detailTime).getTime() / 1000 - startTime,
            };
        })
            .filter(function (d) { return d; });
    }
    var authorizationTypeToString = function (authorizationType) {
        if (authorizationType == null) {
            return null;
        }
        switch (authorizationType) {
            case money.PaymentAuthorizationType.AUTHORIZATION_TYPE_CARD_PRESENT:
                return "CARD PRESENT";
            case money.PaymentAuthorizationType
                .AUTHORIZATION_TYPE_CARD_PRESENT_OFFLINE:
                return isNoc ? "CARD PRESENT/OFFLINE" : "CARD PRESENT";
            case money.PaymentAuthorizationType.AUTHORIZATION_TYPE_CARD_PRESENT_PHONE:
                return "PHONE PAYMENT";
            case money.PaymentAuthorizationType
                .AUTHORIZATION_TYPE_REMOTE_AUTHORIZED_COMMAND_CONSOLE:
                return "COMMAND CONSOLE";
            case money.PaymentAuthorizationType
                .AUTHORIZATION_TYPE_REMOTE_AUTHORIZED_OCPI:
                return "OCPI";
        }
        return null;
    };
    var authorizationTypeString = authorizationTypeToString(details === null || details === void 0 ? void 0 : details.authorizationType);
    var taxes = (_e = (_d = (_c = details === null || details === void 0 ? void 0 : details.chargeBreakdown) === null || _c === void 0 ? void 0 : _c.surcharges) === null || _d === void 0 ? void 0 : _d.filter(function (s) { var _a; return ((_a = s.charge) === null || _a === void 0 ? void 0 : _a.type) == money.SurchargeType.SURCHARGE_TAX; })) !== null && _e !== void 0 ? _e : [];
    var totalTaxAmount = (_f = taxes.reduce(function (a, c) { var _a, _b; return a + ((_b = (_a = c.amount) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0); }, 0)) !== null && _f !== void 0 ? _f : 0;
    var taxAuthorities = taxes.map(function (t) {
        var _a, _b, _c, _d;
        return "".concat((_b = (_a = t.charge) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "Tax", " (").concat((((_d = (_c = t.charge) === null || _c === void 0 ? void 0 : _c.percentage) !== null && _d !== void 0 ? _d : 0) * 100).toFixed(3), "%)");
    });
    var carInformation = (details === null || details === void 0 ? void 0 : details.evccid) != null && details.evccid != ""
        ? GetCarInformationFromEVCCID(details.evccid)
        : undefined;
    var formatDateTimeLocal = function (date) {
        var pad = function (n) { return n.toString().padStart(2, "0"); };
        return "".concat(date.getFullYear(), "-").concat(pad(date.getMonth() + 1), "-").concat(pad(date.getDate()), "T").concat(pad(date.getHours()), ":").concat(pad(date.getMinutes()));
    };
    var handleAdjustTime = function (inputRef, minutes) {
        if (inputRef.current) {
            var newTime = adjustTime(inputRef.current.value, minutes);
            inputRef.current.value = newTime;
        }
    };
    var adjustTime = function (dateString, minutes) {
        var date = new Date(dateString);
        date.setMinutes(date.getMinutes() + minutes);
        return formatDateTimeLocal(date);
    };
    var sessionStart = details ? new Date(details.sessionStart) : undefined;
    var sessionEnd = details ? new Date(details.sessionEnd) : undefined;
    var fetchLogs = function (transactionId, startTime, endTime) { return __awaiter(void 0, void 0, void 0, function () {
        var query, accessToken, filename;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = "".concat(process.env.NOC_SVC_URL, "/logs/transaction/download/").concat(transactionId, "?start=").concat(startTime, "&end=").concat(endTime);
                    return [4 /*yield*/, getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.AUTH0_AUDIENCE,
                            },
                        })];
                case 1:
                    accessToken = _a.sent();
                    filename = "download.zip";
                    fetch(query, {
                        headers: {
                            authorization: "Bearer ".concat(accessToken),
                        },
                    })
                        .then(function (response) {
                        var _a, _b;
                        if (!response.ok) {
                            throw new Error("HTTP error! Status: ".concat(response.status));
                        }
                        filename =
                            ((_b = (_a = response.headers
                                .get("Content-Disposition")) === null || _a === void 0 ? void 0 : _a.match(/filename="([^"]+)"/)) === null || _b === void 0 ? void 0 : _b[1]) || filename;
                        return response.blob();
                    })
                        .then(function (blob) {
                        var url = window.URL.createObjectURL(blob);
                        var link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        link.click();
                        window.URL.revokeObjectURL(url);
                    })
                        .catch(function (error) {
                        console.error("Error fetching logs:", error);
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col pl-[32px]" },
        React.createElement(BackButton, null),
        React.createElement("label", { className: "font-N5 text-white mt-[64px]" }, "Session Details"),
        details && (React.createElement("label", { className: "font-N8 text-black-400 mt-[4px]" }, "".concat(sessionStart === null || sessionStart === void 0 ? void 0 : sessionStart.toLocaleString()))),
        React.createElement("div", { className: "grid grid-cols-3 mt-[64px] gap-x-[32px] gap-y-[32px] w-fit" },
            React.createElement(SingleStat, { title: "Transaction Amount", value: "$".concat(GetDisplayPrice((_g = details === null || details === void 0 ? void 0 : details.transactionAmount) !== null && _g !== void 0 ? _g : 0, (_h = details === null || details === void 0 ? void 0 : details.transactionAmountPrecision) !== null && _h !== void 0 ? _h : 2)) }),
            React.createElement(SingleStat, { title: "Peak Power", value: "".concat(Number(details === null || details === void 0 ? void 0 : details.peakPowerKw).toFixed(2), " kW") }),
            React.createElement(SingleStat, { title: "Energy Delivered", value: "".concat(Number(details === null || details === void 0 ? void 0 : details.energyDeliveredKwh).toFixed(2), " kWh") }),
            React.createElement(SingleStat, { title: "Total Charge Time", value: "".concat(displayTime) }),
            React.createElement(SingleStat, { title: "Vehicle Battery Level", value: "+".concat(socDifference, "%") }),
            isNoc && React.createElement(SingleStat, { title: "Port ID", value: "".concat(details === null || details === void 0 ? void 0 : details.portId) }),
            React.createElement(SingleStat, { title: "Plug", value: PortTypeToString(details === null || details === void 0 ? void 0 : details.portType) }),
            authorizationTypeString && (React.createElement(SingleStat, { title: "Authorization", value: authorizationTypeString })),
            isNoc && (React.createElement(React.Fragment, null,
                React.createElement(SingleStat, { title: "Organization Name (NOC-only)", value: details.organizationName }),
                React.createElement(SingleStat, { title: "Site Name (NOC-only)", value: details.siteName }),
                React.createElement(SingleStat, { title: "Site ID (NOC-only)", value: details.siteId.toString() }),
                React.createElement(SingleStat, { title: "Unique Ref (NOC-only)", value: details.uniqueReference }),
                React.createElement(SingleStat, { title: "Totalizer End (NOC-only)", value: "".concat(Number((_j = details.totalizerEnd) !== null && _j !== void 0 ? _j : 0).toFixed(2), " kWh") }),
                React.createElement(SingleStat, { title: "EVCCID (NOC-only)", value: "".concat((_k = details.evccid) !== null && _k !== void 0 ? _k : "Unknown") }),
                React.createElement(SingleStat, { title: "Friendly ID (NOC-only)", value: details.chargerFriendlyId }),
                React.createElement(SingleStat, { title: "Ocpp ID (NOC-only)", value: details.chargerOcppId }),
                details.stopReason != null && (React.createElement(SingleStat, { title: "Stop Reason (NOC-only)", value: details.stopReason, helpText: "The reason reported by the charger for stopping the transaction" })),
                (carInformation === null || carInformation === void 0 ? void 0 : carInformation.manufacturer) != null && (React.createElement(SingleStat, { title: "Equipment Mfr.", value: carInformation.manufacturer, helpText: "The manufacturer of the charging equipment. Not always the same as the car's manufacturer, as the car manufacturer may outsource the component." })),
                (carInformation === null || carInformation === void 0 ? void 0 : carInformation.suspectedCarManufacturers) != null && (React.createElement(SingleStat, { title: "Suspected Car Manufacturer", value: carInformation.suspectedCarManufacturers.join(", "), helpText: "Car manufacturers we suspect (but have not confirmed) may work with the equipment manufacturer." })),
                (carInformation === null || carInformation === void 0 ? void 0 : carInformation.confirmedCarManufacturers) != null && (React.createElement(SingleStat, { title: "Confirmed Car Manufacturer", value: carInformation.confirmedCarManufacturers.join(", "), helpText: "Car manufacturers we've confirmed use this equipment." })),
                (carInformation === null || carInformation === void 0 ? void 0 : carInformation.confirmedCars) != null && (React.createElement(SingleStat, { title: "Confirmed Cars", value: carInformation.confirmedCars.join(", "), helpText: "Cars we've confirmed to match this EVCCID pattern." }))))),
        details.chargeBreakdown != null && (React.createElement("div", { className: "flex flex-col gap-y-[32px] mt-[64px]" },
            React.createElement("label", { className: "mt-[64px] font-N6 text-white" }, "Payment Breakdown"),
            React.createElement("div", { className: "grid grid-cols-3 gap-x-[32px] gap-y-[32px] w-fit" },
                React.createElement(SingleStat, { title: "Energy Cost", value: "$".concat(GetDisplayPrice((_l = details === null || details === void 0 ? void 0 : details.chargeBreakdown.baseCharge) === null || _l === void 0 ? void 0 : _l.amount, (_m = details === null || details === void 0 ? void 0 : details.chargeBreakdown.baseCharge) === null || _m === void 0 ? void 0 : _m.precision)) }),
                details.chargeBreakdown.idleFeesAfterChargingStarts != null && (React.createElement(SingleStat, { title: "Long Session Fee", value: "$".concat(GetDisplayPrice((_p = (_o = details === null || details === void 0 ? void 0 : details.chargeBreakdown.idleFeesAfterChargingStarts) === null || _o === void 0 ? void 0 : _o.amount) === null || _p === void 0 ? void 0 : _p.amount, (_r = (_q = details === null || details === void 0 ? void 0 : details.chargeBreakdown.idleFeesAfterChargingStarts) === null || _q === void 0 ? void 0 : _q.amount) === null || _r === void 0 ? void 0 : _r.precision)), helpText: "Long session fees are assessed to incentivize turnover. The long session fee for this session began at ".concat(new Date(protoTimestampToNative(details.chargeBreakdown.idleFeesAfterChargingStarts
                        .timeBeginUtc)).toLocaleTimeString(), " and ended at ").concat(new Date(protoTimestampToNative(details.chargeBreakdown.idleFeesAfterChargingStarts
                        .timeEndUtc)).toLocaleTimeString(), ". This can be configured per-station in Command Console.") })),
                details.chargeBreakdown.idleFeesAfterChargingEnds != null && (React.createElement(SingleStat, { title: "Idle Fee", value: "$".concat(GetDisplayPrice((_t = (_s = details === null || details === void 0 ? void 0 : details.chargeBreakdown.idleFeesAfterChargingEnds) === null || _s === void 0 ? void 0 : _s.amount) === null || _t === void 0 ? void 0 : _t.amount, (_v = (_u = details === null || details === void 0 ? void 0 : details.chargeBreakdown.idleFeesAfterChargingEnds) === null || _u === void 0 ? void 0 : _u.amount) === null || _v === void 0 ? void 0 : _v.precision)), helpText: "Idle fees are assessed to encourage customers to quickly unplug after their session ends. The idle fee for this session began at ".concat(new Date(protoTimestampToNative(details.chargeBreakdown.idleFeesAfterChargingEnds
                        .timeBeginUtc)).toLocaleTimeString(), " and ended at ").concat(new Date(protoTimestampToNative(details.chargeBreakdown.idleFeesAfterChargingEnds
                        .timeEndUtc)).toLocaleTimeString(), ". This can be configured per-station in Command Console.") })),
                totalTaxAmount > 0 && (React.createElement(SingleStat, { title: "Tax", value: "$".concat(GetDisplayPrice(totalTaxAmount, 2)), helpText: "Tax Authorities:", helpDetails: taxAuthorities })),
                React.createElement(SingleStat, { title: "Total", value: "$".concat(GetDisplayPrice(details === null || details === void 0 ? void 0 : details.transactionAmount, details === null || details === void 0 ? void 0 : details.transactionAmountPrecision)) })),
            (details === null || details === void 0 ? void 0 : details.uniqueReference) != null &&
                (details === null || details === void 0 ? void 0 : details.uniqueReference) != "" && (React.createElement(PaymentDetails, { transactionId: transactionId })))),
        isNoc &&
            details.relatedTransactionsByPayment &&
            details.relatedTransactionsByPayment.length > 0 && (React.createElement(RelatedTransactionsTable, { transactions: details.relatedTransactionsByPayment, title: "Transactions with Same Payment (NOC-only)" })),
        isNoc &&
            details.relatedTransactionsByEVCCID &&
            details.relatedTransactionsByEVCCID.length > 0 && (React.createElement(RelatedTransactionsTable, { transactions: details.relatedTransactionsByEVCCID, title: "Transactions with Same EVCCID (NOC-only)" })),
        sessionInsights.length > 0 && (React.createElement("div", { className: "flex flex-row gap-x-[32px] my-[64px]" }, sessionInsights.map(function (s, i) { return (React.createElement(InsightCard, { key: i, title: s[0], body: s[1] })); }))),
        isNoc && (React.createElement("div", { className: "flex flex-col gap-y-[32px]" },
            React.createElement("label", { className: "mt-[64px] font-N6 text-white" }, "Select Time Range"),
            React.createElement("div", { className: "flex items-center gap-x-[8px]" },
                React.createElement("button", { onClick: function () { return handleAdjustTime(startDateInput, -5); } }, "-5 min"),
                React.createElement(TextInput, { type: "datetime-local", ref: startDateInput, defaultValue: sessionStart
                        ? formatDateTimeLocal(new Date(sessionStart.getTime() - 5 * MINUTES_TO_MS))
                        : "" }),
                React.createElement("button", { onClick: function () { return handleAdjustTime(startDateInput, 5); } }, "+5 min")),
            React.createElement("div", { className: "flex items-center gap-x-[8px]" },
                React.createElement("button", { onClick: function () { return handleAdjustTime(endDateInput, -5); } }, "-5 min"),
                React.createElement(TextInput, { type: "datetime-local", ref: endDateInput, defaultValue: sessionEnd
                        ? formatDateTimeLocal(new Date(sessionEnd.getTime() + 5 * MINUTES_TO_MS))
                        : "" }),
                React.createElement("button", { onClick: function () { return handleAdjustTime(endDateInput, 5); } }, "+5 min")),
            React.createElement(StyledButton, { text: "Download Logs", onClick: function () {
                    var _a, _b;
                    return fetchLogs(details.transactionId, ((_a = startDateInput.current) === null || _a === void 0 ? void 0 : _a.value)
                        ? new Date(startDateInput.current.value).toISOString()
                        : undefined, ((_b = endDateInput.current) === null || _b === void 0 ? void 0 : _b.value)
                        ? new Date(endDateInput.current.value).toISOString()
                        : undefined);
                }, disabled: false }))),
        hasDetails && (React.createElement("div", { className: "w-[75%] mt-[64px] gap-y-[128px]" },
            React.createElement(LineGraph, { title: "Session Power", labelAccessor: powerLabelAccesor, valueAccessors: powerValueAccessors, data: powerGraphData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(1), " kW"); } }),
            React.createElement("div", { className: "h-[64px]" }),
            React.createElement(LineGraph, { title: "Session Energy", labelAccessor: energyLabelAccesor, valueAccessors: energyValueAccessors, data: deliveredEnergyData, yAxisValueToLabel: function (v) { return "".concat(v, "%"); }, secondYAxisValueToLabel: function (v) { return "".concat(v.toFixed(1), " kWh"); } }),
            React.createElement("div", { className: "h-[64px]" }),
            isNoc && (React.createElement(React.Fragment, null,
                " ",
                React.createElement(LineGraph, { title: "NOC Current/Voltage", labelAccessor: currentLabelAccesor, valueAccessors: currentValueAccessors, data: currentData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(1), " A"); }, secondYAxisValueToLabel: function (v) { return "".concat(v.toFixed(1), " V"); } }),
                React.createElement("div", { className: "h-[64px]" }))))),
        hasLowRateDetails && (React.createElement("div", { className: "w-[75%] mt-[64px] gap-y-[128px]" },
            React.createElement(LineGraph, { title: "Power Diagnostics", labelAccessor: powerDiagnosticsLabelAccesor, valueAccessors: powerDiagnosticsValueAccessors, data: powerDiagnosticsGraphData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(1), " kW"); } }),
            React.createElement("div", { className: "h-[64px]" }),
            React.createElement(LineGraph, { title: "Voltage Diagnostics", labelAccessor: voltageDiagnosticsLabelAccesor, valueAccessors: voltageDiagnosticsValueAccessors, data: voltageDiagnosticsGraphData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(1), " V"); } }),
            React.createElement("div", { className: "h-[64px]" }),
            React.createElement(LineGraph, { title: "Current Diagnostics", labelAccessor: currentDiagnosticsLabelAccesor, valueAccessors: currentDiagnosticsValueAccessors, data: currentDiagnosticsGraphData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(1), " A"); } }),
            React.createElement("div", { className: "h-[64px]" }),
            React.createElement(LineGraph, { title: "SoC Diagnostics", labelAccessor: socDiagnosticsLabelAccesor, valueAccessors: socDiagnosticsValueAccessors, data: socDiagnosticsGraphData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(1), "%"); }, secondYAxisValueToLabel: function (v) { return "".concat(v, " s"); } })))));
};
