import React from "react";
export var StyledButtonStyle;
(function (StyledButtonStyle) {
    StyledButtonStyle[StyledButtonStyle["Green"] = 0] = "Green";
    StyledButtonStyle[StyledButtonStyle["Gray"] = 1] = "Gray";
})(StyledButtonStyle || (StyledButtonStyle = {}));
export var StyledButton = function (_a) {
    var text = _a.text, onClick = _a.onClick, className = _a.className, disabled = _a.disabled, style = _a.style;
    var buttonStyle = "";
    switch (style) {
        case StyledButtonStyle.Gray:
            buttonStyle =
                "text-white hover:text-black-400 bg-black-1000 active:text-black-600";
            break;
        case StyledButtonStyle.Green:
        default:
            buttonStyle =
                "text-black-1200 hover:text-black-1000 bg-green hover:bg-green/80 active:bg-green/60";
    }
    if (disabled) {
        buttonStyle = "bg-black-1000 text-black hover:text-black-600";
    }
    return (React.createElement("button", { onClick: onClick, className: "font-N8 whitespace-pre w-fit px-[20px] py-[13px] rounded-[10px] ".concat(className !== null && className !== void 0 ? className : "", " ").concat(buttonStyle), disabled: disabled }, text));
};
