var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useEffect, useState } from "react";
import { SingleStat } from "../SingleStat";
import { Line } from "react-chartjs-2";
import { Card } from "../Card";
var colors = [
    {
        gradient0: "rgba(151, 71, 255, ",
        gradient1: "rgba(53, 194, 255, ",
        line: "#47C8FF",
        tailwind: "bg-gradient-to-r from-[#9747FF] to-[#35C2FF]",
    },
    {
        gradient0: "rgba(239, 45, 86, ",
        gradient1: "rgba(255, 89, 219, ",
        line: "#FF58A2",
        tailwind: "bg-gradient-to-r from-[#D094FF] to-[#FF3C92]",
    },
    {
        gradient0: "rgba(255, 148, 23, ",
        gradient1: "rgba(255, 109, 109, ",
        line: "#F49D37",
        tailwind: "bg-gradient-to-r from-[#F49D37] to-[#FF6D6D]",
    },
    {
        gradient0: "rgba(56, 255, 160, ",
        gradient1: "rgba(0, 224, 255, ",
        line: "#38FFA0",
        tailwind: "bg-gradient-to-r from-[#38FFA0] to-[#00E0FF]",
    },
    {
        // Red Color
        gradient0: "rgba(255, 0, 0, ",
        gradient1: "rgba(255, 69, 0, ",
        line: "#FF0000",
        tailwind: "bg-gradient-to-r from-[#FF0000] to-[#FF4500]",
    },
    {
        // Green Color
        gradient0: "rgba(0, 255, 0, ",
        gradient1: "rgba(50, 205, 50, ",
        line: "#00FF00",
        tailwind: "bg-gradient-to-r from-[#00FF00] to-[#32CD32]",
    },
];
var startSemiTransparentOpacity = "0.12)";
var endSemiTransparentOpacity = "0.76)";
var createGradient = function (ctx, area, index, solid) {
    var gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    var gradient0Opacity = solid ? "1)" : startSemiTransparentOpacity;
    var gradient1Opacity = solid ? "1)" : endSemiTransparentOpacity;
    gradient.addColorStop(0, colors[index].gradient0 + gradient0Opacity);
    gradient.addColorStop(1, colors[index].gradient1 + gradient1Opacity);
    return gradient;
};
var ColorLabels = function (_a) {
    var labels = _a.labels;
    return (React.createElement(React.Fragment, null, labels.map(function (l, i) {
        return (React.createElement("div", { key: i, className: "flex flex-row gap-x-[8px]" },
            React.createElement("div", { className: "".concat(colors[l[0]].tailwind, " rounded-[50%] w-[14px] h-[14px] min-w-[14px] min-h-[14px]") }),
            React.createElement("label", { className: "font-N8p5 text-black-400" }, l[1])));
    })));
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export var LineGraph = function (props) {
    var _a;
    var chartRef = useRef(null);
    var _b = useState({
        datasets: [],
    }), chartData = _b[0], setChartData = _b[1];
    var _c = useState(false), mouseOver = _c[0], setMouseOver = _c[1];
    var _d = useState(Array(props.valueAccessors.length).fill("--")), tooltipValues = _d[0], setTooltipValues = _d[1];
    useEffect(function () {
        setTooltipValues(Array(props.valueAccessors.length).fill("--"));
    }, [mouseOver]);
    useEffect(function () {
        var chart = chartRef.current;
        if (!chart) {
            return;
        }
        var labels = props.data.map(function (d) { return props.labelAccessor(d); });
        var chartData = {
            labels: labels,
            datasets: props.valueAccessors.map(function (v, i) {
                var _a;
                var colorIndex = (_a = v.colorOverride) !== null && _a !== void 0 ? _a : i;
                return {
                    label: v.label,
                    data: props.data.map(function (d) { return v.accessor(d); }),
                    borderColor: v.omitFill
                        ? createGradient(chart.ctx, chart.chartArea, colorIndex, true)
                        : colors[colorIndex].line,
                    borderDash: v.dashed ? [10, 5] : undefined,
                    borderWidth: 2,
                    pointRadius: 0,
                    backgroundColor: v.omitFill
                        ? undefined
                        : createGradient(chart.ctx, chart.chartArea, colorIndex, false),
                    fill: !v.omitFill,
                    tension: props.tension,
                    yAxisID: v.secondAxis ? "y2" : "y",
                };
            }),
        };
        if (!props.manualOrder) {
            var indexToHowOftenLargest_1 = [];
            for (var i = 0; i < chartData.datasets.length; ++i) {
                indexToHowOftenLargest_1.push([i, 0]);
            }
            for (var i = 0; i < props.data.length; i += 10) {
                var largestIndex = 0;
                for (var j = 1; j < chartData.datasets.length; ++j) {
                    if (chartData.datasets[j].data[i] >
                        chartData.datasets[largestIndex].data[i]) {
                        largestIndex = j;
                    }
                }
                indexToHowOftenLargest_1[largestIndex][1] =
                    indexToHowOftenLargest_1[largestIndex][1] + 1;
            }
            indexToHowOftenLargest_1.sort(function (a, b) {
                return a[1] - b[1];
            });
            chartData.datasets = chartData.datasets.map(function (d, i) {
                var order = indexToHowOftenLargest_1.map(function (a) { return a[0]; }).indexOf(i);
                return __assign(__assign({}, d), { order: order });
            });
        }
        setChartData(chartData);
    }, [props, props.data]);
    var options = {
        responsive: true,
        animation: false,
        events: [
            "mouseenter",
            "mouseleave",
            "mouseout",
            "mousedown",
            "mouseup",
            "mousemove",
        ],
        maintainAspectRatio: false,
        hover: {
            mode: "nearest",
            intersect: true,
        },
        plugins: {
            legend: {
                display: false,
            },
            decimation: {
                enabled: true,
            },
            tooltip: {
                enabled: true,
                animation: false,
                position: "nearest",
                mode: "index",
                intersect: false,
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        if (props.showMouseTooltip) {
                            var v = props.valueAccessors[context.datasetIndex];
                            var value = v.secondAxis
                                ? props.secondYAxisValueToLabel(context.raw)
                                : props.yAxisValueToLabel(context.raw);
                            return "".concat(context.dataset.label, ": ").concat(value);
                        }
                        return "";
                    },
                    title: function (context) {
                        if (props.tooltipLabelAccessor) {
                            var dataIndex = context[0].dataIndex;
                            return props.tooltipLabelAccessor(props.data[dataIndex]);
                        }
                        else {
                            return context[0].label;
                        }
                    },
                },
                external: function (context) {
                    if (!context.tooltip.dataPoints) {
                        return;
                    }
                    var nearestData = props.data[context.tooltip.dataPoints[0].dataIndex];
                    var currentTooltipValues = __spreadArray([], tooltipValues, true);
                    props.valueAccessors.forEach(function (v, i) {
                        currentTooltipValues[i] = "".concat(v.secondAxis
                            ? props.secondYAxisValueToLabel(v.accessor(nearestData))
                            : props.yAxisValueToLabel(v.accessor(nearestData)));
                    });
                    // Avoid excessive updates, since this can retrigger the tooltip to render.
                    if (JSON.stringify(currentTooltipValues) !=
                        JSON.stringify(tooltipValues)) {
                        setTooltipValues(currentTooltipValues);
                    }
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: (_a = props.maxXTicks) !== null && _a !== void 0 ? _a : 5,
                    padding: 8,
                },
            },
            y: {
                ticks: {
                    maxTicksLimit: 5,
                    padding: 8,
                    callback: function (value) {
                        return props.yAxisValueToLabel(value);
                    },
                },
            },
        },
    };
    if (props.secondYAxisValueToLabel) {
        options.scales["y2"] = {
            position: "right",
            ticks: {
                maxTicksLimit: 5,
                padding: 8,
                callback: function (value) {
                    return props.secondYAxisValueToLabel(value);
                },
            },
        };
    }
    var plugins = [
        {
            id: "verticalLine",
            afterDraw: function (chart) {
                var _a;
                var x = (_a = chart.tooltip) === null || _a === void 0 ? void 0 : _a.caretX;
                var yAxis = chart.scales.y;
                var ctx = chart.ctx;
                ctx.save();
                // Draw dashed line at caret.
                ctx.setLineDash([5, 3]);
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 2;
                ctx.strokeStyle = "rgba(255, 255, 255, 0.4)";
                ctx.stroke();
                ctx.closePath();
                ctx.restore();
            },
        },
        {
            id: "mouseLeave",
            afterEvent: function (chart, args) {
                if (args.event.type == "mouseleave") {
                    setMouseOver(false);
                }
                else if (args.event.type == "mouseenter") {
                    setMouseOver(true);
                }
            },
        },
    ];
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px] w-full" },
        props.title != null && (React.createElement("label", { className: "font-N6 text-white" }, props.title)),
        React.createElement(Card, { noPadding: true, className: "flex flex-col", spotlights: [], largeSpotlights: [
                "top-[-250px] right-[-250px]",
                "top-[0px] left-[250px]",
                "top-[-200px] left-[50px]",
            ] },
            React.createElement("div", { className: "flex flex-row gap-x-[64px] p-[16px] items-center z-[-10] w-full justify-between" },
                React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[64px] p-[16px] items-center z-[-10] gap-y-[16px]" }, props.valueAccessors.map(function (v, i) {
                    return v.hideTopTooltip ? (React.createElement(React.Fragment, null)) : (React.createElement(SingleStat, { key: v.label, value: tooltipValues[i], title: v.label }));
                })),
                React.createElement("div", { className: "flex flex-col gap-y-[8px]" },
                    React.createElement(ColorLabels, { labels: props.colorLabelOverrides
                            ? props.colorLabelOverrides.map(function (o, i) { return [i, o]; })
                            : props.valueAccessors.map(function (v, i) {
                                var _a;
                                return [
                                    (_a = v.colorOverride) !== null && _a !== void 0 ? _a : i,
                                    v.label,
                                ];
                            }) }))),
            React.createElement("div", { className: "h-[380px]" },
                React.createElement(Line, { ref: chartRef, options: options, data: chartData, plugins: plugins })))));
};
